import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TestItem } from '../../../shared/modules/test-item';
import { ILicenseCategory } from '../../../shared/interfaces/licenseCategory';
import { Tests } from '../../../shared/interfaces/tests-on-themes';

@Injectable({
  providedIn: 'root'
})
export class TestsOnThemesService {
  private apiUrl = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

  getTestsOnThemes(id: string): Observable<Tests[]> {
    return this.http.get<Tests[]>(`${this.apiUrl}trafficrulestestschapters/licensecategoryid/${id}`);
  }
  getLicenseCategory(): Observable<ILicenseCategory>{
    return this.http.get<ILicenseCategory>(`${this.apiUrl}licensecategories`);
  }
  getTestsOnThemesById(licenseCategoryid: string, id: string): Observable<TestItem[]> {
    return this.http.get<TestItem[]>(`${this.apiUrl}trafficrulestests/licensecategoryid/${licenseCategoryid}/chapterid/${id}`);
  }
  getCorrectAnswer(questionId: string): Observable<string>{
    return this.http.get<string>(`${this.apiUrl}trafficrulestests/${questionId}/rightanswer`);
  }

  // Favourites------------------------------

  getFavouritesTest(): Observable<TestItem[]>{
    return this.http.get<TestItem[]>(`${this.apiUrl}trafficrulestests/favorites`);
  }

  addQuestionToFavourite(question: TestItem): Observable<TestItem[]>{
    return this.http.post<TestItem[]>(`${this.apiUrl}trafficrulestests/${question.id}/favorites`, question);
  }

  deleteQuestionFromFavourite(question: TestItem): Observable<TestItem[]>{
    return this.http.delete<TestItem[]>(`${this.apiUrl}trafficrulestests/${question.id}/favorites`);
  }

  // SelectedQuestion

  getRandomQuestions(licenseCategoryId): Observable<TestItem[]>{
    return this.http.get<TestItem[]>(`${this.apiUrl}trafficrulestests/licensecategoryid/${licenseCategoryId}/random/twenty`);
  }

}
