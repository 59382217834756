<div *ngIf="categories" class="menu-container">
  <div class="menu-container-top">
    <div class="title">
      <p>
        {{'TRAINING_MATERIAL.ROAD_SIGN_TITLE' | translate}}
      </p>
    </div>
    <div class="sign-icon"><img src="../../../assets/icons/sign.svg"></div>
  </div>
  <div class="menu-content-container">
    <a (click)="changeCategory(category)"
       *ngFor="let category of categories | async"
       [routerLink]="'/home/tm/signs/category/'+category.id"
       class="menu-item">
      <div [class.active]="activeCategory?.id === category.id"
           class="menu-item-content">
        <div class="number">
          <p>{{category.number}}.</p>
        </div>
        <div class="theme-name">
          <p>{{category.name}}</p>
        </div>
        <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
      </div>
    </a>
  </div>
</div>
