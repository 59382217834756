import { Component, OnDestroy, OnInit } from '@angular/core';
import { SignsService } from '../../services/signs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../../../../core/services/storage.service';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Config } from '../../../../shared/property/constant';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-sign-category',
  templateUrl: './sign-category.component.html',
  styleUrls: ['./sign-category.component.scss',
  '/src/app/style/sign-marking-item.scss']
})
export class SignCategoryComponent implements OnInit, OnDestroy {

  signs: Observable<TrainingMaterialComponents[]>;
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  scrollElement = false;
  prefixImgPath = Config.storageUrl();
  categoryName = '';
  subscriptions: Subscription = new Subscription();

  constructor(private signsService: SignsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.storageService.get('signCategory')) {
      this.categoryName = this.storageService.get('signCategory').value;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    const categoryId = this.activatedRoute.snapshot.params.id;
    this.signs = this.signsService.getSignsByCategory(categoryId);
    this.categories = this.signsService.getSignCategories();
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.id === categoryId);
    }, () => {

    }, () => {
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'auto'});
    this.scrollElement = true;
  }

  async changeCategory(category): Promise<void> {
    this.spinner.show();
    this.activeCategory = category;
    this.signs = await this.signsService.getSignsByCategory(category.id);
    this.spinner.hide();
  }

  openSign(link, categoryName): void {
    this.storageService.set('signCategory', categoryName);
    this.router.navigate([link]);
  }

}
