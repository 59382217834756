import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrafficRulesService } from '../../services/traffic-rules.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-traffic-rule-categories',
  templateUrl: './traffic-rule-categories.component.html',
  styleUrls: ['./traffic-rule-categories.component.scss']
})
export class TrafficRuleCategoriesComponent implements OnInit, OnDestroy {

  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  trafficRules: Observable<TrainingMaterialComponents[]>;
  openComment = [];
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    const categoryId = this.activatedRoute.snapshot.params.id;
    this.categories = this.trafficRulesService.getTrafficRuleCategories();
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.trafficRules = this.trafficRulesService.getTrafficRulesByCategory(categoryId);
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.id === categoryId);
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async changeCategory(category): Promise<void> {
    this.spinner.show();
    this.activeCategory = category;
    this.trafficRules = await this.trafficRulesService.getTrafficRulesByCategory(category.id);
    this.spinner.hide();
  }

  open(el): void {
    if (this.openComment.includes(el)) {
      this.openComment =  this.openComment.filter(element => element !== el);
    } else {
      this.openComment.push(el);
    }
  }
}
