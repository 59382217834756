<div class="dialog-content container">
  <img (click)="closeDialog(false)" class="cancel" src="../../../assets/icons/cancel.svg">

  <div class="content">
    <div>
      <img src="../../../assets/icons/success-registration.svg">
    </div>
    <div class="main-content">
      <div class="title"><p>Вітаємо!</p></div>
      <div class="subtitle">
        <p>Запит на навчання в автошколі надіслано </p>
        <br><br>
        <p>Очікуйте лист з підтвердженням документів на вашій електронній скринці.</p>
      </div>

      <div class="questions">
        <p>Виникли питання?</p>
        <div class="social">
          <div class="background facebook">
            <a href="https://www.facebook.com/messages/t/112310597191145" target="_blank"></a>
          </div>
          <div class="background telegram">
            <a href="https://t.me/romdomdomua" target="_blank"></a>
          </div>
          <div class="background viber">
            <a href="viber://chat?number=%2B380676788000" target="_blank"></a>
          </div>
        </div>
      </div>
      <button (click)="closeDialog(true)">Готово</button>
    </div>
  </div>
</div>
