<div class="page-container">
  <div class="contentBlock">
    <img src="../../../assets/images/logo.svg" class="logo">
    <h1 class="header">Придбати курс теорії </h1>
    <h1 class="header" style="color: #636DF9;">лише за 500 грн</h1>
    <div class="form">
      <form [formGroup]="registrationForm"
            (window:keypress)="enterRegistration($event)"
            class="registration-form">
          <div class="registration-container">  
            <div class="information">
              <label class="head-label">Навчальный процес</label>
              <ul class="information-list">
                <li>
                  <label>Все навчання в зручному додатку</label>
                  <div class="devices">
                    <a href="https://romdomdom.in.ua/authorization" target="_blank"><label style="cursor: pointer;">WEB</label></a>
                    <a href="https://apps.apple.com/ua/app/romdomdom/id1541853396" target="_blank"><label style="cursor: pointer;"><img src="../../../assets/icons/apple-blue.svg" style="height: 17px;"> IOS</label></a>
                    <a href="https://play.google.com/store/apps/details?id=com.romdomdom.student&pcampaignid=web_share" target="_blank"><label style="cursor: pointer;"><img src="../../../assets/icons/android-blue.svg" style="height: 17px;">ANDROID</label></a>
                  </div>
                </li>
                <li>
                  <label>Переглядай відео лекцій з теорії тривалістю 30-40 хвилин кожна.</label>
                </li>
                <li>
                  <label>В кінці кожної лекції проходь тест для ефективного засвоєння теми.</label>
                </li>
                <li>
                  <label>Після останньої лекції склади підсумковий тест в додатку, який є точним аналогом іспиту з теорії в теоріїв ТСЦ МВС.</label>
                </li>
              </ul>
              
              <br />
              <label class="learn-more">Дізнатись більше <a href="https://lp.romdomdom.in.ua" target="_blank">на сайті</a></label>
            </div>       
            <div class ="inputs">
              <label class="inputs-header">Придбати курс теорії</label>

              <input [ngClass]="{'red':errorName||registrationForm.controls['fullName'].invalid && registrationForm.controls['fullName'].touched}" [ngStyle]="{'border-color':(errorName||registrationForm.controls['fullName'].invalid &&registrationForm.controls['fullName'].touched)?'red':'#DAE0ED'}" class="input-login"
                    formControlName="fullName"
                    (focusout)="deleteGap(0, registrationForm.value.fullName)"
                    placeholder="{{'REGISTRATION.LOGIN_PLACEHOLDER' | translate}}"
                    required>

              <input (input)="emailExist = false"
                    [ngClass]="{'red':emptyEmail||emailExist||incorrectEmail||registrationForm.controls['email'].invalid &&registrationForm.controls['email'].touched}"
                    [ngStyle]="{'border-color':(emptyEmail||incorrectEmail||emailExist||registrationForm.controls['email'].invalid &&registrationForm.controls['email'].touched)?'red':'#DAE0ED'}"
                    class="input-email"
                    formControlName="email"
                    placeholder="{{'REGISTRATION.EMAIL_PLACEHOLDER' | translate}}"
                    type="text">

              <input type="tel"
                    class="tel"
                    placeholder="000) 000 0000"
                    [specialCharacters]="['(',')', '[' ,']' , '\\', '+', ' ' ]"
                    mask="000) 000 0000"
                    formControlName="phoneNumber"
                    prefix="+38("
                    [showMaskTyped]="true"
                    [ngClass]="{'red':registrationForm.controls['phoneNumber'].invalid &&registrationForm.controls['phoneNumber'].touched}">
        
              <select id="course" formControlName="tariff" class="select" placeholder="Обери категорію" required>
                <option value="" disabled selected hidden>Обери категорію</option>
                <option value="A" disabled>Категорія A (в розробці)</option>
                <option value="5b3d470a-f48b-4a1d-9b81-714cf31e3d6c">Категорія B</option>
                <option value="C" disabled>Категорія C (в розробці)</option>
                <option value="D" disabled>Категорія D (в розробці)</option>
                <option value="E" disabled>Категорія E (в розробці)</option>
              </select>

              <!-- <div >
                <input type="checkbox" formControlName="agreement" class="checkbox" required> Я погоджуюсь з <a href="https://lp.romdomdom.in.ua/">умовами угоди користувача</a>
                <p *ngIf="!this.isAgreed" class="g-error error-message" style="margin-top: -13px; margin-left: 20px; font-size: 13px;">Необхідно погодитись з умовами угоди користувача!</p>
              </div> -->

              <div class="submit" style="width: 100%;" (click)="makePayment()">
                <form method="POST" accept-charset="utf-8" id="payForm"  action="https://www.liqpay.ua/api/3/checkout">
                  <input id="paymentData" type="hidden" name="data" [value]="paymentData"  />
                  <input id="paymentSignature" type="hidden" name="signature" [value]="paymentSignature"  />
                  <button class="submit-button">{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.PAY' | translate}}</button>
                </form>
              </div>
            </div>

            <div class="payment-icons">
              <div class="first-column">
                <img src="../../../assets/icons/liqpay.svg" class="liqpay">
                <img src="../../../assets/icons/visa-payment.svg" class="visa">
              </div>
              <div class="second-column">
                <img src="../../../assets/icons/mastercard-payment.svg" class="mastercard">
                <img src="../../../assets/icons/privat24.svg" class="privat24">
                <img src="../../../assets/icons/applepay.svg" class="applepay">
                <img src="../../../assets/icons/googlepay.svg" class="googlepay">
              </div>
            </div>
          </div>
      </form>
    </div>
  </div>
</div>
<div class="footer">
  <hr />
  <div class = "footer-info">
    <label type="phone">+38(067) 67-88-000</label>
    <a href="https://lp.romdomdom.in.ua" target="_blank" style="color: #9A99A2;"><label style="cursor: pointer;">www.romdomdom.in.ua</label></a>
    <div class="social-media">
      <a href="https://t.me/+2DcfV_Vz5Z5mM2E6" target="_blank"><img src="../../../assets/icons/telegram-square.svg"></a>
      <a href="https://www.facebook.com/RomDomDom.school" target="_blank"><img src="../../../assets/icons/facebook-square.svg"></a>
      <a href="https://www.instagram.com/romdomdom.school" target="_blank"><img src="../../../assets/icons/instagram-square.svg"></a>
      <a href="https://www.youtube.com/@romdomdom2304" target="_blank"><img src="../../../assets/icons/youtube-square.svg"></a>
    </div>
  </div>
</div>