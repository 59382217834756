<div class="reject-lesson-content container">
  <img (click)="closeDialog()" class="exit" src="../../../assets/icons/cancel.svg">
  <div class="content-container container-information">
    <div *ngIf="status < 4" class="reject-lesson-icon">
      <img height="90px" src="../../../assets/icons/reject-icon.svg" width="92px">
    </div>
    <div *ngIf="status === 4">
      <img class="icon" height="108px" src="../../../assets/icons/continue-learning.svg" width="157px">
    </div>
    <div class="title">
      <p *ngIf="status === 1">Ви скасували поїздку</p>
      <div *ngIf="status === 2">
        <p>Приносимо вибачення!</p>
        <p>З технічних причин заняття не відбудеться</p>
      </div>
      <p *ngIf="status === 3">Ваше заняття не зараховане</p>
      <p *ngIf="status === 4">Ви не можете забронювати поїздку</p>
    </div>
    <div>
      <div class="subtitle">
        <p *ngIf="status === 1 || status === 3">Щоб завершити навчання вам потрібно пройти усі 10 занять. Для
          відновлення практичного заняття необхідно повідомити службу підтримки
        </p>
        <p *ngIf="status === 2">Забронюйте нову поїздку</p>
        <p *ngIf="status === 4">У вас більше не залишилося доступних поїздок. Для відновлення практичних занять
          необхідно повідомити службу підтримки</p>
      </div>
    </div>
    <app-social-information [line]="true"></app-social-information>
  </div>
</div>
