import { AfterViewInit, Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InstructorsService } from '../../../modules/practice/services/instructors.service';
import { newArray } from '@angular/compiler/src/util';
import { TripStatuses } from '../../../../_shared/constants/tripStatuses';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from '~/node_modules/ngx-spinner';
import { EventEmitter } from '~/node_modules/@angular/core';
import { TripStatus } from '~/src/app/shared/interfaces/trip-status';
import { TripBookingProgress } from '~/src/app/shared/interfaces/tripBookingProgress';
import { LicenseCategoriesService } from '../../../modules/training-materials/services/license-categories.service';

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.scss']
})
export class StudentProgressComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() noRegister: boolean;
  @Input() value: number;
  @Input() progressBarWidth: number;
  @Input() drivingValues: number;
  @Input() practiseArray: Array<number>;
  @Input() noResultInfo: boolean;
  @Input() startProgress: number;
  @Input() page: number;
  @Input() tripCount: number;
  @Output() allPractice = new EventEmitter<any>();
  statuses: Array<number>;
  tripStatus: TripStatus[];
  successTrip: number;
  isPartialPayment: any;

  constructor(private instructorsService: InstructorsService,
              private spinner: NgxSpinnerService,
              private paymentService: LicenseCategoriesService) {
    this.tripStatus = TripStatuses.value();
  }

  ngAfterViewInit(): void {
    if (this.startProgress === undefined || this.startProgress > 0) {
      this.getProgress();
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes && changes.startProgress && changes.startProgress.currentValue) {
      this.getProgress();
    }
  }

  getProgress(): void {
    if (this.startProgress === undefined || this.startProgress > 0) {
      this.instructorsService.getTripBookingsProgress().subscribe((el: TripBookingProgress) => {
        this.tripCount = el.tripsLimit;
        this.practiseArray = newArray(this.tripCount);
        this.statuses = el.tripStatuses;
        this.successTrip = this.statuses.filter((elem: number) =>  elem === 5).length;
        if (this.successTrip >= 10) {
          this.allPractice.emit(true);
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        this.practiseArray = newArray(10);
      });
    } else {
      this.practiseArray = newArray(10);
    }

  }
}
