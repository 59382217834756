import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoadMarkingsService } from '../../services/road-markings.service';
import { Observable } from 'rxjs';
import { StorageService } from '../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Subscription } from '~/node_modules/rxjs';


@Component({
  selector: 'app-road-marking',
  templateUrl: './road-marking.component.html',
  styleUrls: ['./road-marking.component.scss']
})
export class RoadMarkingComponent implements OnInit, OnDestroy {

  roadMarking: TrainingMaterialComponents;
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  openComment: boolean;
  categoryName = '';
  subscriptions: Subscription = new Subscription();

  constructor(private roadMarkingsService: RoadMarkingsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();

    this.router.onSameUrlNavigation = 'reload';
    this.subscriptions.add(this.roadMarkingsService.getRoadMarkingById(this.activatedRoute.snapshot.params.id)
      .subscribe((res: TrainingMaterialComponents) => {
        this.roadMarking = res;
      }, error=>{
        // error during done subscribe
      }, ()=>{
        // After complete subscribe
        this.spinner.hide();
      }));
    this.categories = this.roadMarkingsService.getRoadMarkingCategories();
    if (this.storageService.get('roadMarkingCategory')) {
      this.categoryName = this.storageService.get('roadMarkingCategory').value;
    }
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.name === this.categoryName);
    }, error=>{
      // error during done subscribe
    }, ()=>{
      // After complete subscribe
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  changeCategory(category): void {
    this.activeCategory = this.activatedRoute.snapshot.params.email;
    this.router.navigate([`/home/tm/road-marks/category/${category.id}`]);
  }
}
