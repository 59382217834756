import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightsText'
})
export class HighlightsTextPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string, ...args: any): any {
    if (!args) {
      return value;
    }
    // Match in a case insensitive maneer
    const re = new RegExp(args, 'gi');
    if (value) {
      const match = value.match(re);

      // If there's no match, just return the original value.
      if (!match) {
        return value;
      }

      const firstImgIndex = value.indexOf('<img');
      let secondImgIndex;
      let imageText;
      let result;
      if (firstImgIndex !== -1) {
        secondImgIndex = value.indexOf('style="width: 150px;">');
        secondImgIndex = secondImgIndex + 22;
        imageText = value.slice(firstImgIndex, secondImgIndex);
        value = value.slice(0, firstImgIndex) + value.slice(secondImgIndex);
        value = value.replace(re, '<mark>' + match[0] + '</mark>');
        result = [value.slice(0, firstImgIndex), imageText, value.slice(firstImgIndex)].join('');
      } else {
        result = value.replace(re, '<mark>' + match[0] + '</mark>');
      }


      return this.sanitizer.bypassSecurityTrustHtml(result);
    }
  }

}
