import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PenaltyService } from '../../training-materials/services/penalty.service';
import { StorageService } from '../../../core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ILicenseCategory } from '../../../shared/interfaces/licenseCategory';
import { LecturesService } from '../services/lectures.service';
import { FinalTestService } from '../services/final-test.service';
import { RegistrationPersonalInformationService } from '../../driving-school-registration/services/registration-personal-information.service';
import { PersonalInformation } from '../../../shared/interfaces/personalInformation';
import { LectureView, Structure } from '../../../shared/interfaces/lecture-view';
import { newArray } from '~/node_modules/@angular/compiler/src/util';
import { HttpErrorResponse } from '~/node_modules/@angular/common/http';
import { InstructorsService } from '@module/practice/services/instructors.service';
import { Subscription } from '~/node_modules/rxjs';
import { OnDestroy } from '~/node_modules/@angular/core';
import { SocialLinks } from '~/src/app/shared/property/socialLiinks';
import { LicenseCategoriesService } from '../../training-materials/services/license-categories.service';
import { Tariff } from '../../../shared/interfaces/licenseCategory';

@Component({
  selector: 'app-course-lectures',
  templateUrl: './course-lectures.component.html',
  styleUrls: ['./course-lectures.component.scss',
    '/src/app/modules/training-materials/components/penalties/penalties.component.scss']
})
export class CourseLecturesComponent implements OnInit, AfterViewInit, OnDestroy {
  openComment: number;
  practice: boolean;
  finalTest: boolean;
  practiseArray = new Array(10);
  lessonsScoreValue: number;
  openStatus: boolean;
  licenseCategory: ILicenseCategory;
  courses: LectureView[] = [];
  copyCourses = [...this.courses];
  drivingValues: number;
  accessToDriving: number;
  limitCount: number;
  accessToFinalTest: boolean;
  currentOpen: number;
  practiceWindow: boolean;
  isFinalPassed: boolean;
  registerInfo: PersonalInformation;
  noRegister: boolean;
  statuses: any;
  continueLearning: boolean;
  successPractice: boolean;
  subscriptions: Subscription = new Subscription();
  progressBarWidth: any;
  socialLinks = SocialLinks;
  isPartialPayment: any;
  tariffId: string;
  paymentSignature: string;
  paymentData: string;
  isFull = false;
  canPay = false;
  partialSum: number;
  isNoPractice = false;
  isPracticeAllowed = false;
  tariff: Tariff;

  constructor(private penaltyService: PenaltyService,
              private storageService: StorageService,
              private translateService: TranslateService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private router: Router,
              private lectureService: LecturesService,
              private finalTestService: FinalTestService,
              private paymentService: LicenseCategoriesService,
              private regPersonalInformationService: RegistrationPersonalInformationService,
              private instructorsService: InstructorsService) {
    this.drivingValues = 0;
    this.lessonsScoreValue = 0;
    this.practice = true;
    this.finalTest = true;
    this.currentOpen = this.storageService.get('openComent')?.value;
    this.licenseCategory = this.storageService.get('license-category').value;
    this.courses.forEach((el: LectureView) => {
      if (el.testsCount === 0) {
        this.currentOpen = 0;
      }
    });

  }

  ngOnInit(): void {
    this.checkIsPartial();
    this.continueLearning = this.storageService.get('continue-learning') ?
      this.storageService.get('continue-learning').value : false;
    this.router.onSameUrlNavigation = 'reload';
    this.spinner.show();
    this.subscriptions.add(this.regPersonalInformationService.getRegistrationInformation()
      .subscribe((el: PersonalInformation) => {
        this.registerInfo = el;
        this.noRegister = this.registerInfo === null || (this.registerInfo && !([3,6,7].includes(this.registerInfo.courseRequestStatus)));
        if (this.registerInfo && [3,6,7].includes(this.registerInfo.courseRequestStatus)) {
          this.storageService.set('trialTest', false);
          this.subscriptions.add(this.finalTestService.checkFinalSuccess().subscribe((res: boolean) => {
            this.isFinalPassed = res;
          }));
          this.isPracticeAllowed = this.registerInfo?.courseRequestStatus === 7;
          this.getProgress();
        } else {
          this.storageService.set('trialTest', true);
        }
      }));
    this.subscriptions.add(this.lectureService.getLectures(this.licenseCategory.id).subscribe((res: LectureView[]) => {
      this.courses = res;

      if(this.canPay === false)
        this.checkForPay();

      this.courses.map(el => el.structure.sort((a: Structure, b: Structure) =>  a.chapterNumber - b.chapterNumber));
      this.accessToDriving = this.courses.indexOf(this.courses.find((el: LectureView) => el.progress.percentage < 90));
      this.copyCourses = this.courses.filter((i: LectureView) => {
        if (this.currentOpen >= 0) {
          this.openComment = this.currentOpen;
        } else {
          if ((this.courses.indexOf(i) > 0 && (this.courses[this.courses.indexOf(i) - 1].progress.percentage >= 90) &&
              (this.courses[this.courses.indexOf(i)].progress.percentage < 90)) ||
            (this.courses.indexOf(i) === (this.courses.length - 1) &&
              (this.courses[this.courses.indexOf(i) - 1]?.progress.percentage >= 90) &&
              this.courses.indexOf(i) !== 0) ||
            (this.courses[this.courses.indexOf(i) + 1]?.progress.percentage === 0 && this.courses.indexOf(i) === 0) ||
            this.courses.length === 1
          ) {
            this.openComment = this.courses.indexOf(i);
            return this.courses.indexOf(i);
          }
        }
        if (this.storageService.get('practiceWindow') === null) {
          this.storageService.set('practiceWindow', false);
        }
        const window = this.storageService.get('practiceWindow').value ?
          this.storageService.get('practiceWindow').value : false;
        this.practiceWindow = this.accessToDriving === 30 && window === false;
      });

      if (this.noRegister) {
        this.lessonsScoreValue = 0;
      } else {
        this.courses.forEach((el: LectureView) => this.lessonsScoreValue += el.progress.percentage < 90 ? el.progress.percentage : 100);
        this.lessonsScoreValue /= this.courses.length;
        this.lessonsScoreValue = Math.round(this.lessonsScoreValue);

      }
      setTimeout(() => {
        const el = document.querySelector('.active');
        if (el) {
          el.scrollIntoView();
        }
      }, 0);
    }, error => {
      // error during done subscribe
    }, () => {
      // After complete subscribe
      if (this.courses.length === 0) {
        this.practiceWindow = true;
        this.isNoPractice = true;
      }
      this.spinner.hide();
    }));
  }

  ngAfterViewInit(): void {
    this.currentOpen = this.storageService.get('openComent')?.value;
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getProgress(): void {
    if (this.isPracticeAllowed) {
      this.subscriptions.add(this.instructorsService.getTripBookingsProgress().subscribe(el => {
        this.limitCount = el.tripsLimit;
        this.practiseArray = newArray(this.limitCount);
        this.statuses = el.tripStatuses;
        this.drivingValues = this.statuses.filter(elem => elem === 5).length;
      }, (error: HttpErrorResponse) => {
        this.practiseArray = newArray(10);
      }));
    }
  }

  allPractice(event): void {
    this.successPractice = event;
  }

  open(el): void {
    this.openComment = el;
  }

  savePosition(el): void {
    this.storageService.set('openComent', el);
  }

  close(): void {
    this.storageService.set('practiceWindow', true);
    this.practiceWindow = false;
  }

  reserve(): void {
    this.router.navigate(['/home/practice/practical-training']);
    this.storageService.set('practiceWindow', true);
  }

  closeWindowLearning(): void {
    this.continueLearning = false;
    this.storageService.set('continue-learning', false);
  }

  checkForPay(): void {
    if (this.courses[this.courses.length - 1]?.progress.percentage >= 90)
    {
      this.canPay = true;
      this.accessToFinalTest = [3,7].includes(this.registerInfo.courseRequestStatus) && this.tariff?.lecturesLimit === this.courses.length && !this.isFinalPassed;
    }
    else {
      this.canPay = false;
    }
  }

  private checkIsPartial(): void {
    this.subscriptions.add(this.paymentService.getNextPayment().subscribe(data => {
      this.tariff = data.tariff;
      if (data.tariff.type === 0)
      {
        this.isFull = true;
      }
      if (data.isPartialPayment && !data.isFullyPaid)
      {
        this.isPartialPayment = true;
        this.tariffId = data.tariff.id;
        this.partialSum = data.nextPartialPayment.sum;
      }
      else {
        this.isPartialPayment = false;
      }

      if(this.canPay === false)
        this.checkForPay();
    }));
  }

  pay(): void {
    const clientData = {
      clientType: 2, // web client
      // Set Tariff plan id from localStorage
      tariffId: this.tariffId,
      isPartialPayment: true,
      // Allow to receive payment data in base64 encode format [false: will return payment data in JSON format]
      isDataEncode: true,
      clientUrl: document.location.href
    };

    this.regPersonalInformationService.getPaymentData(clientData).subscribe((res) => {
      this.paymentSignature = res?.signature;
      this.paymentData = res?.paymentData;
      this.spinner.hide();

      (document.getElementById('paymentData') as HTMLInputElement).value = this.paymentData;
      (document.getElementById('paymentSignature') as HTMLInputElement).value = this.paymentSignature;
      this.submit();

    });
  }

  submit(): void {
    const form = document.getElementById('payForm') as HTMLFormElement;
    form.submit();
  }
}
