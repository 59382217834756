import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignCategoriesComponent } from './components/sign-categories/sign-categories.component';
import { SignCategoryComponent } from './components/sign-category/sign-category.component';
import { SignComponent } from './components/sign/sign.component';
import { RoadMarkingCategoriesComponent } from './components/road-marking-categories/road-marking-categories.component';
import { RoadMarkingCategoryComponent } from './components/road-marking-category/road-marking-category.component';
import { RoadMarkingComponent } from './components/road-marking/road-marking.component';
import { TrafficRuleCategoriesComponent } from './components/traffic-rule-categories/traffic-rule-categories.component';
import { TrafficRuleCategoryComponent } from './components/traffic-rule-category/traffic-rule-category.component';
import { TrafficRuleComponent } from './components/traffic-rule/traffic-rule.component';
import { PenaltiesComponent } from './components/penalties/penalties.component';
import { PenaltyComponent } from './components/penalty/penalty.component';
import { TrafficRuleSearchComponent } from './components/search-components/traffic-rule-search/traffic-rule-search.component';
import { SearchSignsComponent } from './components/search-components/search-signs/search-signs.component';
import { SearchRoadMarkingsComponent } from './components/search-components/search-road-markings/search-road-markings.component';
import { PenaltiesSearchComponent } from './components/search-components/penalties-search/penalties-search.component';


const routes: Routes = [
          {
            // path: 'signs/categories/:id',
            path: 'signs/categories',
            component: SignCategoriesComponent,
          },
          {
            path: 'signs/category/:id',
            component: SignCategoryComponent
          },
          {
            path: 'signs/category/:id/:searchText',
            component: SearchSignsComponent
          },
          {
            path: 'signs/sign/:id',
            component: SignComponent
          },
          {
            path: 'road-marks/categories',
            component: RoadMarkingCategoriesComponent
          },
          {
            path: 'road-marks/category/:id',
            component: RoadMarkingCategoryComponent
          },
          {
            path: 'road-marks/category/:id/:searchText',
            component: SearchRoadMarkingsComponent
          },
          {
            path: 'road-marks/road-mark/:id',
            component: RoadMarkingComponent
          },
          {
            path: 'traffic-rules/categories/:id',
            component: TrafficRuleCategoriesComponent
          },
          {
            path: 'traffic-rules/category/:id',
            component: TrafficRuleCategoryComponent
          },
          {
            path: 'traffic-rules/traffic-rule/:categoryId/:id',
            component: TrafficRuleCategoryComponent
          },
          {
            path: 'traffic-rules/category/:id/:searchText',
            component: TrafficRuleSearchComponent
          },
          // {
          //   path: 'traffic-rules/traffic-rule/:id',
          //   component: TrafficRuleComponent
          // },
          {
            path: 'road-fine',
            component: PenaltiesComponent
          },
          {
            path: 'road-fine/:id',
            component: PenaltiesComponent
          },
          // {
          //   path: 'road-fine/:id',
          //   component: PenaltyComponent
          // },
  {
    path: 'road-fines/:searchText',
    component: PenaltiesSearchComponent

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainingMaterialsRoutingModule { }
