<button class="exit">
  <img (click)="closeDialog()" src="../../../assets/icons/white-cancel.svg">
</button>
<div class="dialog-container container">
  <img src="../../../assets/icons/for-mobile-version.svg">
  <div class="content container-information">
    <div class="title">
      <p>
        {{forGooglePlay ? ('DIALOGS.FOR_MOBILE_APP_STORE.TITLE'| translate) : 'DIALOGS.FOR_MOBILE_GOOGLE_PLAY.TITLE' | translate}}
      </p>
    </div>
    <div class="subtitle">
      <p>
        {{'DIALOGS.FOR_MOBILE.SUBTITLE' | translate}}
      </p>
    </div>
    <img class="qr-code" src="../../../assets/images/QR-code.jpg">
    <div class="footer">
      <div class="qr-text">
        <p>
          {{'DIALOGS.FOR_MOBILE.FOOTER' | translate}}
        </p>
      </div>
      <div (click)="forGooglePlay = !forGooglePlay" class="google-play">
        <p>{{forGooglePlay ? ' Google play' : 'AppStore'}}</p>
      </div>
    </div>
  </div>
</div>
