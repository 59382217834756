import { Component, Input, OnInit } from '@angular/core';
import { Instructor } from '../../interfaces/instructor';
import { Config } from '../../property/constant';
import { TransmissionType } from '../../../../_shared/constants/transmissionType';

@Component({
  selector: 'app-full-instructor-information',
  templateUrl: './full-instructor-information.component.html',
  styleUrls: ['./full-instructor-information.component.scss',
    '/src/app/modules/practice/practice.scss']
})
export class FullInstructorInformationComponent implements OnInit {
  @Input() centerInformation: boolean;
  @Input() bigImage: boolean;
  @Input() instructor: Instructor;
  @Input() instructorDistrict: string;
  @Input() noSpliceInformation: boolean;
  transmissionType: Array<string>;
  prefixImgPath = Config.storageUrl();
  instructorInformation: Array<object>;

  constructor() {
  }

  ngOnInit(): void {
    this.transmissionType = TransmissionType.value();
    this.instructorInformation = [{
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.CAR',
      value: this.instructor.vehicle ? this.instructor.vehicle.name : this.instructor.vehicleName
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.VEHICLE',
      value: this.transmissionType[this.instructor.vehicle ?
        this.instructor.vehicle.transmissionType : this.instructor.vehicleTransmissionType]
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.YEAR',
      value: this.instructor.vehicle ? this.instructor.vehicle.manufactureYear : this.instructor.vehicleManufacturerYear
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.NUMBER',
      value: this.instructor.vehicle ? this.instructor.vehicle.stateNumber : this.instructor.vehicleStateNumber
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.DISTRICT',
      value: this.instructorDistrict ? this.instructorDistrict : this.instructor.district
    },
    ];
  }


}
