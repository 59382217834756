import { Component, OnDestroy, OnInit } from '@angular/core';
import { SignsService } from '../../services/signs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, OnDestroy {

  sign: TrainingMaterialComponents;
  openComment = true;
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  categoryName = '';
  subscriptions: Subscription = new Subscription();

  constructor(private signsService: SignsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();

    if (this.storageService.get('signCategory')) {
      this.categoryName = this.storageService.get('signCategory').value;
    }
    this.categories = this.signsService.getSignCategories();
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.name === this.categoryName);
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
    this.spinner.show();
    this.subscriptions.add(this.signsService.getSignById(this.activatedRoute.snapshot.params.id)
      .subscribe((res: TrainingMaterialComponents) => {
        this.sign = res;
      }, ()=>{

      }, ()=>{
        this.spinner.hide();
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
