<div class="traffic-rule-theme theme">
  <div><p>{{'TESTS.TICKET-TESTS' | translate}}</p></div>
</div>
<hr>
<div class="content">
  <div class="test">
    <div class="test-content">
      <a *ngFor="let category of categories | async" [routerLink]="'/home/tests/ticket-test/'+category.id"
           class="item">
        <div class="item-content">
          <div class="number"><p>{{category.number}}.</p></div>
          <div class="name"><p>{{category.name}}</p>
            <div>
              <div class="questions">{{'TESTS.QUESTION' | translate}} <span>{{category.testsCount}}</span></div>
            </div>
          </div>
          <div class="arrow"><img src="../../../assets/icons/arrow-left.svg"></div>
        </div>
      </a>
    </div>
  </div>
</div>

