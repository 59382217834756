/**
 * Document location.
 */
import { environment } from '../../../environments/environment';

const { protocol, hostname, port } = window.location;

/**
 * External properties.
 */
export const EXTERNAL: any = window['appConfig'] || {};

/**
 * Application namespace.
 */
export const NAMESPACE = 'RDD';


/**
 * Date format.
 */
export const DATE_FORMAT = 'DD MMM YYYY';

/**
 * Time format.
 */
export const TIME_FORMAT = 'HH:mm';

/**
 * DateTime format.
 */
export const DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm';

/**
 * API URL namespace.
 */
export const API_PREFIX = '/api';

/**
 * Origin url.
 */
export const ORIGIN_URL = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

/**
 * Running env: local/test-content/prod.
 */
export const ENV: string = EXTERNAL['app.env'] || 'prod';

/**
 * General API end point.
 */
export const API_URL: string = EXTERNAL['api.url'] || ORIGIN_URL;

/**
 * Mobile width.
 */
export const MOBILE_WIDTH = 979;

/**
 * Notification fade delay.
 */
export const NOTIFICATION_FADE_DELAY = 500;

/**
 * Notification auto close delay.
 */
export const NOTIFICATION_AUTO_CLOSE_DELAY = 5000;

/**
 * Ascending sort.
 */
export const SORT_ASC = 'ASC';

/**
 * Descending sort.
 */
export const SORT_DESC = 'DESC';


export class Config {
  public static apiUrl(version = 'v1'): string {
    return `${environment.apiUrl}/api/${version}/`;
  }
  public static apiV2Url(version = 'v2'): string {
    return `${environment.apiUrl}/api/${version}/`;
  }
  public static storageUrl(): string {
    return `${environment.storageUrl}`;
  }
}
