import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../../../layout/services/search.service';
import { StorageService } from '../../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TrafficRulesService } from '../../../services/traffic-rules.service';
import { TrainingMaterialCategories } from '../../../../../shared/interfaces/training-material-categories';
import { Subscription } from '~/node_modules/rxjs';
import {error} from "protractor";

@Component({
  selector: 'app-traffic-rule-search',
  templateUrl: './traffic-rule-search.component.html',
  styleUrls: ['./traffic-rule-search.component.scss']
})
export class TrafficRuleSearchComponent implements OnInit, OnDestroy {

  trafficRules: Observable<any[]>;
  openComment = [];
  activeCategory: any;
  searchText: any;
  categories: any;
  subscriptions: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private searchService: SearchService,
              private storageService: StorageService,
              private spinner: NgxSpinnerService,
              private translateService: TranslateService,
              private router: Router,
              private trafficRulesService: TrafficRulesService) {
  }

  async ngOnInit(): Promise<void> {
    const categoryId = this.activatedRoute.snapshot.params.id;
    this.spinner.show();
    this.trafficRules = this.searchService.searchByTrafficLowsId(this.activatedRoute.snapshot.params.id,
      this.activatedRoute.snapshot.params.searchText)
    this.searchText =  await this.activatedRoute.snapshot.params.searchText;
    this.spinner.hide();
    this.categories = this.trafficRulesService.getTrafficRuleCategories();
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.id === categoryId);
    }, error=>{
      // error during done subscribe
    }, ()=>{
      // After complete subscribe
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  open(el): void {
    if (this.openComment.includes(el)) {
      this.openComment = this.openComment.filter(element => element !== el);
    } else {
      this.openComment.push(el);
    }
  }

  changeCategory(category): void {
    this.activeCategory = this.activatedRoute.snapshot.params.email;
    this.router.navigate([`/home/tm/traffic-rules/categories/${category.id}`]);
  }
}
