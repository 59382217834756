import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrainingMaterialComponents } from '../../../shared/interfaces/training-material-components';
import { TrainingMaterialCategories } from '../../../shared/interfaces/training-material-categories';
import { Tests } from '../../../shared/interfaces/tests-on-themes';
import { TestItem } from '../../../shared/modules/test-item';

@Injectable({
  providedIn: 'root'
})
export class TrafficRulesService {

  // private url = Config.apiUrl();
  private apiUrl = Config.apiUrl();


  constructor(private http: HttpClient) {
  }

  getTrafficRuleCategories(): Observable<TrainingMaterialCategories[]>{
    return this.http.get<TrainingMaterialCategories[]>(`${this.apiUrl}trafficruleschapters`);
  }


  getTrafficRulesByCategory(id: string): Observable<TrainingMaterialComponents[]> {
    return this.http.get<TrainingMaterialComponents[]>(`${this.apiUrl}trafficrules/chapterid/${id}`);
  }

  getTrafficRuleById(id: string): Observable<TrainingMaterialComponents> {
    return this.http.get<TrainingMaterialComponents>(`${this.apiUrl}trafficrules/${id}`);
  }

  getTrafficRuleBTicketsyId(id: string): Observable<Tests[]> {
    return this.http.get<Tests[]>(`${this.apiUrl}trafficrulesteststickets/licensecategoryid/${id}`);
  }

  geTicketsyId(ticketId: string): Observable<TestItem[]> {
    return this.http.get<TestItem[]>(`${this.apiUrl}trafficrulestests/ticketid/${ticketId}`);
  }
}
