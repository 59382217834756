import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInfirmation } from '../../../shared/interfaces/user-infirmation';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }
  getUser(): Observable<UserInfirmation> {
    return this.http.get<UserInfirmation>(this.url + 'profile');
  }

  editUserInfo(userInfo): Observable<any> {
    return this.http.patch(this.url + 'profile', userInfo);
  }

  changePassword(passwords): Observable<any> {
    return this.http.put(this.url + 'accountsettings/password', passwords);
  }

  uploadPhoto(image): Observable<any>{
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(Config.apiUrl() + 'files/image', formData);
  }

  changeLanguage(language): Observable<any>{
    return this.http.patch(this.url + 'accountsettings/language', {
      language
    });
  }
}
