import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TestsOnThemesService } from '../../../services/tests-on-themes.service';
import { Observable } from 'rxjs';
import { TrafficRulesService } from '../../../../training-materials/services/traffic-rules.service';
import { StorageService } from '../../../../../core/services/storage.service';
import { ILicenseCategory } from '../../../../../shared/interfaces/licenseCategory';
import { NgxSpinnerService } from 'ngx-spinner';
import { Tests } from '../../../../../shared/interfaces/tests-on-themes';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-tests-on-themes',
  templateUrl: './tests-on-themes.component.html',
  styleUrls: ['./tests-on-themes.component.scss']
})
export class TestsOnThemesComponent implements OnInit, OnDestroy {

  categories: Observable<Tests[]>;
  licenseCategory: ILicenseCategory;
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private testsOnThemes: TestsOnThemesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.storageService.set('trialTest', false);
    this.spinner.show();
    this.licenseCategory = this.storageService.get('license-category').value;
    this.categories = this.testsOnThemes.getTestsOnThemes(this.licenseCategory.id);
    this.subscriptions.add(this.categories.subscribe(() => {
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));

    this.storageService.set('test-category', 'tests-on-themes');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
