import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cities } from '../../../shared/interfaces/cities';
import { Instructor } from '~/src/app/shared/interfaces/instructor';
import { TripBookingProgress } from '~/src/app/shared/interfaces/tripBookingProgress';

@Injectable({
  providedIn: 'root'
})
export class InstructorsService {
  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

  getCities(): Observable<Cities[]> {
    return this.http.get<Cities[]>(this.url + 'locations/cities');
  }

  getAllInstructors(): Observable<Instructor[]> {
    return this.http.get<Instructor[]>(this.url + 'instructors');
  }

  getInstructors(districtIds, lessonDates, lessonHours, transmissionType): Observable<any> {
    let instructors = this.url + `instructors`;
    instructors += this.instructorUrl(districtIds, lessonDates, lessonHours, transmissionType);
    return this.http.get(instructors);
  }
  getAllInstructorSchedules(id): Observable<any> {
    return this.http.get(this.url + 'instructors/' + id + '/schedules');
  }
  getInstructorSchedules(id, districtIds, lessonDates, lessonHours, transmissionType): Observable<any> {
    let instructorSchedule = this.url + 'instructors/' + id + '/schedules';
    instructorSchedule += this.instructorUrl(districtIds, lessonDates, lessonHours, transmissionType);
    return this.http.get(instructorSchedule);
  }

  instructorUrl(districtIds, lessonDates, lessonHours, transmissionType): string {
    let url = '';
    if (transmissionType >= 0) {
      url += `?transmissionType=${transmissionType}`;
    }
    if (districtIds.length > 0) {
      districtIds.map(district => {
        url += transmissionType >= 0 || (transmissionType === -1 && districtIds[0] !== district) ? '&' : '?';
        url += `districtIds=${district}`;
      });
    }
    if (lessonDates.length > 0) {
      lessonDates.map(date => {
        url += transmissionType >= 0 || districtIds.length > 0 ||
        ((transmissionType < 0 && districtIds.length === 0) && lessonDates[0] !== date) ? '&' : '?';
        url += `lessonDates=${date}`;
      });
    }
    if (lessonHours.length > 0) {
      lessonHours.map(hours => {
        url += transmissionType >= 0 || districtIds.length > 0 || lessonDates.length > 0 ||
        ((transmissionType < 0 && districtIds.length === 0 && lessonDates.length === 0) && lessonHours[0] !== hours) ? '&' : '?';
        url += `lessonHours=${hours}`;
      });
    }
    return url;
  }

  postTripBooking(reqData): Observable<any> {
    return this.http.post(this.url + 'tripbookings', reqData);
  }

  getTripBookings(): Observable<any> {
    return this.http.get(this.url + 'tripbookings');
  }

  rejectLesson(id): Observable<any> {
    return this.http.put(this.url + `tripbookings/${id}/cancel`, id);
  }
  getTripBookingsProgress(): Observable<TripBookingProgress> {
    return this.http.get<TripBookingProgress>(this.url + 'tripbookings/progress');
  }

}
