import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoadMarkingsService } from '../../services/road-markings.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../../../../core/services/storage.service';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Config } from '../../../../shared/property/constant';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-road-marking-category',
  templateUrl: './road-marking-category.component.html',
  styleUrls: ['./road-marking-category.component.scss',
    '/src/app/style/sign-marking-item.scss']
})
export class RoadMarkingCategoryComponent implements OnInit, OnDestroy {

  roadMarkings: Observable<TrainingMaterialComponents[]>;
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  scrollElement = false;
  prefixImgPath = Config.storageUrl();
  subscriptions: Subscription = new Subscription();

  constructor(private roadMarkingsService: RoadMarkingsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.router.onSameUrlNavigation = 'reload';
    const categoryId = this.activatedRoute.snapshot.params.id;
    this.roadMarkings = this.roadMarkingsService.getRoadMarkingsByCategory(this.activatedRoute.snapshot.params.id);
    this.categories = this.roadMarkingsService.getRoadMarkingCategories();
    this.subscriptions.add(this.categories.subscribe((categories: TrainingMaterialCategories[]) => {
      this.activeCategory = categories.find((cat: TrainingMaterialCategories) => cat.id === categoryId);
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'smooth'});
    this.scrollElement = true;
  }

  async changeCategory(category): Promise<void> {
    this.spinner.show();
    this.activeCategory = category;
    this.roadMarkings = await this.roadMarkingsService.getRoadMarkingsByCategory(category.id);
    this.spinner.hide();
  }

  openMarking(link, categoryName): void {
    this.storageService.set('roadMarkingCategory', categoryName);
    this.router.navigate([link]);
  }
}
