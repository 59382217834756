<div class="menu">
  <div class="menu-container">
    <div class="menu-container-top">
      <div class="title"><p>{{'FINES_TABLE.MENU_TITLE' | translate}}</p>
      </div>
      <div class="fines-table-icon"><img src="../../../assets/icons/fine.png"></div>
    </div>
    <div class="menu-item">
      <div class="menu-item-text">
          <span class=" menu-item-text-part1 ">
          {{'FINES_TABLE.MENU_ITEM_TEXT_PART1' | translate}}
          </span>
        <a class="codex" href="https://zakon.rada.gov.ua/laws/show/80731-10/page5#n958"
           target="_blank"> {{'FINES_TABLE.CODEX' | translate}} </a>
        <span class="abreviation">{{'FINES_TABLE.ABREVIATION' | translate}} </span>
        <div class="menu-footer">
          <div class="footer-text">
            <p>{{'FINES_TABLE.FOOTER_TEXT' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
