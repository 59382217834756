import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.scss']
})
export class PenaltyComponent implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
