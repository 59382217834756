<div [class.less-size]="drivingInformation" class="social">
  <a href="https://www.facebook.com/messages/t/112310597191145" target="_blank">
    <img src="../../../assets/icons/messenger.svg">
  </a>
  <a href="https://t.me/romdomdomua" target="_blank">
    <img src="../../../assets/icons/telegram.svg">
  </a>
  <a href="viber://chat?number=%2B380676788000" target="_blank">
    <img src="../../../assets/icons/viber.svg">
  </a>
</div>
<hr [class.line]="line">
<div class="phone-block">
  <img src="../../../assets/icons/red-phone.svg">
  <p>+38 0(67) 67 88 0003</p>
</div>
