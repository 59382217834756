<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
        <p>{{activeCategory}}</p>
    </div>
    <hr>
    <div class="content">
      <div (click)="openMarking('/home/tm/road-marks/road-mark/' + roadMarking.id,activeCategory)"
           *ngFor="let roadMarking of roadMarkings; let it=index"
           class="little-block">
        <div class="little-block-content">
          <div class="marking-image">
            <img alt="" src="{{prefixImgPath}}/road-markings/{{roadMarking.number}}.png">
          </div>
          <div class="marking-number">
            <p>{{roadMarking.number}}</p>
          </div>
          <div [innerHTML]="roadMarking.name | highlightsText: searchText"
               class="little-block-name"></div>
          <div class="marking-arrow"><img class="marking-arrow-image" src="../../assets/icons/sign-arrow.svg"></div>
        </div>
        <div class="big">
          <div class="disabled-block"></div>
          <div
            [ngClass]="{left:(it%3 === 0 || it === 0 && it !== 1),
                        left2:(((it - 1)%3 === 0 || (it - 1) === 0 && (it - 1) !== 1)),
                        left3:(((it + 1)%3 === 0 || (it + 1) === 0 && (it + 1) !== 1))}"
            class="item">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{roadMarking.number + '.'}}</span>
              <span class="title-hover-block-name">{{roadMarking.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-marking-hover-block">
              </div>
              <div [innerHTML]="roadMarking.description | highlightsText: searchText"
                   class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
        <div class="little">
          <div [ngClass]="{left:(it%2 === 0 || it === 0 && it !== 1),
                           left2:(((it + 1)%2 === 0 || (it + 2) === 0 && (it + 2) !== 1))}"
               class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{roadMarking.number + '.'}}</span>
              <span class="title-hover-block-name">{{roadMarking.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-marking-hover-block">
                <!--            <img class="image" src="../../../assets/icons/road-sign.svg">-->
              </div>
              <div [innerHTML]="roadMarking.description | highlightsText: searchText"
                   class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emptyBlock"></div>
    </div>
  </div>
  <div class="menu">
    <app-road-marking-menu [activeCategory]="activeCategory" [categories]="categories"></app-road-marking-menu>
  </div>
</div>
