<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <p>{{activeCategory?.name}}</p>
    </div>
    <hr>
    <div class="content">
      <div (click)="openSign('/home/tm/signs/sign/' + sign.id, activeCategory?.name)"
           *ngFor="let sign of signs| async; let it=index"
           class="little-block">
        <div class="little-block-content">
          <div class="sign-image">
            <img alt="" src="{{prefixImgPath}}/signs/{{sign?.number}}.png">
          </div>
          <div class="sign-number">{{sign.number}}</div>
          <div class="little-block-name">{{sign.name}}
            <div class="sign-arrow"><img class="sign-arrow-image" src="../../assets/icons/sign-arrow.svg"></div>
          </div>
        </div>

        <div class="big">
          <div class="disabled-block"></div>
          <div [ngClass]="{left:(it%3 === 0 || it === 0 && it !== 1),
                            left2:(((it - 1)%3 === 0 || (it - 1) === 0 && (it - 1) !== 1)),
                            left3:(((it + 1)%3 === 0 || (it + 1) === 0 && (it + 1) !== 1))}"
               class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{sign.number + '.'}}</span>
              <span class="title-hover-block-name">{{sign.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-sign-hover-block">
              </div>
              <div [froalaView]="sign.description" class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
        <div class="little">
          <div [ngClass]="{left:(it%2 === 0 || it === 0 && it !== 1),
                           left2:(((it + 1)%2 === 0 || (it + 2) === 0 && (it + 2) !== 1))}"
               class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{sign.number + '.'}}</span>
              <span class="title-hover-block-name">{{sign.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-sign-hover-block">
              </div>
              <div [froalaView]="sign.description" class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emptyBlock"></div>
    </div>
  </div>
  <div class="menu">
    <div class="menu-container">
      <div class="menu-container-top">
        <div class="title">{{'TRAINING_MATERIAL.ROAD_SIGN_TITLE' | translate}}</div>
        <div class="sign-icon"><img src="../../../assets/icons/sign.png"></div>
      </div>
      <div class="menu-content-container">
        <a (click)="changeCategory(category)"
           *ngFor="let category of categories | async"
           class="menu-item"
           [routerLink]="'../'+category.id">
          <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
            <div class="number"><p>{{category.number}}.</p></div>
            <div class="theme-name"><p>{{category.name}}</p></div>
            <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="menu-mobile none">
    <div class="menu-container">
      <div *ngFor="let category of categories | async" [routerLink]="'../'+category.id" class="menu-item">
        <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
          <div class="number"><p>{{category.number}}.</p></div>
          <div class="theme-name"><p>{{ category.name}}</p></div>
          <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
