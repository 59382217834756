import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-road-marking-categories',
  templateUrl: './road-marking-categories.component.html',
  styleUrls: ['./road-marking-categories.component.scss']
})
export class RoadMarkingCategoriesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {}


}

