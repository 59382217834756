import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegistrationPersonalInformationService } from '@module/driving-school-registration/services/registration-personal-information.service';
import { Config } from '~/src/app/shared/property/constant';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  videoPlay: boolean;
  introdactionUrl: string;
  prefixImgPath = Config.storageUrl();
  @ViewChild('video') video: any;
  @ViewChild('progress') progressBar: any;
  @ViewChild('playbackIcons') playbackIcons: any;
  @ViewChild('videoControls') videoControls: any;
  @ViewChild('play') playButton: any;
  @ViewChild('seek') seek: any;
  @ViewChild('duration') duration: any;
  @ViewChild('timeElapsed') timeElapsed: any;
  @ViewChild('seekTooltip') seekTooltip: any;
  @ViewChild('playbackAnimation') playbackAnimation: any;

  constructor(public dialogRef: MatDialogRef<VideoPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private matDialog: MatDialog,
              private  regPersonalInformationService: RegistrationPersonalInformationService) {
    this.introdactionUrl = '';
  }

  ngOnInit(): void {
    this.getIntrodactionVideo();
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

  getIntrodactionVideo(): void {
    this.regPersonalInformationService.getIntrodactionVideo().subscribe((res) => {
      this.introdactionUrl = this.prefixImgPath + res.introductionVideoUrl;
    });
  }

  cancel(): void {
    this.matDialog.closeAll();
  }

  togglePlay(): void {
    if (this.video.nativeElement.paused || this.video.nativeElement.ended) {
      this.video.nativeElement.play();
      this.videoPlay = true;
    } else {
      this.video.nativeElement.pause();
      this.videoPlay = false;
    }
  }


  updatePlayButton(): void {
  }


  formatTime(timeInSeconds): { seconds: string; minutes: string } {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  }

  initializeVideo(): void {
    const videoDuration = Math.round(this.video.nativeElement.duration);
    this.seek.nativeElement.setAttribute('max', videoDuration);
    this.progressBar.nativeElement.setAttribute('max', videoDuration);
    const time = this.formatTime(videoDuration);
    this.duration.nativeElement.innerText = `${time.minutes}:${time.seconds}`;
    this.duration.nativeElement.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
  }


  updateTimeElapsed(): void {
    const time = this.formatTime(Math.round(this.video.nativeElement.currentTime));
    // this.timeElapsed.nativeElement.innerText = `${time.minutes}:${time.seconds}`;
    // this.timeElapsed.nativeElement.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
  }

  updateProgress(): void {
    this.seek.nativeElement.value = Math.floor(this.video.nativeElement.currentTime);
    this.progressBar.nativeElement.value = Math.floor(this.video.nativeElement.currentTime);
    if (this.video.nativeElement.ended) {
      this.videoPlay = false;
    }
  }

  updateSeekTooltip(event): void {
    const skipTo = Math.round(
      (event.offsetX / event.target.clientWidth) *
      parseInt(event.target.getAttribute('max'), 10)
    );
    this.seek.nativeElement.setAttribute('data-seek', skipTo);
    const t = this.formatTime(skipTo);
    this.seekTooltip.nativeElement.textContent = `${t.minutes}:${t.seconds}`;
    const rect = this.video.nativeElement.getBoundingClientRect();
    this.seekTooltip.nativeElement.style.left = `${event.pageX - rect.left}px`;
  }

  skipAhead(event): void {
    const skipTo = event.target.dataset.seek
      ? event.target.dataset.seek
      : event.target.value;
    this.video.nativeElement.currentTime = skipTo;
    this.progressBar.nativeElement.value = skipTo;
    this.seek.nativeElement.value = skipTo;
  }

  rewind(): void {
    this.seek.nativeElement.value += 15;
    this.video.nativeElement.currentTime += 15;
    this.progressBar.nativeElement.value += 15;
  }

  rewindBack(): void {
    this.seek.nativeElement.value -= 15;
    this.video.nativeElement.currentTime -= 15;
    this.progressBar.nativeElement.value -= 15;
  }

  animatePlayback(): void {
    this.playbackAnimation.nativeElement.animate(
      [
        {
          opacity: 1,
          transform: 'scale(1)',
        },
        {
          opacity: 0,
          transform: 'scale(1.3)',
        },
      ],
      {
        duration: 500,
      }
    );
  }

}
