import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, concatMap, delay, map, tap, timeout } from 'rxjs/operators';
import { Params, Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { propToLocalStorage } from '../../shared/decorators/storage.decorator';
import { Config } from '../../shared/property/constant';
import { NgxSpinnerService } from 'ngx-spinner';

import * as jwt_decode from 'jwt-decode';
import { ActivatedRoute } from '~/node_modules/@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  private refreshTokenInProgress = false;
  private url = Config.apiUrl();
  private promise;

  @propToLocalStorage('token')
  public token;

  @propToLocalStorage('user')
  public user;

  constructor(private injector: Injector,
              private router: Router,
              private http: HttpClient,
              private storageService: StorageService,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) {

    if (window.location.pathname.includes('/registration-categories') ){
      this.activatedRoute.queryParams
        .subscribe((params: Params) => {
          if (params.refreshToken) {
            this.token = {
              refreshToken: params.refreshToken,
              token: params.token
            };
            this.user = jwt_decode(this.token.token);
          }
        });
    }

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.user && !this.token) {
      return next.handle(request);
    }

    const params = request.url.split('/');
    if (params[params.length - 1] === 'token') {
      this.promise = this.doRequest(request, next);
      return from(this.promise);
    }

    if (this.checkTokenExpaired && !this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      return this.refreshToken().pipe(
        concatMap((value: any) => {
          request = this.addHeaders(request);
          this.refreshTokenInProgress = false;
          return from(this.doRequest(request, next));
        })
      );
    } else {
      return from(this.doRequest(request, next));
    }
  }

  async doRequest(request, next): Promise<any> {
    await this.promise;
    request = this.addHeaders(request);
    return next.handle(request).pipe(
      timeout(20000),
      catchError((err: any) => {
        this.errorHandler(err);
        this.refreshTokenInProgress = false;
        return throwError(err);
      })
    ).toPromise();
  }

  errorHandler(err: any): void{
    if (err instanceof HttpErrorResponse) {
      this.spinner.hide();
      if (err.status === 400 && err.error.errorCodes.includes(400003) || err.status === 400 && err.error.errorCodes.includes(400002)) {
        delete this.user;
        delete this.token;
        this.storageService.remove('user');
        this.storageService.remove('token');
        window.location.reload();
      }
      if (err.status === 404 && err.error.errorCodes.includes(404001)) {
        this.storageService.remove('user');
        this.storageService.remove('token');
        delete this.user;
        delete this.token;
        window.location.reload();
      }

      if (err.status === 401) {
        delete this.user;
        delete this.token;
        this.router.navigate(['authorization']);
      }
    }
  }

  refreshToken(): Observable<any> {
    const reqParams = {
      token: this.token.token,
      refreshToken: this.token.refreshToken
    };
    return this.http.put<any>(`${this.url}account/token`, reqParams)
      .pipe(map((token) => {
        this.token = token;
        this.user = jwt_decode(token.token);
        return token;
      }));
  }

  get checkTokenExpaired(): boolean {
    const token = this.token.token;
    if (token) {
      return new Date(this.user.exp * 1000).getTime() <= new Date().getTime();
    }
    return false;
  }

  addHeaders(request): HttpRequest<any> {
    if (!this.user)
    {
        this.user = {id: ''};
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token.token}`,
        appId: this.user.id,
        Accept: 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });
  }

}
