<div class="test-container">
  <div (click)="goToPreviousUrl()" class="back-to-category">
    <img src="../../../../../../assets/icons/arrow-left-grey.svg">
    <p>{{'TESTS.TESTS' | translate}}</p>
  </div>
  <app-ticket-content [link]="'/home/tests/test/tests-on-themes'"
                      [testItems]="questions"
                      [testKind]="'TESTS.THEMES' | translate">
  </app-ticket-content>
</div>
