<div *ngIf="errorUpload" class="cdk-overlay-backdrop
 cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing opacity"></div>
<div *ngIf="errorUpload" class="error">
  <p class="err-message">{{'DOCUMENTS.TITLE' | translate}}</p>
  <button (click)="reload()" class="next"><p>{{'DOCUMENTS.RELOAD_DOCUMENTS' | translate}}</p></button>
</div>
<div class="block">
  <p class="title">{{'DOCUMENTS.TITLE' | translate}}</p>
  <div class="navigation-block">
    <button (click)="step = 1" *ngIf="(editStatusDocuments && isIdInvalid) || !editStatusDocuments"
            [disabled]="((error || !chekFormPassword()) && !editStatusDocuments) "
            class="menu-button">
      <p [class.active]="step === 1" [class.trueData]="step !== 1 && chekFormPassword()">
        <img *ngIf="chekFormPassword()"
             src="../../../assets/icons/ok-circle-green.svg">
        <img *ngIf="editStatusDocuments && !chekFormPassword()"
             src="../../../assets/icons/ok-circle-danger.svg">
        {{'DOCUMENTS.MENU_BUTTON.PASSWORD' | translate}}
      </p>
    </button>
    <button (click)="step = 2" *ngIf="((editStatusDocuments && isIndividualTaxNumberInvalid) || !editStatusDocuments) && purchaseType !== 3"
            [disabled]="((error || !checkIdNumber()) && !editStatusDocuments) "
            class="menu-button">
      <p [class.active]="step === 2" [class.trueData]="step !== 2 && checkIdNumber()">
        <img *ngIf="checkIdNumber()"
             src="../../../assets/icons/ok-circle-green.svg">
        <img *ngIf="editStatusDocuments && !checkIdNumber()"
             src="../../../assets/icons/ok-circle-danger.svg">
        {{'DOCUMENTS.MENU_BUTTON.I_NUMBER' | translate}}
      </p>
    </button>

    <button (click)="step = 3" *ngIf="((editStatusDocuments && isMedicalReferenceInvalid) || !editStatusDocuments) && purchaseType !== 3"
            [disabled]="((error || !checkMedical() )&& !editStatusDocuments)"
            [ngClass]="{'margin': purchaseType === 3}"
            class="menu-button">
      <p [class.active]="step === 3" [class.trueData]="step !== 3 && checkMedical()">
        <img *ngIf="checkMedical()"
             src="../../../assets/icons/ok-circle-green.svg">
        <img *ngIf="editStatusDocuments && !checkMedical()"
             src="../../../assets/icons/ok-circle-danger.svg">
        <span *ngIf=" purchaseType !== 3; else categoryB">{{'DOCUMENTS.MENU_BUTTON.MEDICAL' | translate}}</span>
        <ng-template #categoryB>
          <span>{{'DOCUMENTS.MENU_BUTTON.CATEGORY_B' | translate}}</span>
        </ng-template>
      </p>
    </button>
  </div>
  <div *ngIf="step === 1" class="document-content">
    <div class="data">
      <p class="upload-data">{{'DOCUMENTS.UPLOAD_DATA' | translate}}</p>
      <div class="password-menu">
        <div (click)="passwordData(1, 2)"
             [class.activePassword]="passwordStep === 1"
             class="id-card"
        >
          <p>{{'DOCUMENTS.ID_CARD' | translate}}</p>
        </div>
        <div (click)="passwordData(2, 1)"
             [class.activePassword]="passwordStep === 2"
             class="data"
        >
          <p>{{'DOCUMENTS.UKRAINE_PASSWORD' | translate}}</p>
        </div>
      </div>
      <p *ngIf="error &&( searchImageUrl(0,1) === false ||
                          searchImageUrl(1,1) === false ||
                          searchImageUrl(2,1) === false) &&
                          passwordStep === 1" class="err-message">{{'DOCUMENTS.NO_ALL_DATA' | translate}}</p>
      <div *ngIf="passwordStep === 1" class="item-data">
        <div>
          <div *ngIf="searchImageUrl(0,1) !== false &&
                                               searchImageUrl(0,1) !== '' ;else uploadImg" class="page active-doc">
            <img (click)="deleteDock( searchImageUrl(0,1))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(0,1) }}'>
          </div>
          <ng-template #uploadImg>
            <div [class.errorDoc]="error && searchImageUrl(0,1) === false " class="page">
              <input (click)="documentStep = 1"
                     (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options"
                     [uploadInput]="uploadInput"
                     id="file_id1"
                     ngFileSelect type="file">
              <label for="file_id1">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.FRONT_SIDE' | translate}}</p>
        </div>
        <div>
          <div *ngIf="searchImageUrl(1,1) !== false &&
          searchImageUrl(1,1) !== '';else uploadImg2" class="page active-doc">
            <img (click)="deleteDock( searchImageUrl(1,1))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(1,1) }}'>
          </div>
          <ng-template #uploadImg2>
            <div [class.errorDoc]="error && searchImageUrl(1,1) === false " class="page">
              <input (click)="documentStep = 2" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_id2"
                     ngFileSelect type="file">
              <label for="file_id2">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.REVERSE_SIDE' | translate}}</p>
        </div>
        <div>

          <div *ngIf="searchImageUrl(2,1) !== false &&
                       searchImageUrl(2,1) !== ''; else uploadImg3"
               class="page active-doc">
            <img (click)="deleteDock( searchImageUrl(2,1))"
                 class="cancel-dock"
                 src="../../../assets/icons/cancel.svg">

            <img src='{{storagePath + searchImageUrl(2,1) }}'>
          </div>
          <ng-template #uploadImg3>
            <div [class.errorDoc]="error && searchImageUrl(2,1) === false "
                 class="page">

              <input (click)="documentStep = 3" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_id3"
                     ngFileSelect type="file">

              <label for="file_id3">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.ID_ADDITION' | translate}}</p>
        </div>
      </div>
      <p *ngIf="error &&( !searchImageUrl(0,2)||
                          !searchImageUrl(1,2)||
                          !searchImageUrl(2,2)) &&
                          passwordStep === 2" class="err-message">{{'DOCUMENTS.NO_ALL_DATA' | translate}}</p>
      <div *ngIf="passwordStep === 2" class="item-data">
        <div>
          <div *ngIf="searchImageUrl(0,2) ;else uploadImg4" class="page active-doc">
            <img (click)="deleteDock(searchImageUrl(0,2))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(0,2) }}'>
          </div>
          <ng-template #uploadImg4>
            <div [class.errorDoc]="error && !searchImageUrl(0,2)" class="page">

              <input (click)="documentStep = 4" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_password1"
                     ngFileSelect type="file">

              <label for="file_password1">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.FIRST_PAGE' | translate}}</p>
        </div>
        <div>
          <div *ngIf="searchImageUrl(1,2);else uploadImg5" class="page active-doc">
            <img (click)="deleteDock( searchImageUrl(1,2))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(1,2) }}'>
          </div>
          <ng-template #uploadImg5>
            <div [class.errorDoc]="error && !searchImageUrl(1,2)" class="page">

              <input (click)="documentStep = 5" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_password2"
                     ngFileSelect type="file">

              <label for="file_password2">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.SECOND_PAGE' | translate}}</p>
        </div>
        <div>
          <div *ngIf="searchImageUrl(2,2) ;else uploadImg6" class="page active-doc">
            <img (click)="deleteDock( searchImageUrl(2,2))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(2,2) }}'>
          </div>
          <ng-template #uploadImg6>
            <div [class.errorDoc]="error && !searchImageUrl(2,2) " class="page">

              <input (click)="documentStep = 6" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_password3"
                     ngFileSelect type="file">

              <label for="file_password3">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.REGISTRATION' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="next-block">
      <div *ngIf="checkValidDocuments() && purchaseType === 3 else next1" class="success-data" >
        {{'DOCUMENTS.READY_APPLICATION' | translate}}
      </div>
      <ng-template #next1>
        <div *ngIf="checkValidDocuments() && isIdInvalid && !isIndividualTaxNumberInvalid && !isMedicalReferenceInvalid"
             class="success-data"
        >{{'DOCUMENTS.READY_APPLICATION' | translate}}</div>
      </ng-template>
      <button (click)="nextStep()" *ngIf="isIdInvalid && !isIndividualTaxNumberInvalid && !isMedicalReferenceInvalid;
       else next" class="next aplication">{{'DOCUMENTS.APPLICATION' | translate}}</button>
      <ng-template #next>
        <button (click)="nextStep()" class="next">{{'DOCUMENTS.NEXT' | translate}}</button>
      </ng-template>
    </div>
  </div>
  <div *ngIf="step === 2 && purchaseType !== 3" class="document-content">
    <div class="data">
      <p *ngIf="error && !searchImageUrl(0,0)" class="err-message top">{{'DOCUMENTS.NO_I_NUMBER' | translate}}</p>
      <div class="item-data id-number-data">
        <div>
          <div *ngIf="searchImageUrl(0,0) ;else uploadImg7" class="page active-doc big_input">
            <img (click)="deleteDock( searchImageUrl(0,0))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(0,0) }}'>
          </div>
          <ng-template #uploadImg7>
            <div [class.errorDoc]="error && !searchImageUrl(0,0)" class="page big_input">

              <input (click)="documentStep = 7" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_id_number"
                     ngFileSelect type="file">

              <label for="file_id_number">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="next-block">
      <div *ngIf="checkValidDocuments() && isIndividualTaxNumberInvalid && !isMedicalReferenceInvalid"
           class="success-data">{{'DOCUMENTS.READY_APPLICATION' | translate}}</div>
      <button (click)="nextStep()" *ngIf="isIndividualTaxNumberInvalid && !isMedicalReferenceInvalid;
       else next" class="next aplication">{{'DOCUMENTS.APPLICATION' | translate}}</button>
      <ng-template #next>
        <button (click)="nextStep()" class="next">{{'DOCUMENTS.NEXT' | translate}}</button>
      </ng-template>
    </div>
  </div>
  <div *ngIf="step === 3 && purchaseType !== 3" class="document-content">
    <div class="data">
      <div>
        <p class="upload-data" *ngIf="purchaseType !== 3">
          {{'DOCUMENTS.SCAN' | translate}} <a
          href="https://medtransvp.com.ua/umovi-ta-poslugi/produkciya/blanki/"
          target="_blank"> {{'DOCUMENTS.FORM' | translate}} 0-83</a> {{'DOCUMENTS.FOR_DRIVING' | translate}}
        </p>
      </div>
      <p *ngIf="error && !searchImageUrl(0,3)" class="err-message top">
        <span *ngIf="categoryEnum === 0; else categoryB">
           {{'DOCUMENTS.NO_MEDICAL' | translate}}
        </span>
        <ng-template #categoryB>
          <span>{{'DOCUMENTS.NO_ALL_DATA' | translate}}</span>
        </ng-template>
      </p>
      <div class="item-data id-number-data">
        <div>
          <div *ngIf="searchImageUrl(0,3);else uploadImg8" class="page active-doc medical-image medical-image-margin">
            <img (click)="deleteDock( searchImageUrl(0,3))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(0,3) }}'>
          </div>
          <ng-template #uploadImg8>
            <div [class.errorDoc]="error && !searchImageUrl(0,3) " class="page medical-image medical-image-margin">

              <input (click)="documentStep = 8" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_medical"
                     ngFileSelect type="file">

              <label for="file_medical">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.FRONT_SIDE' | translate}}</p>
        </div>
        <div>
          <div *ngIf="searchImageUrl(1,3);else uploadImgMedical2" class="page active-doc medical-image">
            <img (click)="deleteDock( searchImageUrl(1,3))" class="cancel-dock" src="../../../assets/icons/cancel.svg">
            <img src='{{storagePath + searchImageUrl(1,3) }}'>
          </div>
          <ng-template #uploadImgMedical2>
            <div [class.errorDoc]="error && !searchImageUrl(1,3) " class="page medical-image">

              <input (click)="documentStep = 9" (uploadOutput)="onUploadOutput($event, 'img', 'posts', 'img')"
                     [options]="options" [uploadInput]="uploadInput"
                     id="file_medical2"
                     ngFileSelect type="file">

              <label for="file_medical2">
                <img src="../../../assets/icons/download-cloud.svg">
                <p>{{'DOCUMENTS.UPLOAD' | translate}}</p>
              </label>
            </div>
          </ng-template>
          <p>{{'DOCUMENTS.REVERSE_SIDE' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="next-block">
      <div *ngIf="checkValidDocuments()" class="success-data">{{'DOCUMENTS.READY_APPLICATION' | translate}}</div>
      <button (click)="nextStep()" *ngIf="categoryEnum !== 1; else next"
              class="next aplication">{{'DOCUMENTS.APPLICATION' | translate}}</button>
      <ng-template #next>
        <button (click)="nextStep()" class="next">{{'DOCUMENTS.NEXT' | translate}}</button>
      </ng-template>
    </div>
  </div>
</div>
