export class PrivacyPolicy{
  public static value(): any {
    return [
      {
        theme: 'Загальні положення',
        themeItem: [
          {
            text: '  У межах цієї Політики конфіденційності персональною інформацією є:',
            item: [
              {
                definition: 'ідентифікаційні та контактні дані',
                itemText: ', як-от: ім’я, прізвище, по батькові; ідентифікаційного номера облікової картки платника податків;адреса електронної пошти та номери телефонів; серії та номери документів, що засвідчують особу або їх замінюють;'
              },
              {
                definition: 'ідентифікаційні та контактні дані',
                itemText: ', як-от: ім’я, прізвище, по батькові; ідентифікаційного номера облікової картки платника податків;адреса електронної пошти та номери телефонів; серії та номери документів, що засвідчують особу або їх замінюють;'
              },
              {
                definition: 'технічні дані',
                itemText: ', які автоматично передаються Сайту у процесі їх використання Послуг за допомогою встановленого на Вашому пристрої програмного забезпечення, зокрема, адреса інтернет-протоколу (IP-адреса), дані файлів cookie, інформація про тип та версію браузера, який Ви використовуєте (або інші програми, за допомогою яких здійснюється доступ до Послуги), дата та година доступу до Послуги, технічні характеристики обладнання та програмного забезпечення, які Ви використовуєте для доступу до Сайту; '
              },
              {
                definition: 'дані профілю',
                itemText: ', як-от ім’я користувача та пароль;'
              },
              {
                definition: 'маркетингові та комунікаційні дані',
                itemText: ', які включають переваги в отриманні від нас маркетингових матеріалів та Ваші уподобання щодо комунікації;'
              },
              {
                definition: 'дані з медичної довідки',
                itemText: '(форма 083/о) щодо Вашої придатності керування транспортним засобом;'
              },
              {
                definition: 'фінансові дані ',
                itemText: '(якщо Ви приєднуєтеся до ліцензійного договору), наприклад, номер банківського рахунку або картки, коли Ви розміщуєте замовлення через реєстрацію на Сайті;'
              },
              {
                definition: 'інша інформація',
                itemText: ' про Вас, обробка якої передбачена',
                url: 'Правилами користування',
                urlUnderline: true,
                itemTextSecondPart: '.'
              }
            ]
          },
          {
            text: 'Політика конфіденційності застосовується лише до Сайту www.romdomdom.in.ua.'
          },
          {
            text: 'Ми є володільцем та розпорядником Ваших персональних даних. Одержувачем Ваших персональних даних є організації та фізичні особи, з якими ми взаємодіємо для виконання умов ліцензійного договору (публічної оферти).'
          },
          {
            text: 'Місцезнаходження персональних даних: Україна, 79066 м. Львів, вул. Сихівська, 20.'
          },
          {
            text: 'Цей Сайт може містити посилання на сторонні вебсайти, як-от наші сторінки в соціальних мережах Twitter, Facebook і LinkedIn. Натискання на ці посилання може дозволити третім особам збирати або передавати дані про Вас. Сайт не контролює та не несе відповідальності за сайти третіх осіб, на які Ви можете перейти за посиланням, доступним на Сайті.'
          }
        ],
      },
      {
        theme: 'Мета обробки персональних даних ',
        themeItem: [
          {
            text: 'Сайт збирає та зберігає тільки ту персональну інформацію, яка необхідна для надання Послуг або виконання договору із Вами, за винятком випадків, коли законодавством передбачено обов’язкове зберігання персональної інформації впродовж визначеного законом строку.'
          },
          {
            text: 'Сайт як оператор Ваших персональних даних обробляє такі дані для (мета):',
            item: [
              {
                itemText: 'Вашої ідентифікації для надання Послуг;'
              },
              {
                itemText: 'формування даних Вашого електронного кабінету (створення облікового запису);'
              },
              {
                itemText: 'надання Вам, зокрема, з використанням мобільного застосунку Сайту, доступу до персоналізованих ресурсів Сайту;'
              },
              {
                itemText: 'забезпечення Вам доступу до даних про себе через Ваш електронний кабінет, зокрема, у мобільному застосунку;'
              },
              {
                itemText: 'встановлення з Вами зворотного зв’язку, включно з надсиланням повідомлень, запитів щодо використання Сайту, надання послуг, оброблення запитів та Ваших заявок;'
              },
              {
                itemText: 'підтвердження достовірності та повноти наданих Вами персональних даних;'
              },
              {
                itemText: 'створення облікового запису для функціонування Вашого електронного кабінету на Сайті, якщо Ви дали згоду на створення облікового запису;'
              },
              {
                itemText: 'адміністрування вашої передплати (якщо Ви приєднуєтеся до договору про надання послуг), коли Ви розміщуєте замовлення через реєстрацію на Сайті, щоби ми могли надати послуги (для виконання ліцензійного договору-оферти); '
              },
              {
                itemText: 'передання нами Ваших персональних даних або доручення їх обробки третім особам відповідно до чинного законодавства;'
              },
              {
                itemText: 'адміністрування Сайту, включно з наданням Вам ефективної клієнтської та технічної підтримки в разі виникнення проблем, пов’язаних із використанням Сайту;'
              },
              {
                itemText: 'здійснення рекламної (маркетингової) діяльності за Вашою згодою;'
              },
              {
                itemText: 'збору Ваших відгуків про Послуги;'
              },
              {
                itemText: 'повідомлення Вас про порушення',
                url: 'Правил користування',
                urlUnderline: true,
                itemTextSecondPart: ' чи інших положень (політики);'
              },
            ]
          },
          {
            text: 'Сайт не здійснює обробку персональних даних щодо расової, національної приналежності, політичних поглядів, релігійних або філософських переконань, інтимного життя.'
          }
        ],
      },
      {
        theme: 'Умови обробки персональної інформації та її передання третім особам',
        themeItem: [
          {
            text: 'Обробка Ваших персональних даних здійснюється відповідно до',
            url: 'Закону',
            urlUnderline: true,
            text2: ' України від 01.06.2010 № 2297–VI «Про захист персональних даних».'
          },
          {
            text: 'Сайт зберігає Вашу персональну інформацію згідно з внутрішніми регламентами Сайту.'
          },
          {
            text: 'Зберігання отриманих нами Ваших персональних даних здійснюється на матеріальних (паперових та електронних) носіях, зокрема, в інформаційній системі персональних даних. Право доступу до Ваших персональних даних на матеріальних носіях мають (дістануть) тільки ті працівники Адміністрації Сайту, яким це необхідно для виконання їх посадових обов’язків та які в законному порядку наділені відповідними повноваженнями та правами доступу до Ваших персональних даних та засобів їх обробки.'
          },
          {
            text: 'Щодо Вашої персональної інформації зберігається її конфіденційність, крім випадків добровільного надання Вами інформації про себе для загального доступу необмеженому колу осіб. Під час використання Послуги Ви погоджується з тим, що певна частина Вашої персональної інформації стає загальнодоступною.'
          },
          {
            text: 'Сайт має право передати Вашу персональну інформацію третім особам у таких випадках:',
            item: [
              {
                itemText: 'Ви дали згоду на такі дії;'
              },
              {
                itemText: 'передання необхідних для використання Вами Послуг або для виконання ліцензійного договору з Вами;'
              },
              {
                itemText: 'передання передбачене українським законодавством у межах встановленої законом процедури;'
              },
              {
                itemText: 'у разі продажу Сайту до набувача переходять усі зобов’язання з дотримання умов цієї Політики щодо отриманої ним персональної інформації.'
              },
            ]
          },
          {
            text: 'Ви даєте згоду на передання таких Ваших персональних даних третім особам (взаємодіючими організаціями та фізичними особами) для організації проведення занять із теоретичної підготовки та практичного керування транспортним засобом:',
            addingInformation: 'У разі передання Ваших персональних даних ми попередимо третіх осіб, які одержать Ваші персональні дані, про те, що ці дані можуть бути використані лише для наперед визначеної мети, а також будемо вимагати від цих осіб підтвердження того, що це правило було (буде) дотримано. Ми включимо в договори з взаємодіючими організаціями та фізичними особами про передання Ваших персональних даних вимоги дотримання конфіденційності (включно з зобов’язаннями нерозголошення) та забезпечення безпеки Ваших персональних даних під час їх обробки.',
            item: [
              {
                definition: 'ідентифікаційні та контактні дані',
                itemText: ', як-от: ім’я, прізвище, по батькові; адреса електронної пошти та номери телефонів; серії та номери документів, що засвідчують особу або їх замінюють;'
              },
              {
                definition: 'ідентифікаційного номера',
                itemText: 'облікової картки платника податків;'
              },
              {
                definition: 'дані з медичної довідки',
                itemText: '(форма 083/о) щодо Вашої придатності керування транспортним засобом.'
              },
            ]
          },
          {
            text: 'Строки обробки та зберігання Ваших персональних даних визначаються відповідно до;',
            addingInformation: 'За загальним правилом, строк обробки Ваших персональних даних не обмежений.',
            item: [
              {
                itemText: 'строку дії договору (цивільно-правових відносин) з Вами;'
              },
              {
                itemText: 'строків зберігання документів, що містять персональні дані (на паперових та електронних носіях), встановленими Наказом [гіперпосилання] Міністерства юстиції України від 12.04.2012 № 578/5 «Про затвердження Переліку типових документів, що створюються під час діяльності державних органів та органів місцевого самоврядування, інших установ, підприємств та організацій, із зазначенням строків зберігання документів»;'
              },
              {
                itemText: 'строку позовної давності;'
              },
              {
                itemText: 'інших вимог, що визначають строки обробки та зберігання персональних даних, встановленими актами законодавства та іншими нормативно-правовими актами;'
              },
              {
                itemText: 'строку дії Вашої згоди.'
              },
            ]
          },
          {
            text: 'Підставами для припинення обробки Ваших персональних даних можуть бути:',
            item: [
              {
                itemText: 'досягнення мети обробки Ваших персональних даних;'
              },
              {
                itemText: 'закінчення строку дії Вашої згоди на обробку персональних даних;'
              },
              {
                itemText: 'відкликання Вами згоди на обробку Ваших Персональних даних;'
              },
              {
                itemText: 'виявлення неправомірної обробки Ваших персональних даних.'
              },
              {
                itemText: 'строку дії Вашої згоди.'
              },
            ]
          },
          {
            text: 'Обробка Ваших персональних даних здійснюється будь-яким законним способом, зокрема, в інформаційних системах персональних даних із використанням засобів автоматизації або без використання таких засобів.',
          },
          {
            text: 'У разі втрати або розголошення персональних даних Ми поінформуємо Вас про це.',
          },
          {
            text: 'Ми вживаємо необхідних організаційних та технічних заходів для захисту Вашої персональної інформації від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб.',
          },
          {
            text: 'Ми спільно з Вами вживаємо необхідних заходів щодо запобігання збиткам або іншим шкідливим наслідкам, які спричинені втратою або розголошенням Ваших персональних даних.',
          },
        ],
      },
      {
        theme: 'Зобов’язання сторін',
        themeItem: [
          {
            text: 'Ви зобов’язані:',
            item: [
              {
                itemText: 'надати інформацію про Ваші персональні дані, яка необхідна для користування Сайтом, забезпечувати на момент надання їх точність, достовірність та актуальність;'
              },
              {
                itemText: 'оновлювати, доповнювати надану інформацію про персональні дані в разі зміни такої інформації.'
              },
            ]
          },
          {
            text: 'Ми зобов’язані:',
            item: [
              {
                itemText: 'використовувати отриману інформацію для досягнення мети, яка зазначена в цій Політиці конфіденційності;'
              },
              {
                itemText: 'забезпечити зберігання конфіденційної інформації в таємниці, не розголошувати без Вашої попередньої письмової згоди, а також не здійснювати продаж, обмін, опублікування або розголошення іншими можливими способами переданих Ваших персональних даних, крім винятків передбачених цією Політикою конфіденційності;'
              },
              {
                itemText: 'вживати заходів обережності для захисту конфіденційності Ваших персональних даних згідно з порядком, який зазвичай використовується для захисту такого роду інформації в сучасному діловому обігу;'
              },
              {
                itemText: 'здійснити блокування персональних даних щодо Вас із моменту звернення або Вашого запиту або Вашого законного представника або уповноваженого органу з захисту прав суб’єктів персональних даних на період перевірки в разі виявлення недостовірних персональних даних або неправомірних дій.'
              },
            ]
          },
        ]
      },
      {
        theme: 'Відповідальність сторін',
        themeItem: [
          {
            text: 'Якщо ми не виконали свої зобов’язання, ми несемо відповідальність за збитки, понесені Вами внаслідок неправомірного використання Ваших персональних даних, відповідно до законодавства України.',
          },
          {
            text: 'У разі втрати або розголошення конфіденційної інформації Ми не несемо відповідальності, якщо така конфіденційна інформація:',
            item: [
              {
                itemText: 'стала публічним надбанням до її втрати або розголошення;'
              },
              {
                itemText: 'була отримана від третьої сторони до моменту її отримання нами;'
              },
              {
                itemText: 'була розголошена за Вашою згодою.'
              }
            ]
          },
        ]
      },
      {
        theme: 'Вирішення спорів',
        themeItem: [
          {
            text: 'До звернення в суд із позовом у спорах, що виникають із відносин між Вами та нами, обов’язковим є пред’явлення претензії (письмової пропозиції про добровільне врегулювання спору).',
          },
          {
            text: 'Одержувач претензії впродовж 10 (десяти) календарних днів із дня отримання претензії письмово повідомляє заявника претензії про результати розгляду претензії.',
          },
          {
            text: 'У разі недосягнення згоди спір буде переданий на розгляд до суду відповідно до чинного законодавства України.',
          },
          {
            text: 'До цієї Політики конфіденційності та відносин між Вами та нами застосовується чинне законодавство України.',
          },
        ]
      },
      {
        theme: 'Інші умови',
        themeItem: [
          {
            text: 'Ми маємо право змінити Політику конфіденційності без Вашої згоди, змінивши цю сторінку. Деякі умови, що містяться в цій Політиці конфіденційності, також можуть бути замінені положеннями або повідомленнями, які публікуються в іншому місці на Сайті. Очікується, що Ви будете час від часу перевіряти цю сторінку, щоби помічати будь-які зміни, оскільки вони є обов’язковими для Вас.',
          },
          {
            text: 'Нова Політика конфіденційності набирає чинності з моменту її розміщення на Сайті, якщо інше не передбачено новою редакцією Політики конфіденційності.',
          },
          {
            text: 'Усі пропозиції або питання з цієї Політики конфіденційності повідомляйте на адресу:',
            url: ' romdomdomofficial@gmail.com'
          },
          {
            text: 'Чинна Політика конфіденційності розміщена на сторінці за адресою:',
            url: 'Правил користування',
            text2: ', розміщених на сторінці за адресою: ',
            url2: 'www.romdomdom.in.ua.',
            urlUnderline: true
          },
        ]
      }
    ]
  }
}
