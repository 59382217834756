import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingMaterialsRoutingModule } from './training-materials-routing.module';
import { SignCategoriesComponent } from './components/sign-categories/sign-categories.component';
import { SignCategoryComponent } from './components/sign-category/sign-category.component';
import { SignComponent } from './components/sign/sign.component';
import { SignsService } from './services/signs.service';
import { SharedModule } from '../../shared/shared.module';
import { TrafficRuleComponent } from './components/traffic-rule/traffic-rule.component';
import { TrafficRuleCategoriesComponent } from './components/traffic-rule-categories/traffic-rule-categories.component';
import { TrafficRuleCategoryComponent } from './components/traffic-rule-category/traffic-rule-category.component';
import { RoadMarkingCategoriesComponent } from './components/road-marking-categories/road-marking-categories.component';
import { RoadMarkingCategoryComponent } from './components/road-marking-category/road-marking-category.component';
import { RoadMarkingComponent } from './components/road-marking/road-marking.component';
import { PenaltyComponent } from './components/penalty/penalty.component';
import { PenaltiesComponent } from './components/penalties/penalties.component';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import { TrafficRuleSearchComponent } from './components/search-components/traffic-rule-search/traffic-rule-search.component';
import { SearchSignsComponent } from './components/search-components/search-signs/search-signs.component';
import { SearchRoadMarkingsComponent } from './components/search-components/search-road-markings/search-road-markings.component';
import { PenaltiesSearchComponent } from './components/search-components/penalties-search/penalties-search.component';
import { RoadMarkingMenuComponent } from './components/menu-components/road-marking-menu/road-marking-menu.component';
import { SignMenuComponent } from './components/menu-components/sign-menu/sign-menu.component';
import { PenaltiesMenuComponent } from './components/menu-components/penalties-menu/penalties-menu.component';



@NgModule({
    declarations: [SignCategoriesComponent,
      SignCategoryComponent,
      SignComponent,
      TrafficRuleComponent,
      TrafficRuleCategoriesComponent,
      TrafficRuleCategoryComponent,
      RoadMarkingCategoriesComponent,
      RoadMarkingCategoryComponent,
      RoadMarkingComponent,
      PenaltyComponent,
      PenaltiesComponent,
      TrafficRuleSearchComponent,
      SearchSignsComponent,
      SearchRoadMarkingsComponent,
      PenaltiesSearchComponent,
      RoadMarkingMenuComponent,
      SignMenuComponent,
      PenaltiesMenuComponent],
    imports: [
        CommonModule,
        SharedModule,
        TrainingMaterialsRoutingModule,
        FroalaEditorModule
    ],
    exports: [
        SignComponent
    ],
    providers: [
        SignsService
    ]
})
export class TrainingMaterialsModule { }
