<div [class.border-bottom]="page === 0"
     [class.course-result-noRegister]="noRegister"
     class="course-result-view">

  <div [class.disableProgress]="noRegister"></div>
  <div [class.noRegister]="noRegister" class="item">
    <p>{{'COURSE.ADDING_INFORMATION.THEORY' | translate}}</p>
    <span *ngIf="value>0 && value < 100 && !noRegister"
          [ngStyle]="{'left.px':value < 80 ? ((255-35)*value)/100 : 0,
                              'color':'#051C2C',
                              'font-family': 'Gotham Pro Bold'}"
          class="inscription-position">{{value}}%</span>
    <mat-progress-bar [value]="value"
                      class="example-margin"
                      color="" mode="determinate">
    </mat-progress-bar>
    <span *ngIf="value>0 && !noRegister;else zero_result"
          [ngClass]="{'bold':value === 100}"
          [ngStyle]="{'left.px':-30}"
          class="inscription-position">100%</span>
    <ng-template #zero_result>
              <span [ngStyle]="{'left.px':-15}"
                    class="inscription-position">0%</span>
    </ng-template>
  </div>
  <div *ngIf="tripCount !== 0" class="item padding">
    <p [class.noRegisterDriveText]="noRegister">{{'COURSE.ADDING_INFORMATION.PRACTICE' | translate}}</p>
    <div [ngClass]="{'success':drivingValues === tripCount}"
         class="driving-count">{{successTrip > 0 ? successTrip : 0}} <span *ngIf="tripCount">з {{tripCount}}</span>
    </div>
    <span
      *ngFor="let lesson of practiseArray; let i = index"
      [class.noRegisterDrive]="noRegister"
      [class.progressWidth2]="practiseArray.length > 12 && practiseArray.length < 20"
      [class.progressWidth3]="practiseArray.length > 19 && practiseArray.length < 25"
      [class.progressWidth4]="practiseArray.length > 25"
      [ngClass]="{'success-drive':i < drivingValues}"
      [ngStyle]="{'background': tripStatus && statuses && i < statuses.length ? tripStatus[statuses[i]].color : '',
                  'border-color': tripStatus && statuses && i < statuses.length ? tripStatus[statuses[i]].color : ''}"
      class="lesson"></span>
  </div>
</div>
