import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../core/services/storage.service';

@Component({
  selector: 'app-menu-registration',
  templateUrl: './menu-registration.component.html',
  styleUrls: ['./menu-registration.component.scss']
})
export class MenuRegistrationComponent implements OnInit {
  @Input() editStatusDocuments: boolean;
  statusMenu: Array<boolean>;
  registrationMenu: Array<{ name: string }>;
  categoryEnum: number;
  purchaseType: number;
  personalInfo: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService) {
    this.registrationMenu = [
      {
        name: 'DRIVING_SCHOOL_REGISTRATION.MENU.PERSONAL_INFORMATION'
      },
      {
        name: 'DRIVING_SCHOOL_REGISTRATION.MENU.PAYMENT'
      },
      {
        name: 'DRIVING_SCHOOL_REGISTRATION.MENU.UPLOAD'
      }
    ];
  }

  ngOnInit(): void {
    this.categoryEnum = this.storageService.get('registCategory') ? this.storageService.get('registCategory').value.catEnum : -1;
    this.purchaseType = this.storageService.get('personalInfo')  ?
      (this.storageService.get('personalInfo').value !== null ? this.storageService.get('personalInfo').value.purchaseType : null) : -1;
    this.personalInfo = this.storageService.get('personalInfo') ?
      (this.storageService.get('personalInfo').value !== null ? this.storageService.get('personalInfo').value.isPurchased : false) : false;
  }


  getValue(i): boolean {

    const menuValue = this.storageService.get('correctInformation') ?
      this.storageService.get('correctInformation').value : '';
    this.statusMenu = Object.values(menuValue);
    return this.statusMenu[i];
  }

  registrationStep(): number {
    const temp = this.storageService.get('registrationStep').value;
    if (this.storageService.get('documents')) {
      if (this.storageService.get('documents').value) {
        return 3;
      }
    }
    return temp;
  }
}
