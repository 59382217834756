import {UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions} from 'ngx-uploader';
import {EventEmitter} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { StorageService } from '../../core/services/storage.service';
import { Config } from '../property/constant';
import { propToLocalStorage } from '../decorators/storage.decorator';

export class UploadImg {

  fileUploaded: any;
  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;

  @propToLocalStorage('token')
  public token;

  constructor(public spinner: NgxSpinnerService, storageService: StorageService) {
    this.options = {concurrency: 10, maxUploads: 10};
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
  }

  // Upload IMG
  onUploadOutput(output: UploadOutput, queryType, folderType?, imgType?) {
    this.spinner.show();
    if (output.type === 'allAddedToQueue') {
      switch (queryType) {
        case 'img': {
          this.uploadImg(folderType);
          break;
        }
        case 'video': {
          this.uploadVideo();
          break;
        }
        case 'file': {
          this.uploadFile();
          break;
        }
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      this.fileUploaded = output.file.response;
      this.uploadResponse(this.fileUploaded, this.files, queryType, imgType);
      this.spinner.hide();
    }
  }

  uploadImg(folderType) {
    const event: UploadInput = {
      type: 'uploadAll',
      url: Config.apiUrl() + 'studentpersonaldetails/documents/upload',
      method: 'POST',
      fieldName: 'image',
      headers: {
        folder: folderType,
        Authorization: 'Bearer ' + this.token.token
      }
    };
    this.uploadInput.emit(event);
  }

  uploadResponse(file?, files?, queryType?, imgType?): void {
  }

  uploadVideo(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: Config.apiUrl() + 'Upload/video',
      method: 'POST',
      data: {foo: 'bar'}
    };
    this.uploadInput.emit(event);
  }

  uploadFile(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: Config.apiUrl() + 'Upload/file',
      method: 'POST',
      data: {foo: 'bar'}
    };
    this.uploadInput.emit(event);
  }
}
