import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrafficRulesService } from '../../services/traffic-rules.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialCategories } from '../../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Subscription } from '~/node_modules/rxjs';


@Component({
  selector: 'app-traffic-rule-category',
  templateUrl: './traffic-rule-category.component.html',
  styleUrls: ['./traffic-rule-category.component.scss']
})
export class TrafficRuleCategoryComponent implements OnInit, OnDestroy {

  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  trafficRules: TrainingMaterialComponents[];
  openComment = [];
  trafficRuleId: string;
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
    this.trafficRuleId = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.router.onSameUrlNavigation = 'reload';
    const categoryId = this.activatedRoute.snapshot.params.categoryId;
    this.categories = this.trafficRulesService.getTrafficRuleCategories();
    this.subscriptions.add(this.trafficRulesService.getTrafficRulesByCategory(this.activatedRoute.snapshot.params.categoryId)
      .subscribe((res: TrainingMaterialComponents[]) => {
        this.trafficRules = res;
        setTimeout(() => {
          const el = document.getElementById(this.trafficRuleId);
          if (el) {
            el.scrollIntoView();
            el.style.marginTop = '20px';
          } else {
            window.location.reload();
          }
        }, 0);
      }));
    this.subscriptions.add(this.categories.subscribe(categories => {
      this.activeCategory = categories.find(cat => cat.id === categoryId);
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  open(el): void {
    if (this.openComment.includes(el)) {
      this.openComment = this.openComment.filter(element => element !== el);
    } else {
      this.openComment.push(el);
    }
  }

  changeCategory(category): void {
    this.activeCategory = category;
    this.spinner.show();
    this.subscriptions.add(this.trafficRulesService.getTrafficRulesByCategory(category.id)
      .subscribe((res: TrainingMaterialComponents[]) => {
        this.trafficRules = res;
      }, ()=>{

      }, ()=>{
        this.spinner.hide();
      }));
  }
}

