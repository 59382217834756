<div *ngIf="categories" class="menu-container">
  <div class="menu-container-top">
    <div class="title"><p>{{'TRAINING_MATERIAL.ROAD_MARKING_TITLE' | translate}}</p></div>
    <div class="road-marking-icon"><img src="../../../assets/icons/general-road-marking.svg"></div>
  </div>
  <div class="menu-content-container">
    <a (click)="changeCategory(category)"
         *ngFor="let category of categories | async"
         class="menu-item"
       [routerLink]="'/home/tm/road-marks/category/'+category.id">
      <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
        <div class="number">
          <p>{{category.number}}.</p>
        </div>
        <div class="theme-name">
          <p>{{category.name}}</p>
        </div>
        <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
      </div>
    </a>
  </div>
</div>
