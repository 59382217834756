import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrainingMaterialComponents } from '~/src/app/shared/interfaces/training-material-components';
import { SearchCategories } from '~/src/app/shared/modules/searchCategories';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

  getSearch(searchText): Observable<SearchCategories> {
    return this.http.get<SearchCategories>(this.url + `search?search=${searchText}`);
  }

  searchByTrafficLowsId(chapterId, searchText): Observable<TrainingMaterialComponents[]> {
    return this.http.get<TrainingMaterialComponents[]>(this.url + `search/trafficrules/chapterid/${chapterId}?search=${searchText}`);
  }
  searchByRoadMarkingsId(categoryId, searchText): Observable<any> {
    return this.http.get(this.url + `search/roadmarkings/categoryid/${categoryId}?search=${searchText}`);
  }

  searchBySignsId(categoryId, searchText): Observable<TrainingMaterialComponents[]> {
    return this.http.get<TrainingMaterialComponents[]>(this.url + `search/signs/categoryid/${categoryId}?search=${searchText}`);
  }

  searchByPenalties(searchText): Observable<TrainingMaterialComponents> {
    return this.http.get<TrainingMaterialComponents>(this.url + `search/penalties?search=${searchText}`);
  }
}
