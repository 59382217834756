<div class="dialog-content container">
  <div class="content container-information">
    <div class="timer">
      <img src="../../../assets/icons/clock.svg">
      <span>20:00</span>
    </div>
    <p class="title">Внутрішній тест</p>
    <p class="subtitle ">Щоб отримати доступ до екзамену в МРЕО тобі потрібно успішно здати внутрішній тест у
      додатку.</p>
    <p class="subtitle ">Ти маєш 20 хв на виконання всіх завдань та не більше трьох помилок. В іншому випадку тест буде
      автоматично не складено</p>
    <p class="subtitle ">У разі не здачі ти можеш повторити тестування</p>
    <button (click)="closeDialog(false)" class="continue">Розпочати</button>
  </div>
</div>
