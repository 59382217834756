import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignsService } from '../../../services/signs.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from '~/node_modules/rxjs';
import { TrainingMaterialCategories } from '~/src/app/shared/interfaces/training-material-categories';

@Component({
  selector: 'app-sign-menu',
  templateUrl: './sign-menu.component.html',
  styleUrls: ['./sign-menu.component.scss']
})
export class SignMenuComponent implements OnInit, OnDestroy {
  @Input() categories: Observable<TrainingMaterialCategories[]>;
  @Input() activeCategory: string;
  subscriptions: Subscription = new Subscription();

  constructor(private signsService: SignsService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.categories = this.signsService.getSignCategories();
    this.subscriptions.add(this.categories.subscribe(() => {
    }, error=>{
      // error during done subscribe
    }, ()=>{
      // After complete subscribe
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  changeCategory(category): void {
    this.activeCategory = this.activatedRoute.snapshot.params.email;
    this.router.navigate([`/home/tm/signs/category/${category.id}`]);
  }
}
