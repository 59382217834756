<div class="block-menu">
  <img src="../../../assets/icons/big-logo.svg">
  <div class="menu-navigation">
    <div>
      <p class="title"> {{'DRIVING_SCHOOL_REGISTRATION.MENU.TITLE' | translate}}</p>
      <div class="navigation">
        <div *ngFor="let item of registrationMenu; let i = index" [class.active]="registrationStep() === i+1"
             class="flex nav-item">
          <img *ngIf="getValue(i) || (!editStatusDocuments && registrationStep() > i + 1 )"
               src="../../../assets/icons/ok-circle-green.svg">
          <img *ngIf="editStatusDocuments && !getValue(i)" src="../../../assets/icons/ok-circle-danger.svg">
          <p class="number">{{i + 1}}</p>
          <p class="item">{{ item.name| translate}}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <p class="category">Категорія B</p>
      <p class="category-subscription">
        <ng-container *ngIf="(categoryEnum === 0 && !personalInfo) || (purchaseType === 0 && personalInfo)">
          Повноцінне навчання в онлайн автошколі
        </ng-container>
        <ng-container *ngIf="(categoryEnum === 3 && !personalInfo) || (purchaseType === 2 && personalInfo)">
          Лише лекційний курс
        </ng-container>
        <ng-container *ngIf="(categoryEnum === 2 && !personalInfo) || (purchaseType === 1 && personalInfo)">
          Курс лекцій та практики
        </ng-container>
      </p>
    </div>
  </div>
</div>
