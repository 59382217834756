<div class="content">
  <div class="reject-content">
    <div class="icon">
      <img src="../../../assets/icons/exclamation-circle-error.svg">
    </div>
    <div class="reject-content-data">
      <div>
        <p class="title">
          Ваша заявка на навчання не підтверджена
        </p>
        <p *ngIf="rejectText" class="reject-text">
          {{rejectText}}
        </p>
      </div>
      <button routerLink="/driving-school-registration/edit">
        Перезавантажити
      </button>
    </div>
  </div>
</div>
