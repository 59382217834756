<div class="block" [class.block-pay]="!isPartial">
  <p class="title">{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.TITLE' | translate}}</p>
  <div class="block-content">
    <div *ngIf="!isPartial">
      <p>{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.DO_PAY' | translate}}</p>
      <p>{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.AFTER_PAY' | translate}}</p>
      <p>{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.OPEN_PAY' | translate}}</p>
      <p>{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.CONTRACT' | translate}}</p>
    </div>
  <div *ngIf="isPartial && tariff != null" class="row pay-row">
    <div class="col-49">
      <div #block2 id="block2" class="row col-block" >
        <div class="text-block">
          <div class="radio">
            <input #rad2 id="radio-2" (click)="ChangeColor(rad2,block2,block1)" name="radio" type="radio">
            <label for="radio-2" class="radio-label">Повна</label>
          </div>
          <span class="col-price">{{tariff.price}} <span class="italic">грн</span></span>
        </div>
      </div>

      <div id="block1" style="color: white; background-color: #23C1B8;" #block1 class="row col-block">
        <div class="text-block">
          <div class="radio">
            <input #rad1 id="radio-1" (click)="ChangeColor(rad1, block1, block2)" name="radio" type="radio" checked>
            <label for="radio-1" class="radio-label">Часткова</label>
          </div>
          <span class="col-price">{{tariff.partialPayments[0].sum}} <span class="italic">грн</span></span>
        </div>
      </div>
    </div>
    <div class="vl">

    </div>
    <div class="col-49">
      <ul *ngFor ="let description of descriptions; index as i;" class="pay-ul">
        <li class="pay-list">
          {{i + 1}} платіж = {{tariff.partialPayments[i].sum}} грн<br>
          <span class="li-description">{{description}}</span>
        </li>
      </ul>
    </div>
  </div>

    <div class="submit" (click)="preSubmit()">
      <form method="POST" accept-charset="utf-8" id="payForm"  action="https://www.liqpay.ua/api/3/checkout">
        <input id="paymentData" type="hidden" name="data" [value]="paymentData"  />
        <input id="paymentSignature" type="hidden" name="signature" [value]="paymentSignature"  />
        <button class="next">{{'DRIVING_SCHOOL_REGISTRATION.PAYMENT.PAY' | translate}}</button>
      </form>
    </div>

  </div>
</div>

