<div class="content" *ngIf="registrationStep()>0">
  <img class="cancel" src="../../../assets/icons/cancel.svg" (click)="exit()">
  <app-menu-registration [editStatusDocuments] = editStatusDocuments></app-menu-registration>
  <div class="main-content">
    <div *ngIf="registrationStep() === 1">
      <app-personal-information [editStatusDocuments] = editStatusDocuments></app-personal-information>
    </div>
    <div *ngIf="registrationStep() === 2">
      <app-payment></app-payment>
    </div>
    <div *ngIf="registrationStep() === 3">
      <app-documents [editStatusDocuments] = editStatusDocuments></app-documents>
    </div>
  </div>
</div>
