import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../../shared/property/constant';
import { Observable } from 'rxjs';
import { TrainingMaterialCategories } from '../../../shared/interfaces/training-material-categories';
import { TrainingMaterialComponents } from '../../../shared/interfaces/training-material-components';

@Injectable({
  providedIn: 'root'
})
export class SignsService {

  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

  getSignCategories(): Observable<TrainingMaterialCategories[]> {
    return this.http.get<TrainingMaterialCategories[]>(this.url + 'signcategories');
  }


  getSignsByCategory(id: string): Observable<TrainingMaterialComponents[]> {
    return this.http.get<TrainingMaterialComponents[]>(this.url + 'signs/categoryid/' + id);
  }

  getSignById(id: string): Observable<TrainingMaterialComponents> {
    return this.http.get<TrainingMaterialComponents>(this.url + 'signs/' + id);
  }

}
