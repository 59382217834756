<a routerLink="home/tests/test/ticket-tests" class="back-to-category">
  <img src="../../../../../../assets/icons/arrow-left-grey.svg">
  <p>{{'TESTS.BACK-TO-TICKETS' | translate}}</p>
</a>
<div class="ticket-container">
  <app-ticket-content [link]="'/home/tests/test/ticket-tests'"
                      [testItems]="testsById"
                      [testKind]="'TESTS.TICKETS' | translate">
  </app-ticket-content>
</div>
