<div class="cropping-block">

  <div class="crop-image">
    <image-cropper
      #cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      format="png"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>
  <div class="preview-image">
    <div>
      <img [src]="croppedImage"  alt="croppedImage"/>
      <div>
        <button (click)="closeDialog(croppedImage)">Зберегти</button>
      </div>
    </div>
  </div>
</div>
