import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-penalties-menu',
  templateUrl: './penalties-menu.component.html',
  styleUrls: ['./penalties-menu.component.scss']
})
export class PenaltiesMenuComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
