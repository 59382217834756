import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrafficRulesService } from '../../../../training-materials/services/traffic-rules.service';
import { TestsOnThemesService } from '../../../services/tests-on-themes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectedTest } from '../../../../../shared/interfaces/selected-test';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../core/services/storage.service';
import { TrainingMaterialCategories } from '../../../../../shared/interfaces/training-material-categories';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-selected-tests',
  templateUrl: './selected-tests.component.html',
  styleUrls: ['./selected-tests.component.scss']
})
export class SelectedTestsComponent implements OnInit, OnDestroy {
  categories: Observable<SelectedTest[]>;
  activeCategory: TrainingMaterialCategories;
  favourites = 0;
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private testsOnThemes: TestsOnThemesService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private storageService: StorageService
  ) {
  }

  initTranslate(): void {
    this.storageService.get('lang');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getFavourites();
  }

  getFavourites(): void {
    this.categories = this.testsOnThemes.getFavouritesTest();
    this.subscriptions.add(this.categories.subscribe((res: SelectedTest[]) => {
      this.favourites = res.length;
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  deleteFavouriteQuestion(question): void {
    this.spinner.show();
    this.subscriptions.add(this.testsOnThemes.deleteQuestionFromFavourite(question).subscribe(() => {
      this.getFavourites();
    }));
  }
}
