import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { propToLocalStorage } from '../../shared/decorators/storage.decorator';

@Injectable({
  providedIn: 'root'
})
export class StudentRegistrationGuard implements CanActivate {
  @propToLocalStorage('token')
  public token;

  @propToLocalStorage('registCategory')
  public registCategory;
  constructor(private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.token && this.registCategory) {
      return true;
    }
    if (this.token && window.localStorage.getItem('documents')) {
      return true;
    }
    else {
      this.router.navigate([`/registration-category-select`]);
      return false;
    }
  }

}
