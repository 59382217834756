import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrafficRulesService } from '../../services/traffic-rules.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialComponents } from '../../../../shared/interfaces/training-material-components';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-traffic-rule',
  templateUrl: './traffic-rule.component.html',
  styleUrls: ['./traffic-rule.component.scss']
})
export class TrafficRuleComponent implements OnInit, OnDestroy {

  trafficRule: TrainingMaterialComponents;
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.spinner.show();

    this.subscriptions.add(this.trafficRulesService.getTrafficRuleById(this.activatedRoute.snapshot.params.id)
      .subscribe((res: TrainingMaterialComponents) => {
      this.trafficRule = res;
    }, ()=>{

      }, ()=>{
        this.spinner.hide();
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
