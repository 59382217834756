import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InterceptorService } from '../core/services/interceptor.service';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { HintsComponent } from './dialogs/hints/hints.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TestItemComponent } from './components/test-item/test-item.component';
import { FinishedTestComponent } from './components/finished-test/finished-test.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OutsideClickDirective } from './directives/click-outside.directive';
import { CropImageComponent } from './dialogs/crop-image/crop-image.component';
import { TiketGuard } from '../core/guards/tiket.guard';
import { SelectCategoryComponent } from './dialogs/select-category/select-category.component';
import { MatRadioModule } from '@angular/material/radio';
import { LeaveTestComponent } from './dialogs/leave-test/leave-test.component';
import { MobileVersionComponent } from './dialogs/mobile-version/mobile-version.component';
import { FinalTestComponent } from './dialogs/final-test/final-test.component';

import { HighlightsTextPipe } from './pipes/highlights-text.pipe';
import { CancelRegistrationComponent } from './dialogs/cancel-registration/cancel-registration.component';
import { SuccessRegistrationComponent } from './dialogs/success-registration/success-registration.component';
import { RegistrationDataComponent } from './dialogs/registration-data/registration-data.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { StartLearningComponent } from './components/start-learning/start-learning.component';
import { WaitConfirmComponent } from './components/wait-confirm/wait-confirm.component';
import { ChangePasswordTypeComponent } from './dialogs/change-password-type/change-password-type.component';
import { VideoPlayerComponent } from './dialogs/video-player/video-player.component';
import { RejectApplicationComponent } from './components/reject-application/reject-application.component';
import { StudentProgressComponent } from './components/student-progress/student-progress.component';
import { RouterModule } from '@angular/router';
import { ContinueLearningComponent } from './dialogs/continue-learning/continue-learning.component';
import { BookClassesComponent } from './dialogs/book-classes/book-classes.component';
import { FullInstructorInformationComponent } from './components/full-instructor-information/full-instructor-information.component';
import { ShortInstructorInformationComponent } from './components/short-instructor-information/short-instructor-information.component';
import { RejectLessonComponent } from './dialogs/reject-lesson/reject-lesson.component';
import { RejectedLessonInformationComponent } from './dialogs/rejected-lesson-information/rejected-lesson-information.component';
import { SocialInformationComponent } from './components/social-information/social-information.component';
import { PrivacyPolicyComponent } from './dialogs/privacy-policy/privacy-policy.component';
import { TestImageViewComponent } from './dialogs/test-image-view/test-image-view.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HintsComponent,
    TestItemComponent,
    FinishedTestComponent,
    OutsideClickDirective,
    CropImageComponent,
    SelectCategoryComponent,
    LeaveTestComponent,
    MobileVersionComponent,
    FinalTestComponent,
    HighlightsTextPipe,
    CancelRegistrationComponent,
    SuccessRegistrationComponent,
    RegistrationDataComponent,
    StartLearningComponent,
    WaitConfirmComponent,
    ChangePasswordTypeComponent,
    VideoPlayerComponent,
    RejectApplicationComponent,
    StudentProgressComponent,
    ContinueLearningComponent,
    BookClassesComponent,
    FullInstructorInformationComponent,
    ShortInstructorInformationComponent,
    RejectLessonComponent,
    RejectedLessonInformationComponent,
    SocialInformationComponent,
    PrivacyPolicyComponent,
    TestImageViewComponent
  ],
  imports: [
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatTreeModule,


    CommonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    NgxUploaderModule,
    FroalaViewModule.forRoot(),
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'Uk'
    }),
    FormsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RouterModule,
  ],
    exports: [
        TranslateModule,
        FroalaViewModule,
        ImageCropperModule,
        FormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        HintsComponent,
        NgxUploaderModule,
        ReactiveFormsModule,
        MatDialogModule,
        TestItemComponent,
        FinishedTestComponent,
        OutsideClickDirective,
        HighlightsTextPipe,
        StartLearningComponent,
        WaitConfirmComponent,
        RejectApplicationComponent,
        StudentProgressComponent,
        ContinueLearningComponent,
        BookClassesComponent,
        FullInstructorInformationComponent,
        ShortInstructorInformationComponent,
        SocialInformationComponent,
        PrivacyPolicyComponent,
        TestImageViewComponent


    ],
  entryComponents: [
    HintsComponent,
    CropImageComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    TiketGuard
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
