import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-continue-learning',
  templateUrl: './continue-learning.component.html',
  styleUrls: ['./continue-learning.component.scss']
})
export class ContinueLearningComponent implements OnInit {

  constructor( ) { }

  ngOnInit(): void {
  }

}
