import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SuccessRegistrationComponent } from '../../../shared/dialogs/success-registration/success-registration.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../../core/services/storage.service';
import { UploadImg } from '../../../shared/classes/upload-img';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationPersonalInformationService } from '../services/registration-personal-information.service';
import { Config } from '../../../shared/property/constant';
import { documentsTypeEnum } from '../../../shared/enums/documentsType.enum';
import { ChangePasswordTypeComponent } from '../../../shared/dialogs/change-password-type/change-password-type.component';
import { PersonalInformation } from '../../../shared/interfaces/personalInformation';
import { Subscription } from '~/node_modules/rxjs';
import { PersonalDocuments } from '~/src/app/shared/interfaces/personalDocuments';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss',
    '/src/app/modules/driving-school-registration/registration-content.scss']
})
export class DocumentsComponent extends UploadImg implements OnInit, OnDestroy {
  @Input() editStatusDocuments: boolean;
  step = 1;
  passwordStep: number;
  documentStep = 1;
  documents = {documents: []};
  copyDocuments = {documents: []};
  storagePath = Config.storageUrl();
  error: boolean;
  categoryEnum = 0;
  passwordStepData: boolean;
  idNumberStep: boolean;
  errorUpload: boolean;
  isIdInvalid: boolean;
  isIndividualTaxNumberInvalid: boolean;
  isMedicalReferenceInvalid: boolean;
  subscriptions: Subscription = new Subscription();
  purchaseType: number;

  constructor(private router: Router,
              private matDialog: MatDialog,
              public spinner: NgxSpinnerService,
              public storageService: StorageService,
              private regPersonalInformationService: RegistrationPersonalInformationService,
              private activeRouter: ActivatedRoute) {

    super(spinner, storageService);
    this.editStatusDocuments = this.activeRouter.snapshot.routeConfig.path === 'edit';
    this.passwordStep = this.storageService.get('passport-step') ? this.storageService.get('passport-step').value : 1;
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.storageService.get('dock') && this.storageService.get('dock').value !== 'no-documents') {
      this.documents = this.storageService.get('dock').value;
    }
    this.categoryEnum = this.storageService.get('registCategory') ?
      this.storageService.get('registCategory').value.catEnum : 0;
    this.subscriptions.add(this.regPersonalInformationService.getRegistrationInformation().subscribe((el: PersonalInformation) => {
      this.purchaseType = el.purchaseType;
      if (el.personalDocuments.length > 0) {
        el.personalDocuments.find((ele: PersonalDocuments) => {
          if (ele.type === 2) {
            this.storageService.set('passport-step', 2);
          }
        });
        this.passwordStep = this.storageService.get('passport-step') ? this.storageService.get('passport-step').value : 1;
        this.documents.documents = el.personalDocuments;
        if (this.editStatusDocuments) {
          this.isIdInvalid = el.courseRequestRejection.isIdInvalid;
          this.isIndividualTaxNumberInvalid = el.courseRequestRejection.isIndividualTaxNumberInvalid;
          this.isMedicalReferenceInvalid = el.courseRequestRejection.isMedicalReferenceInvalid;
          this.checkStep();
          if (this.storageService.get('editDocuments') && this.storageService.get('editDocuments').value.length > 0) {
            this.documents.documents = this.storageService.get('editDocuments').value;
          } else {
            if (el.courseRequestRejection.isIdInvalid) {
              this.documents.documents = el.personalDocuments.filter(res => res.imagePath.length > 0);
              this.documents.documents = this.documents.documents.filter(ele => (ele.type !== 1 && ele.type !== 2));
            }
            if (el.courseRequestRejection.isIndividualTaxNumberInvalid) {
              this.documents.documents.filter(ele => (ele.type === 0)).map(elem => elem.imagePath = '');
            }
            if (el.courseRequestRejection.isMedicalReferenceInvalid) {
              this.documents.documents.filter(ele => (ele.type === 3)).map(elem => elem.imagePath = '');
            }
          }
          this.storageService.set('editDocuments', this.documents.documents);
        }
        this.copyDocuments = this.documents;
        this.checkValidDocuments();
      }
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkStep(): void {
    if (this.isIdInvalid) {
      this.step = 1;
    } else if (!this.isIdInvalid && this.isIndividualTaxNumberInvalid) {
      this.step = 2;
    } else {
      this.step = 3;
    }
  }

  successRegist(): void {
    if ((this.step === 4 && this.documents.documents.length === 7) ||
      (this.step === 1 && this.purchaseType === 3 && this.documents.documents.length >= 3) ||
      (this.step === 3 && this.categoryEnum !== 1) ||
      (this.isIdInvalid && !this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid) ||
      (this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid) ||
      (this.isIdInvalid && !this.isIndividualTaxNumberInvalid && this.isMedicalReferenceInvalid)) {
      const dialogRef = this.matDialog.open(SuccessRegistrationComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.matDialog.closeAll();
          this.storageService.set('finish-registration', true);
          console.log('editStatusDocuments ',  this.editStatusDocuments);
          if (this.editStatusDocuments) {
            this.subscriptions.add(this.regPersonalInformationService.editDocuments(this.documents).subscribe(() => {
              this.router.navigate([`/home/dashboard`]);
              this.storageService.remove('editDocuments');
              this.storageService.remove('registrationStep');
            }));
          } else {
            this.subscriptions.add(this.regPersonalInformationService.addDocument(this.documents).subscribe(() => {
              this.router.navigate([`/home/dashboard`]);
            }));
          }
          this.storageService.remove('personal-information');
          this.storageService.remove('dock');
          this.storageService.remove('editDocuments');
          this.storageService.remove('editStatusDocuments');
          this.storageService.remove('registrationStep');
          this.router.navigate([`/home/dashboard`]);
        } else {
          this.matDialog.closeAll();
        }
        return result;
      });
    }
    else {
      this.error = true;
    }
  }

  uploadResponse(file, files, queryType, imgType): void {
    if (this.editStatusDocuments && this.documentStep >= 7) {
      if (this.documentStep === 7) {
        this.documents.documents.map(el => {
          if (el.type === documentsTypeEnum.IndividualTaxNumber && el.page === 0) {
            el.imagePath = file;
          }
        });
      }
      if (this.documentStep === 8) {
        this.documents.documents.map(el => {
          if (el.type === documentsTypeEnum.MedicalReference && el.page === 0) {
            el.imagePath = file;
          }
        });
      }
      if (this.documentStep === 9) {
        this.documents.documents.map(el => {
          if (el.type === documentsTypeEnum.MedicalReference && el.page === 1) {
            el.imagePath = file;
          }
        });
      }
      this.storageService.set('editDocuments', this.documents.documents);
    } else {
      if (file !== '') {
        const documentPage = {
          imagePath: '',
          page: 0,
          type: 0,
        };
        documentPage.imagePath = file;
        if (this.documentStep > 0 && this.documentStep < 4) {
          documentPage.page = this.documentStep - 1;
          documentPage.type = documentsTypeEnum.IdCard;
        }
        if (this.documentStep > 3 && this.documentStep < 7) {
          documentPage.page = this.documentStep - 4;
          documentPage.type = documentsTypeEnum.Passport;
        }
        if (this.documentStep === 7) {
          documentPage.page = 0;
          documentPage.type = documentsTypeEnum.IndividualTaxNumber;
        }
        if (this.documentStep === 8 || this.documentStep === 9) {
          documentPage.page = this.documentStep - 8;
          documentPage.type = documentsTypeEnum.MedicalReference;
        }
        if (this.documentStep === 10 || this.documentStep === 11) {
          documentPage.page = this.documentStep - 9;
          documentPage.type = documentsTypeEnum.CategoryBDrivingLicense;
        }
        this.documents.documents.push(documentPage);
        if (this.editStatusDocuments && this.documentStep < 7 && this.isIdInvalid) {
          this.storageService.set('editDocuments', this.documents.documents);
        }
        this.storageService.set('dock', this.documents);
      } else {
        this.errorUpload = true;
      }
    }
    this.checkValidDocuments();
  }

  searchImageUrl(page, type): string | false {
    const elem = this.documents.documents.find(el => (el.page === page && el.type === type));
    return (elem !== undefined) ? elem.imagePath : false;
  }

  nextStep(): void {
    this.error = false;
    console.log('step: ', this.step);
    console.log('isIdInvalid: ', this.isIdInvalid);
    console.log('isIndividualTaxNumberInvalid: ', this.isIndividualTaxNumberInvalid);
    console.log('isMedicalReferenceInvalid: ', this.isMedicalReferenceInvalid);
    console.log('documents.length: ', this.documents.documents.length);
    if ((this.step === 1 && (((this.passwordStep === 1 || this.passwordStep === 2) && this.documents.documents.length >= 3) &&
      !(this.isIdInvalid && !this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid) &&
      !(this.isIdInvalid && !this.isIndividualTaxNumberInvalid && this.isMedicalReferenceInvalid))) ||
      (this.step === 2 && this.documents.documents.length >= 4 && !(this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid))
      || (this.step === 3 && this.documents.documents.length >= 6 &&
        this.categoryEnum === 1)
    ) {
      console.log('if: enter');
      if (this.purchaseType === 3)
      {
        console.log('if: success');
        this.successRegist();
      }
      else {
        console.log('if: next step');
        this.step++;
      }
    } else if (this.step !== 3 && this.isIdInvalid && !this.isIndividualTaxNumberInvalid && this.isMedicalReferenceInvalid) {
      this.step = 3;
    } else if ((this.step === 3 && this.documents.documents.length === 6 && this.categoryEnum !== 1) ||
      (this.isIdInvalid && !this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid) ||
      (this.isIndividualTaxNumberInvalid && !this.isMedicalReferenceInvalid)
    ) {
      console.log('end');
      this.successRegist();
    } else {
      this.error = true;
    }
    this.chekFormPassword();
    this.checkIdNumber();
    if (this.step === 2) {
      this.passwordStepData = true;
    }
    if (this.step === 3) {
      this.idNumberStep = true;
    }
  }

  checkValidDocuments(): boolean {
    let statusDocuments;
    const menuError = {
      personalInformation: this.storageService.get('correctInformation') ?
        this.storageService.get('correctInformation').value.personalInformation : false,
      isPurchased: this.storageService.get('correctInformation') ? this.storageService.get('correctInformation').value.isPurchased : false,
      documents: false
    };
    if (this.purchaseType !== 3) {
      statusDocuments = ((this.documents.documents.length === 6 && !this.editStatusDocuments) ||
        (this.editStatusDocuments && this.chekFormPassword() && this.checkIdNumber() && this.checkMedical()));
    }
    else {
      statusDocuments = ((this.documents.documents.length >= 3 && this.purchaseType === 3 && !this.editStatusDocuments) ||
        (this.editStatusDocuments && this.chekFormPassword()));
    }

    menuError.documents = statusDocuments;
    this.storageService.set('correctInformation', menuError);
    return statusDocuments;
  }


  chekFormPassword(): boolean {
    return !!((this.searchImageUrl(0, 1) &&
      this.searchImageUrl(1, 1) &&
      this.searchImageUrl(2, 1)) ||
      this.searchImageUrl(0, 2) &&
      this.searchImageUrl(1, 2) &&
      this.searchImageUrl(2, 2));
  }

  checkIdNumber(): boolean {
    return !!(this.searchImageUrl(0, 0));
  }

  checkMedical(): boolean {
    return !!(this.searchImageUrl(0, 3)) && !!(this.searchImageUrl(1, 3));
  }

  passwordData(step, type): void {
    if (this.documents.documents.find(elem => elem.type === type)) {
      const dialogRef = this.matDialog.open(ChangePasswordTypeComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.passwordStep = step;
          this.matDialog.closeAll();
          this.documents.documents.map(elem => elem.type === type ? this.deleteDock(elem.imagePath) : '');
          this.storageService.set('passport-step', step);
        } else {
          this.matDialog.closeAll();
        }
      });
    } else {
      this.passwordStep = step;
      this.storageService.set('passport-step', step);
    }
    this.error = false;
  }

  successRegister(): boolean {
    if (this.purchaseType !== 3) {
      return (!this.chekFormPassword() || !this.checkMedical() || !this.checkIdNumber());
    }
    else{
      return !this.chekFormPassword();
    }
  }

  deleteDock(currentPath): void {
    if (this.editStatusDocuments) {
      const findPassword = this.documents.documents.find(elem =>  elem.imagePath === currentPath).type;
      if (findPassword === 1 || findPassword === 2) {
        const delPath = {
          path: currentPath
        };
        this.subscriptions.add(this.regPersonalInformationService.deleteDock(delPath).subscribe(() => {
          this.documents.documents.splice(this.documents.documents.indexOf(this.documents.documents
            .find(elem => elem.imagePath === currentPath)), 1);
          this.storageService.set('editDocuments', this.documents.documents);
        }));
      } else {
        this.documents.documents.map(el => {
          if (el.imagePath === currentPath) {
            el.imagePath = '';
          }
          this.storageService.set('editDocuments', this.documents.documents);
          this.checkValidDocuments();
        });
      }
    } else {
      const delPath = {
        path: currentPath
      };
      this.subscriptions.add(this.regPersonalInformationService.deleteDock(delPath).subscribe(() => {
        this.documents.documents.splice(this.documents.documents.indexOf(this.documents.documents
          .find(elem => elem.imagePath === currentPath)), 1);
        this.storageService.set('dock', this.documents);
      }));
    }
  }

  reload(): void {
    window.location.reload();
  }
}
