<div [ngStyle]="{'width':(finalTest && test >= 90) ? '770px' : ''}"
     class="finished-test-container">
  <div *ngIf="(finalTest && test < 90) || !finalTest"
       class="content-top"></div>
  <div *ngIf="finalTest && test >= 90"
       class="final-test"></div>
  <div [ngClass]="{'final-content-bottom':(finalTest && test >= 90)}"
       class="content-bottom">
    <div [ngStyle]="{'width.%':finalTest && test >= 90 ? '44' : ''}"
         class="bottom-left-side">
      <div class="empty-block">
        <div class="test-mark">{{number}}%</div>
        <mat-progress-spinner [value]="number" mode="determinate"></mat-progress-spinner>
      </div>
    </div>
    <div class="bottom-right-side">
      <div class="result-title">
        <p *ngIf="finalTest && test >= 90"
           class="final-test-title">Вітаємо!</p>
        <p *ngIf="finalTest && test >= 90"
           class="final-test-subtitle">Ви успішно склали тест</p>
        <span *ngIf="(finalTest && test < 90) || !finalTest"> {{'FINISHED_TEST.YOUR_RESULT' | translate}}</span>
        <span *ngIf="(finalTest && test < 90) || !finalTest"
              class="bold">
          {{testInformation?.correctAnswers.length}}/{{testInformation?.allTestsLength}}
        </span>
      </div>
      <div class="star-container">
        <span *ngIf="number >= 1; else other_star1">
          <img alt="" class="icon" src="../../../../assets/icons/gold-star.svg">
        </span>
        <ng-template #other_star1>
          <img alt="" class="icon" src="../../../../assets/icons/grey-star.svg">
        </ng-template>
        <span *ngIf="number >= 20; else other_star2">
          <img alt="" class="icon" src="../../../../assets/icons/gold-star.svg">
        </span>
        <ng-template #other_star2>
          <img alt="" class="icon" src="../../../../assets/icons/grey-star.svg">
        </ng-template>
        <span *ngIf="number >= 50; else other_star3">
          <img alt="" class="icon" src="../../../../assets/icons/gold-star.svg">
        </span>
        <ng-template #other_star3>
          <img alt="" class="icon" src="../../../../assets/icons/grey-star.svg">
        </ng-template>
        <span *ngIf="number >= 75; else other_star4">
          <img alt="" class="icon" src="../../../../assets/icons/gold-star.svg">
        </span>
        <ng-template #other_star4>
          <img alt="" class="icon" src="../../../../assets/icons/grey-star.svg">
        </ng-template>
        <span *ngIf="number >= 90; else other_star5">
          <img alt="" class="icon" src="../../../../assets/icons/gold-star.svg">
        </span>
        <ng-template #other_star5>
          <img alt="" class="icon" src="../../../../assets/icons/grey-star.svg">
        </ng-template>
      </div>
      <div *ngIf="(!finalTest && test >= 90)"
           class="success-test">{{'FINISHED_TEST.SUCCESS_TEST' | translate}}</div>
      <p *ngIf="wasteTime" class="final-result">Час вичерпано. <br>Нажаль тест не складено!</p>
      <p *ngIf="treeError" class="final-result">Ви зробили три помилки. <br>Нажаль тест не складено!</p>
      <p *ngIf="!treeError && !wasteTime && ((!finalTest && test < 90) || ((finalTest && test < 90)))"
         class="failure-test">{{'FINISHED_TEST.FAILURE_TEST' | translate}}</p>
      <p *ngIf="!treeError && !wasteTime && (test < 90 || ((finalTest && test < 90) || !finalTest))"
         class="success-percentage">{{'FINISHED_TEST.SUCCESS_PERCENTAGE' | translate}}</p>
      <p *ngIf="finalTest && test >= 90 && !treeError && !wasteTime"
         class="final-result">
        Ви завершили навчання в автошколі RomDomDom. Найблищим часом на вашу електронну адресу будуть надіслані усі
        необхідні документи для складання іспиту в сервісному центрі МВС
      </p>

    </div>
  </div>
  <button (click)="reloadPage()"
          *ngIf="!finalTest || (finalTest && test < 90)"
          [ngClass]="{'next-lecture':nextLectureStatus() ,
                      'margin':nextLectureStatus()  }"
          class="repeat-test-button">{{'FINISHED_TEST.REPEAT_TEST_BUTTON' | translate}}</button>
  <button (click)="openNextLecture(nextLecture.id)"
          *ngIf="nextLectureStatus()"
          [ngClass]="{'next-lecture':nextLectureStatus() }"
          class="repeat-test-button next-lection">Наступна лекція
  </button>

  <p (click)="changeUrl()"
     *ngIf="!finalTest || (finalTest && test < 90) "
     class="footer-text">{{'FINISHED_TEST.GO_TO' | translate}} {{testKind}}</p>

</div>

<svg *ngIf="test >= 90">
  <linearGradient id="gradient1">
    <stop id="stop1" offset="0" stop-color="#52DDD5"/>
    <stop id="stop2" offset="100%" stop-color="#636DF9"/>
  </linearGradient>
  <linearGradient id="gradient2" x1="0" x2="0" xlink:href="#gradient1" y1="0"
                  y2="1"/>
</svg>

<svg *ngIf="test < 90">
  <linearGradient id="gradient1">
    <stop id="stop1" offset="0" stop-color="#F43232"/>
    <stop id="stop2" offset="100%" stop-color="#F9CF63"/>
  </linearGradient>
  <linearGradient id="gradient2" x1="0" x2="0" xlink:href="#gradient1" y1="0"
                  y2="1"/>
</svg>
