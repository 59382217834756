<div class="dialog-content container">
  <div class="content container-information">
    <p class="title">{{'DIALOGS.LEAVE_REGISTRATION.END' | translate}}</p>
    <p class="subtitle">{{'DIALOGS.LEAVE_REGISTRATION.RESULT' | translate}}</p>
    <div class="buttons">
      <button class="leave"  (click)="closeDialog(true)">{{'DIALOGS.LEAVE_TEST.LEAVE_BUTTON' | translate}}</button>
      <button class="continue" (click)="closeDialog(false)" >{{'DIALOGS.LEAVE_TEST.CONTINUE_BUTTON' | translate}}</button>
    </div>
  </div>
</div>
