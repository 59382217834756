<div *ngIf="instructor" [class.information-with-background]="background" class="information">
  <img *ngIf="drivingStatus && instructor.status === 1"
       class="driving-status"
       src="../../../assets/icons/alert-circle-yellow.svg"
  />
  <img *ngIf="drivingStatus && instructor.status === 5"
       class="driving-status"
       src="../../../assets/icons/ok-circle-green.svg"
  />
  <img *ngIf="drivingStatus && instructor.status > 1 && instructor.status < 5 "
       class="driving-status"
       src="../../../assets/icons/ok-circle-danger.svg"
  />
  <img *ngIf="(!instructor.imagePath&& !instructor.instructorImagePath); else personImage"
       class="user-image"
       src="../../../assets/icons/user-image.svg"
  />

  <ng-template #personImage>
    <div class="img-block">
      <div *ngIf="loadImg" class="spinner">
        <img src="../../../assets/icons/test-load-image-spinner.svg">
      </div>
      <img (load)="spinnerEvent($event)"
           (loadstart)="spinnerEvent($event)"
           [ngStyle]="{'opacity': loadImg ? 0 : 1}"
           [src]="prefixImgPath + (instructor.imagePath ? instructor.imagePath : instructor.instructorImagePath)"
           class="instructor-image user-image"
      >
    </div>
  </ng-template>
  <div class="text">
    <p
      class="name">{{instructor.firstName ? instructor.firstName : instructor.instructorFirstName}} {{instructor.lastName ? instructor.lastName : instructor.instructorLastName}}</p>
    <table>
      <tr *ngFor="let information of instructorInformation">
        <td class="data">{{information.sign | translate}}</td>
        <td class="info">{{information.value}}</td>
      </tr>

    </table>
  </div>
</div>
