import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestItem } from '../../../../../shared/modules/test-item';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrafficRulesService } from '../../../../training-materials/services/traffic-rules.service';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit, OnDestroy {

  testsById: TestItem[] = [];
  subscriptions: Subscription = new Subscription();

  constructor(private trafficRulesService: TrafficRulesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private matDialog: MatDialog,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.getTestById();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTestById(): void {
    this.spinner.show();
    this.subscriptions.add(this.trafficRulesService.geTicketsyId(this.activatedRoute.snapshot.params.id)
      .subscribe((test: TestItem[]) => {
        this.testsById = test;
      }, ()=>{

      }, ()=>{
        this.spinner.hide();
      }));
  }

  goToPreviousUrl(): void {
    this.router.navigate(['home/tests/test/ticket-tests']);
  }
}
