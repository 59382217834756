<div *ngIf="instructor" [class.center]="centerInformation" class="information padding">
  <img *ngIf="(!instructor.imagePath && !instructor.instructorImagePath); else personImage"
       [class.bigImage]="bigImage"
       src="../../../assets/icons/user-image.svg">

  <ng-template #personImage>
    <img [class.bigImage]="bigImage"
         [src]="prefixImgPath + (instructor.imagePath ? instructor.imagePath : instructor.instructorImagePath)"
         class="instructor-image">
  </ng-template>

  <div class="text">
    <p
      class="name padding-name">{{instructor.firstName ? instructor.firstName : instructor.instructorFirstName}} {{instructor.lastName ? instructor.lastName : instructor.instructorLastName}}</p>
    <table>
      <tr *ngFor="let info of instructorInformation">
        <td class="data">{{info.sign | translate }}</td>
        <td [class.no-splice]="!noSpliceInformation"
            class="info">{{info.value}}</td>
      </tr>
    </table>
  </div>
</div>
