<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div><p>{{activeCategory}}</p></div>
    </div>
    <hr>
    <div class="content">
      <div (click)="openSign('/home/tm/signs/sign/' + sign.id, activeCategory)"
           *ngFor="let sign of signs| async; let it=index"
           class="little-block">
        <div class="little-block-content">
          <div class="sign-image">
            <img alt="" src="{{prefixImgPath}}/signs/{{sign.number}}.png">
          </div>
          <div class="sign-number"><p>{{sign.number}}</p></div>
          <div [innerHTML]="sign.name | highlightsText: searchText" class="little-block-name"></div>
          <div class="sign-arrow"><img class="sign-arrow-image" src="../../assets/icons/sign-arrow.svg"></div>
        </div>
        <div class="big">
          <div class="disabled-block"></div>
          <div
            [ngClass]="{left:(it%3 === 0 || it === 0 && it !== 1),
                        left2:(((it - 1)%3 === 0 || (it - 1) === 0 && (it - 1) !== 1)),
                        left3:(((it + 1)%3 === 0 || (it + 1) === 0 && (it + 1) !== 1))}"
            class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{sign.number + '.'}}</span>
              <span class="title-hover-block-name">{{sign.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-sign-hover-block">
              </div>
              <div [innerHTML]="sign.description | highlightsText: searchText"
                   class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
        <div class="little">
          <div [ngClass]="{left:(it%2 === 0 || it === 0 && it !== 1),
                           left2:(((it + 1)%2 === 0 || (it + 2) === 0 && (it + 2) !== 1))}"
               class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{sign.number + '.'}}</span>
              <span class="title-hover-block-name">{{sign.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-sign-hover-block">
              </div>
              <div [innerHTML]="sign.description | highlightsText: searchText" class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="emptyBlock"></div>
    </div>
  </div>
  <div class="menu">
    <app-sign-menu [activeCategory]="activeCategory"
                   [categories]="categories"
    ></app-sign-menu>
  </div>
</div>
