<div *ngIf="licence" class="content container">
  <img (click)="closeDialog()" class="exit" src="../../../assets/icons/cancel.svg">
  <div class="content-container  container-information">
    <p class="title">{{'DIALOGS.SELECT_CATEGORY.TITLE' | translate}}</p>
    <mat-radio-group *ngIf="licence" [(ngModel)]="categoryIndex" aria-label="Select an option">
      <div class="radio-buttons">
        <mat-radio-button *ngFor="let licen of licence" [value]="licen.name" labelPosition="before">
          <img *ngIf="licen.name.match('A')" src="../../../../assets/icons/category-A.svg">
          <img *ngIf="licen.name.match('B')" src="../../../../assets/icons/category-B.svg">
          <img *ngIf="licen.name.match('C')" src="../../../../assets/icons/category-C.svg">
          <span>{{licen.name}}</span>
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <button (click)="saveChanges()" class="select"> {{'DIALOGS.SELECT_CATEGORY.BUTTON' | translate}}</button>
  </div>
</div>
