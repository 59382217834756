export class TripStatuses {
  public static value(): ({ color: string; value: string })[] {
    return [{
      value: 'Заняття заплановане',
      color: '#EAEAEA'
    },
      {
        value: 'Очікує підтвердження',
        color: '#FFBF00'
      },
      {
        value: 'Скасоване студентом',
        color: '#FE5858'
      },
      {
        value: 'Скасоване автошколою',
        color: '#FE5858'
      },
      {
        value: 'Скасоване інструктором',
        color: '#FE5858'
      },
      {
        value: 'Заняття зараховане',
        color: '#23C1B8'
      }
    ];
  };
}
