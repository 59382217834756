import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LicenseCategoriesService } from '../../../modules/training-materials/services/license-categories.service';
import { ILicenseCategory } from '../../interfaces/licenseCategory';
import { NgxSpinnerService } from 'ngx-spinner';
import {error} from "protractor";


@Component({
  selector: 'app-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.scss',
    '/src/app/shared/dialogs/dialogs.scss']
})
export class SelectCategoryComponent implements OnInit {
  categoryIndex: string;
  licence: ILicenseCategory[];

  constructor(public dialogRef: MatDialogRef<SelectCategoryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private licenseService: LicenseCategoriesService,
              private spinner: NgxSpinnerService) {
    this.categoryIndex = this.data.category.name;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.licenseService.getLicense().subscribe((res: ILicenseCategory[]) => {
      this.licence = res;
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    });
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

  saveChanges(): void {
    const index = this.licence.findIndex((el: ILicenseCategory) => el.name.includes(this.categoryIndex));
    this.dialogRef.close(this.licence[index]);
  }

}
