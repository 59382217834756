import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rejected-lesson-information',
  templateUrl: './rejected-lesson-information.component.html',
  styleUrls: ['./rejected-lesson-information.component.scss',
    '/src/app/shared/dialogs/dialogs.scss']
})
export class RejectedLessonInformationComponent implements OnInit {
  status: number;

  constructor(public dialogRef: MatDialogRef<RejectedLessonInformationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.status = 1;
    if (data) {
      this.status = data;
    }
  }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }
}
