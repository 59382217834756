import { Component, OnDestroy, OnInit } from '@angular/core';
import { PenaltyService } from '../../services/penalty.service';
import { IPenalties } from '../../../../shared/interfaces/penalties';
import { StorageService } from '../../../../core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-penalties',
  templateUrl: './penalties.component.html',
  styleUrls: ['./penalties.component.scss',
    '/src/app/style/penalties.scss']
})
export class PenaltiesComponent implements OnInit, OnDestroy {

  penalties: IPenalties[];
  openComment = [];
  subscriptions: Subscription = new Subscription();

  constructor(private penaltyService: PenaltyService,
              private storageService: StorageService,
              private translateService: TranslateService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.subscriptions.add(this.penaltyService.getPenalties().subscribe((res: IPenalties[]) => {
      const categoryId = this.activatedRoute.snapshot.params.id;
      this.penalties = res;
      setTimeout(() => {
        const el = document.getElementById(categoryId);
        el.scrollIntoView();
      }, 0);
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  open(el): void {
    if (this.openComment.includes(el)) {
      this.openComment = this.openComment.filter(element => element !== el);
    } else {
      this.openComment.push(el);
    }
  }

}
