import { Injectable } from '@angular/core';

export interface StorageOptions {
  expire?: number;
  tags?: Array<string>;
  inMemory?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  private memoryCache = {};
  private localStorageSupported;

  constructor() {
    this.localStorageSupported = this.checkLocalStorage();
  }

  set(key: string, value: any, options: StorageOptions = {}): any {
    const data = {
      value: value,
      tags: options.tags,
      expireAt: null,
      createdAt: new Date()
    };

    if (options.expire) {
      data.expireAt = new Date(Date.now() + options.expire);
    }

    if (options.inMemory || !this.localStorageSupported) {
      this.memoryCache[key] = data;
    } else {
      window.localStorage.setItem(key, JSON.stringify(data));
    }
  }

  get(key: string, inMemory = false): any {
    let data;

    if (inMemory || !this.localStorageSupported) {
      data = this.memoryCache[key];

      if (data === undefined) {
        return null;
      }
    } else {
      const dataString = window.localStorage.getItem(key);

      if (!dataString) {
        return null;
      }

      data = JSON.parse(dataString);
    }

    if (data.expireAt) {
      const currentDate = new Date();
      const expireDate = new Date(data.expireAt);

      if (currentDate > expireDate) {
        return null;
      }
    }

    return data;
  }

  remove(key: string): void {
    delete this.memoryCache[key];
    window.localStorage.removeItem(key);
  }

  private checkLocalStorage(): boolean {
    const key = 'ls-check';

    try {
      window.localStorage.setItem(key, key);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}
