import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../../core/services/storage.service';
import { SearchService } from '../../../../layout/services/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialComponents } from '../../../../../shared/interfaces/training-material-components';
import { TrainingMaterialCategories } from '../../../../../shared/interfaces/training-material-categories';
import { Config } from '../../../../../shared/property/constant';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-search-road-markings',
  templateUrl: './search-road-markings.component.html',
  styleUrls: ['./search-road-markings.component.scss',
    '/src/app/style/sign-marking-item.scss']
})
export class SearchRoadMarkingsComponent implements OnInit, OnDestroy {

  roadMarkings: TrainingMaterialComponents[];
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  searchText: string;
  prefixImgPath = Config.storageUrl();
  subscriptions: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private searchService: SearchService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.subscriptions.add(this.searchService.searchByRoadMarkingsId
    (this.activatedRoute.snapshot.params.id, this.activatedRoute.snapshot.params.searchText)
      .subscribe((res: TrainingMaterialComponents[]) => {
        this.roadMarkings = res;
      }, ()=>{

      }, ()=>{
        this.spinner.hide();
      }));
    this.activeCategory = this.storageService.get('change-category').value;
    this.searchText = this.activatedRoute.snapshot.params.searchText;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openMarking(link, categoryName): void {
    this.storageService.set('roadMarkingCategory', categoryName);
    this.router.navigate([link]);

  }
}

