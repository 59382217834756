import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-classes',
  templateUrl: './book-classes.component.html',
  styleUrls: ['./book-classes.component.scss']
})
export class BookClassesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
