import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestItem } from '../../../../shared/modules/test-item';
import { TestsOnThemesService } from '../../services/tests-on-themes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../../../../core/services/storage.service';
import { Router } from '@angular/router';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-twenty-random-tickets',
  templateUrl: './twenty-random-tickets.component.html',
  styleUrls: ['./twenty-random-tickets.component.scss']
})
export class TwentyRandomTicketsComponent implements OnInit, OnDestroy {

  questions: TestItem[] = [];
  subscriptions: Subscription = new Subscription();

  constructor(private testsOnThemes: TestsOnThemesService,
              private spinner: NgxSpinnerService,
              private storageService: StorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getRandomQuestions(this.storageService.get('license-category').value);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getRandomQuestions(licenseCategory): void {
    this.subscriptions.add(this.testsOnThemes.getRandomQuestions(licenseCategory.id).subscribe((resp: TestItem[]) => {
      this.questions = resp;
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  goToPreviousUrl(): void {
    this.router.navigate(['/home/tests/test/tests-on-themes']);
  }
}
