 <div class="theme">
   <div>
     <p>{{'TESTS.TESTS-ON-THEMES' | translate}}</p>
   </div>
 </div>
 <hr>
 <div class="content">
   <div class="test" >
     <div class="test-content">
       <a class="item"
            *ngFor="let category of categories | async"
            routerLink="/home/tests/test-on-theme/{{category.id}}">
         <div class="item-content">
           <div class="number"><p>{{category.number}}.</p></div>
           <div class="name"><p class="name">{{category.name}}</p>
             <p class="count-question">{{'TESTS.QUESTION' | translate}} <span>{{category.testsCount}}</span></p>
           </div>
           <div class="arrow"> <img src="../../../assets/icons/arrow-left.svg"></div>
         </div>
       </a>
     </div>
   </div>
 </div>
 <div class="menu-mobile" style="display: none">
   <div class="menu-container">
   </div>
 </div>
