import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DrivingSchoolRegistrationRoutingModule } from './driving-school-registration-routing.module';
import { MenuRegistrationComponent } from './menu-registration/menu-registration.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent } from './payment/payment.component';
import { DocumentsComponent } from './documents/documents.component';
import { NgxMaskModule } from 'ngx-mask';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { SharedModule } from '../../shared/shared.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';



@NgModule({
  declarations: [ MenuRegistrationComponent, PersonalInformationComponent, PaymentComponent, DocumentsComponent],
  exports: [
    MenuRegistrationComponent,
    PersonalInformationComponent,
    PaymentComponent,
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    DrivingSchoolRegistrationRoutingModule,
    MatTabsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    CalendarModule,
    FormsModule,
    SharedModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ]
})
export class DrivingSchoolRegistrationModule { }
