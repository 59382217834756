/**
 * Sync property with localStorage.
 * @param key
 */
export function propToLocalStorage(key: any = null) {
  return (target: any, propertyKey: string) => {
    const storageKey = key ? key : propertyKey;
    const { set } = Object.getOwnPropertyDescriptor(target, propertyKey) || {};

    const setValue = (value: any) => {
      window.localStorage.setItem(storageKey, JSON.stringify(value));
      if (set) {
        set(value);
      }
    }

    const getValue = () => {
      const value: any = window.localStorage.getItem(storageKey);

      return JSON.parse(value);
    }

    Object.defineProperty(target, propertyKey, {
      configurable: true,
      enumerable: true,
      get: getValue,
      set: setValue,
    });
  };
}
