import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FinalTestItem, TestItem } from '../../../shared/modules/test-item';


@Injectable({
  providedIn: 'root'
})
export class FinalTestService {
  private apiUrl = Config.apiUrl();
  constructor(private http: HttpClient) { }

  getFinal(): Observable<FinalTestItem> {
    return this.http.get<FinalTestItem>(`${this.apiUrl}trafficrulestests/final`);
  }
  postFinal(session): Observable<any> {
    return this.http.post(`${this.apiUrl}trafficrulestests/session`, session);
  }
  postAnswer(answer): Observable<any> {
    return this.http.post(`${this.apiUrl}trafficrulestests/answer`, answer);
  }
  checkFinalSuccess(): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}trafficrulestests/final/ispassed`);
  }
  postSession(data): Observable<any> {
    return this.http.put(`${this.apiUrl}trafficrulestests/session/${data}`, data);
  }

}
