import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthorizeService } from 'src/app/modules/authorize/authorize.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationPersonalInformationService } from 'src/app/modules/driving-school-registration/services/registration-personal-information.service';


@Component({
  selector: 'app-buy-course',
  templateUrl: './buy-course.component.html',
  styleUrls: ['./buy-course.component.scss']
})
export class BuyCourseComponent implements OnInit {

  registrationForm: FormGroup;
  error: boolean;
  name: string;
  errorName: boolean;
  emptyEmail: boolean;
  incorrectEmail: boolean;
  emailExist: boolean;
  // isAgreed: boolean = true;
  formControlName = new FormGroup({
    fullName: new FormControl('')
  });
  user = {
    fullName: '',
  };
  subscriptions: Subscription = new Subscription();

  public tariff: any;
  public paymentData = '';
  public paymentSignature = '';
  private clientData;

  constructor(private authService: AuthorizeService,
              private regPersonalInformationService: RegistrationPersonalInformationService,
              private fb: FormBuilder,
              private spinner: NgxSpinnerService) {
    this.error = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern('^[\\s]*[a-zA-Zа-щА-ЩЬьЮюЯяЇїІіЄєҐґ’_`\'-\\s]{3,20}')]],
      email: ['', [Validators.required, Validators.pattern('^[\\s]*(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[\\s]*(\\+38)?(\\d{3})\\-?(\\d{3})\\-?(\\d{2})\\-?(\\d{2})')]],
      // agreement: [false, [Validators.required]],
      tariff: ['', [Validators.required]]
    });

    this.clientData = {
      email: '', 
      tariffId: '',
      // Allow to receive payment data in base64 encode format [false: will return payment data in JSON format]
      isDataEncode: true,
      clientUrl: ''
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async makePayment(){
    // this.isAgreed = this.registrationForm.get('agreement').value;
      // if(this.isAgreed)
      // {
        if (this.registrationForm.valid) {
        await this.registration();
        this.spinner.show();
        this.clientData.email = this.registrationForm.get('email').value;
        this.clientData.tariffId = this.registrationForm.get('tariff').value;
        this.clientData.clientUrl = window.location.protocol + window.location.host + '/authorization/confirmed-email';
        this.getPaymentData(this.clientData);
        }
      // }
  }

  validate()
  {
    return !this.registrationForm.get('agreement').value;
  }

  async registration(): Promise<void> {
      this.spinner.show();
      const credential = this.registrationForm.getRawValue();
      credential.language = 0;
      try {
        await this.authService.fastRegistration(credential).toPromise();
        this.spinner.hide();
      } catch (err) {
        this.error = true;
        this.spinner.hide();
        this.errorName = !!err.error.errorCodes.includes(400022);
        this.emptyEmail = !!err.error.errorCodes.includes(400021);
        this.incorrectEmail = !!err.error.errorCodes.includes(400030);
        this.emailExist = !!err.error.errorCodes.includes(400319);
    }
  }

  deleteGap(key, value): void {
    const src = value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    switch (key) {
      case 0:
        this.registrationForm.patchValue({fullName: src});
        break;
      case 1:
        this.registrationForm.patchValue({email: src});
        break;
    }
  }

  enterRegistration(event): void {
    if (event.keyCode === 13){
      this.registration();
    }
  }

  getPaymentData(data): void {
    this.regPersonalInformationService.getPaymentDataSimplified(data).subscribe((res) => {
      this.paymentSignature = res?.signature;
      this.paymentData = res?.paymentData;
      this.spinner.hide();
      if(document !== undefined)
      {
        (document.getElementById('paymentData') as HTMLInputElement).value = this.paymentData;
        (document.getElementById('paymentSignature') as HTMLInputElement).value = this.paymentSignature;
        this.submit();
      }
    });
  }

  submit(): void {
    if(document !== undefined)
    {
      const form = document.getElementById('payForm') as HTMLFormElement;
      form.submit();
    }
  }

}
