import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeaveTestComponent } from '../../shared/dialogs/leave-test/leave-test.component';
import { map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TiketGuard implements CanDeactivate<unknown> {

  constructor(private matDialog: MatDialog,
              private stor: StorageService) {
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const dialogConfig = new MatDialogConfig();
    const data = {
      hint: 'ssss'
    };
    dialogConfig.data = data;
    if (!this.stor.get('finish-test').value) {
      const dialogRef = this.matDialog.open(LeaveTestComponent);
      return dialogRef.afterClosed().pipe(map(result => {
        if (result) {
          this.matDialog.closeAll();
        }
        return result;
      }));
    }
    else {
      return true;
    }
  }

}
