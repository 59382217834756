<div class="content-learning">
  <div (click)="openVideo()" class="video-block">
    <div class="video-block-content">
      <div class="hover-block"></div>
      <img class="video" src="../../../assets/images/video.svg">
      <img class="play-button" src="../../../assets/icons/video-play.svg">
    </div>
  </div>
  <div class="main-content">
    <div>
      <div *ngIf="statusData === 'trainingMaterial'">
        <div class="title">
          <p>
            Розпочніть навчання
          </p>
        </div>
        <p class="video-details">Щоб дізнатися як відбувається навчання в онлайн-автошколі перегляньте ролик та пройдіть
          першу безкоштовну лекцію</p>
      </div>
      <div *ngIf="statusData === 'practice'">
        <div class="title">
          <p>Практичні заняття</p>
        </div>
        <div *ngIf="statusData === 'practice'" class="video-details">
          <p>В цьому розділі ви можете обирати інструктора, учбовий автомобіль та зручні вам час і місце для практичних
            занять</p>
          <p class="details-practice">Для доступу до практичних занять потрібно зареєстуватись на навчальний курс та
            пройти необхідну кількість лекцій теорії</p>
        </div>
      </div>
      <div *ngIf="statusData === 'pr'">
        <div class="title">
          <p>Практичні заняття</p>
        </div>
        <div *ngIf="statusData === 'pr'" class="video-details">
          <p>В цьому розділі вам не доступні лекції</p>
          <p class="details-practice">Для продовження навчання, перейдіть на сторінку з практичними занатями</p>
        </div>
      </div>
      <button (click)="start()"><span *ngIf="statusData != 'pr'">Розпочати навчання</span> <span *ngIf="statusData === 'pr'">Практичні заняття</span></button>
    </div>
  </div>
</div>
