<div class="player">
  <div class="top-data">
    <div class="title"> RomDomDom - Як розпочати навчання</div>
    <img (click)="cancel()" class="cancel" src="../../../assets/icons/cancel.svg">
  </div>
  <div class="video-container" id="video-container">
    <div #playbackAnimation
         class="playback-animation"
         id="playback-animation">
    </div>
    <div (click)="togglePlay()"
         *ngIf="!videoPlay"
         class="video video-pause">
      <img class="video-play" src="../../../assets/icons/video-play.svg">
    </div>
    <video #video
           (click)="togglePlay(); animatePlayback()"
           (loadedmetadata)="initializeVideo()"
           (loadedmetadata)="initializeVideo()"
           (pause)="updatePlayButton()"
           (play)="updatePlayButton()"
           (timeupdate)="updateProgress()"
           class="video player-video"
           src="{{introdactionUrl}}">

    </video>
    <div #videoControls class="video-controls hidden" id="video-controls">
      <div class="bottom-controls">
        <img (click)="rewindBack()" class="rewind" src="../../../assets/icons/rewind.svg">
        <img #play (click)="togglePlay()" *ngIf="!videoPlay" class="play-pause"
             src="../../../assets/icons/custom-video-play.svg">
        <img #play (click)="togglePlay()" *ngIf="videoPlay" class="play-pause" src="../../../assets/icons/pause.svg">
        <img (click)="rewind()" class="rewind" src="../../../assets/icons/rewind-right.svg">
        <div class="time" style="position: absolute; top: 0; display: none">
          <time #timeElapsed id="time-elapsed" style="color: black"></time>
          <span></span>
          <time #duration id="duration" style="color: black"></time>
        </div>
      </div>
      <div class="video-progress">
        <progress #progress id="progress-bar" min="0" value="0"></progress>
        <input #seek
               (input)="skipAhead($event)"
               (mousemove)="updateSeekTooltip($event)"
               class="seek" id="seek" min="0" step="1" type="range" value="0">
        <div #seekTooltip class="seek-tooltip" id="seek-tooltip">00:00</div>
      </div>
    </div>
  </div>
</div>
<svg display="none">
  <defs>
    <symbol id="pause" viewBox="0 0 24 24">
      <path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>
    </symbol>

    <symbol id="play-icon" viewBox="0 0 24 24">
      <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
    </symbol>
  </defs>
</svg>
