<div class="block"  id ="date-1" >
  <p class="title">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.TITLE' | translate}}</p>
  <div class="navigation-block" [class.menuEditButtons]="editStatusDocuments">

    <button class="menu-button" [disabled]="(!(regStep > 1 && !checkDate() && !checkName() && !error )) || error  || (personalInfoForm.controls['phoneNumber'].invalid &&
                  personalInfoForm.controls['phoneNumber'].touched)"
                                (click)="step = 1">

      <p [class.trueData]="(personalInfoForm.controls.firstName.valid &&
                            personalInfoForm.controls.lastName.valid &&
                            personalInfoForm.controls.middleName.valid )  && step !== 1 "
         [class.active]="step === 1">
        <img  src="../../../assets/icons/ok-circle-green.svg"
              *ngIf="(personalInfoForm.controls.firstName.valid &&
                      personalInfoForm.controls.lastName.valid &&
                      personalInfoForm.controls.middleName.valid ) " >
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PERSONAL_MENU.FIRSTNAME' | translate}}
      </p>
    </button>

    <button  class="menu-button" [disabled]="!editStatusDocuments ? (!(regStep > 2 && !checkDate() && !checkName() && !error )) || error || (personalInfoForm.controls['phoneNumber'].invalid &&
                  personalInfoForm.controls['phoneNumber'].touched) :
                                              !(editStatusDocuments && howOld >= 18) || error || phoneError || errorPhone  || phoneAlreadyIs|| (personalInfoForm.controls['phoneNumber'].invalid &&
                  personalInfoForm.controls['phoneNumber'].touched)"
                                 (click)="step = 2">

      <p [class.trueData]="howOld >= 18 && step !== 2"
         [class.active]="step === 2">
      <img  src="../../../assets/icons/ok-circle-green.svg"
            *ngIf="( howOld >= 18)" >

        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PERSONAL_MENU.DATE' | translate}}
      </p>
    </button>

    <button *ngIf="!editStatusDocuments"
            class="menu-button"
            [disabled]="(!(regStep > 3 && !checkDate() && !checkName() && !error))"
            (click)="personalInfoForm.controls['phoneNumber'].valid ? step = 4 :  step = 3">

      <p [class.trueData]="personalInfoForm.controls.phoneNumber.valid && step<=2"
         [class.active]="step > 2">
        <img  src="../../../assets/icons/ok-circle-green.svg"
              *ngIf="personalInfoForm.controls.phoneNumber.valid" >
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PERSONAL_MENU.NUMBER' | translate}}
      </p>
    </button>
  </div>
  <form [formGroup]="personalInfoForm">
    <div class="person-data" *ngIf="step === 1">
          <div>
      <p class="subtitle">
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.SUBTITLE' | translate}}
      </p>
      <div class="form">
        <p class="g-error"
           *ngIf="(checkName() || error) && errorLanguage[0] && errorLanguage[1] && errorLanguage[2]">
          {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.ERROR' | translate}}
        </p>
        <p class="g-error"
           *ngIf=" !errorLanguage[0]|| !errorLanguage[1]|| !errorLanguage[2]">
          {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.LANGUAGE_ERROR' | translate}}
        </p>
        <div class="form-field">
          <input required placeholder="Прізвище*"
                 formControlName="firstName"
                 minlength="3"
                 [value]="personalInfoForm.value.firstName"
                 (focusout)="deleteGap(0, personalInfoForm.value.firstName)"
                 (input)="checkLanguage(0, personalInfoForm.value.firstName)"
                 [class.invalidData]="personalInfoForm.controls['firstName'].invalid &&
                                     (personalInfoForm.controls['firstName'].touched||error||
                                     !errorLanguage[0])"
          >
        </div>
        <div class="form-field">
          <input required placeholder="Ім'я*"
                 formControlName="lastName"
                 minlength="2"
                 [value]="personalInfoForm.value.lastName"
                 (focusout)="deleteGap(1, personalInfoForm.value.lastName)"
                 (input)="checkLanguage(1, personalInfoForm.value.lastName)"
                 [class.invalidData]="personalInfoForm.controls['lastName'].invalid &&
                                     (personalInfoForm.controls['lastName'].touched||error||
                                     !errorLanguage[1])"
          >
        </div>
        <div class="form-field">
          <input required placeholder="По батькові*"
                 formControlName="middleName"
                 minlength="2"
                 [value]="personalInfoForm.value.middleName"
                 (focusout)="deleteGap(2, personalInfoForm.value.middleName)"
                 (input)="checkLanguage(2,personalInfoForm.value.middleName)"
                 [class.invalidData]="personalInfoForm.controls['middleName'].invalid &&
                                     (personalInfoForm.controls['middleName'].touched||error||
                                     !errorLanguage[2])"
          >
        </div>
      </div>
    </div>
    <div>
      <button class="next"
              (click)="checkForm('personal-information')"
      >
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.NEXT_BUTTON' | translate}}
      </button>
    </div>
  </div>
  <div class="person-data" *ngIf="step === 2">
    <div>
      <p class="subtitle">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.DAY_DATA' | translate}}</p>
      <div class="form birthday-form" >
        <p class="g-error error-message"
           *ngIf="personalInfoForm.controls['dateOfBirth'].invalid &&
                  (personalInfoForm.controls['dateOfBirth'].touched )">
          {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.ERROR' | translate}}
        </p>
        <p class="g-error error-message"
           *ngIf="checkDate()||(error && howOld < 18)">
          {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.DAY_DATA_ERROR' | translate}}
        </p>
        <div class="form-field date-1">
          <mat-form-field mat-raised-button
                          (click)="picker.open()"
                          class="example-full-width input find-input">

              <input aria-invalid="false"
                     class="date-input black-color"
                     matInput disabled
                     [matDatepicker]="picker"
                     [max]="maxDate"
                     [min]="minDate"
                     placeholder="ДД.ММ.РРРР"
                     formControlName="dateOfBirth"
                     [class.invalidData]="checkDate()">

              <mat-datepicker disabled="false" #picker>
              </mat-datepicker>
            <img class="date" mat-raised-button (click)="picker.open()"
                 src="../../../assets/icons/calendar.svg">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <button class="next" (click)="editStatusDocuments? editData():checkForm()">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.NEXT_BUTTON' | translate}}</button>
    </div>
  </div>
  <div class="person-data" *ngIf="step === 3">
    <div>
      <p class="subtitle">
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PHONE_VERIFICATION' | translate}}
        </p>
      <div class="form">
        <div
           *ngIf="(personalInfoForm.controls['phoneNumber'].invalid &&
                  personalInfoForm.controls['phoneNumber'].touched)||error">
        <p class="g-error error-message" *ngIf="phoneError;else otherPhoneError">
          {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PHONE_ERROR' | translate}}
        </p>
          <ng-template #otherPhoneError>
            <p class="g-error error-message" *ngIf="phoneAlreadyIs; else noData">
              {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.PHONE_EXIST_ERROR' | translate}}
            </p>
            <ng-template #noData>
              <p class="g-error error-message">
                {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.ERROR' | translate}}
              </p>
            </ng-template>
          </ng-template>
        </div>
        <div class="form-field">
          <input type="tel"
                 class="tel"
                 placeholder="000) 000 0000"
                 [specialCharacters]="['(',')', '[' ,']' , '\\', '+', ' ' ]"
                 mask="000) 000 0000"
                 formControlName="phoneNumber"
                 prefix="+38("
                 [showMaskTyped]="true"
                 [class.invalidData]="(personalInfoForm.controls['phoneNumber'].invalid &&
                                     personalInfoForm.controls['phoneNumber'].touched)||error">
        </div>
      </div>
    </div>

    <div>
      <button class="next"
              [disabled]="phoneCheck>1"
              (click)="checkForm('checkNumber')">
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.BUTTON' | translate}}
      </button>
    </div>
  </div>
  <div class="person-data" *ngIf="step === 4||step === 5">
    <div class="form">
      <p class="subtitle subtitle-phone">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.VERIFICATION_CODE' | translate}}</p>
      <p class="phone-number">+38{{this.personalInfoForm.getRawValue().phoneNumber|mask:"0000000000"}}</p>
      <p class="g-error error-message top"
         *ngIf=" verCodeError ">
        {{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.ERROR_CODE' | translate}}
      </p>
      <div class="code-block">
        <div class="form" formGroupName="verificationCode">

          <input id="codeBox1"
                 type="number"
                 formControlName="phoneCodesOne"
                 (input)="lengthCode(1, $event)"
                 (keydown)="onKeyDown(1, $event)"
                 (keyup)="onKeyUpEvent(1, $event)"
                 (focus)="onFocusEvent(1)" required
                 [ngStyle]="{'border-color':getCodeBoxElement(1).value.length > 0?'#23C1B8':''}"
          />
          <input id="codeBox2"
                 type="number"
                 formControlName="phoneCodesTwo"
                 (input)="lengthCode(2, $event)"
                 (keydown)="onKeyDown(2, $event)"
                 (keyup)="onKeyUpEvent(2, $event)"
                 (focus)="onFocusEvent(2)" required
                 [ngStyle]="{'border-color':getCodeBoxElement(2).value.length > 0?'#23C1B8':''}"
          />
          <input id="codeBox3"
                 type="number"
                 formControlName="phoneCodesThree"
                 (input)="lengthCode(3, $event)"
                 (keydown)="onKeyDown(3, $event)"
                 (keyup)="onKeyUpEvent(3, $event)"
                 (focus)="onFocusEvent(3)" required
                 [ngStyle]="{'border-color':getCodeBoxElement(3).value.length > 0?'#23C1B8':''}"
          />
          <input id="codeBox4"
                 type="number"
                 formControlName="phoneCodesFour"
                 (input)="lengthCode(4, $event)"
                 (keydown)="onKeyDown(4, $event)"
                 (keyup)="onKeyUpEvent(4, $event)"
                 (focus)="onFocusEvent(4)" required
                 [ngStyle]="{'border-color':getCodeBoxElement(4).value.length > 0?'#23C1B8':''}"
          />

        </div>
      </div>
      <div class="block-code" *ngIf="!phoneError">
        <div *ngIf="timerTimeSeconds > 0 || timerTimeMinutes === 1">
        <span class="enable-code">
           <img src="../../../assets/icons/time.svg">
          <span *ngIf="timerTimeMinutes<1">0</span>{{timerTimeMinutes}} : <span *ngIf="timerTimeSeconds<10">0</span>{{timerTimeSeconds}} </span>
        </div>
      </div>
      <div *ngIf="!phoneError && codeCount<4;else noCode">
        <div *ngIf="codeActive; else noActiveCode">
          <p class="retry" (click)="setTimer('retry')">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.RETRY_VERIFICATION_CODE' | translate}}</p>
        </div>
        <ng-template #noActiveCode>
          <p class="retry enable-code">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.RETRY_VERIFICATION_CODE' | translate}}</p>
        </ng-template>
      </div>
      <ng-template #noCode>
        <p class="enable-code" *ngIf="(timerTimeSeconds === 0 && timerTimeMinutes === 0) || phoneError || codeCount > 3">{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.RETRY_VERIFICATION_CODE_ERROR' | translate}}</p>
      </ng-template>
    </div>
    <div class="flex">
      <img src="../../../assets/icons/back.svg" (click)="clearInterval()">
      <button class="next"
              [disabled]="!personalInfoForm.valid"
              (click)="goToPayment()"
      >{{'DRIVING_SCHOOL_REGISTRATION.PERSONAL_INFORMATION.NEXT_BUTTON' | translate}}</button>
    </div>
  </div>
  </form>
</div>
