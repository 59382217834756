import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HintsComponent } from '../../dialogs/hints/hints.component';
import { TestItem } from '../../modules/test-item';
import { TestsOnThemesService } from '../../../modules/tests/services/tests-on-themes.service';
import { Config } from '../../property/constant';
import { StorageService } from '../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinalTestService } from '../../../modules/course/services/final-test.service';
import { TestImageViewComponent } from '~/src/app/shared/dialogs/test-image-view/test-image-view.component';
import { EventEmitter } from '~/node_modules/@angular/core';

@Component({
  selector: 'app-test-item',
  templateUrl: './test-item.component.html',
  styleUrls: ['./test-item.component.scss'],

})
export class TestItemComponent implements OnInit, OnChanges {

  @Input() testItem: TestItem;
  @Input() isStartTest = false;
  @Input() finalTestId: string;
  @Input() finalTest;
  @Input() testInformation;
  @Input() sessionId: string;
  @Input() isImg: boolean;
  @Output() disabledButton = new EventEmitter<any>();
  storagePath = Config.storageUrl();
  step = 0;
  scrollElement = false;
  isOpen = true;
  testHint: number;
  testSelect: number;
  falseAnswer = 0;
  testId: string;
  isItemComplete = false;
  loadImg: boolean;
  img: string;
  isFirefox: boolean;

  constructor(private matDialog: MatDialog,
              private testsOnThemesService: TestsOnThemesService,
              private storageService: StorageService,
              private spinner: NgxSpinnerService,
              private finalTestService: FinalTestService) {
    this.testHint = this.storageService.get('test-hint')?.value;
    this.testSelect = this.storageService.get('test-select')?.value;

    this.isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
  }

  ngOnInit(): void {
    this.storageService.set('false-answer-count', 0);
  }

  ngOnChanges(changes): void {
    if (changes && changes.testItem && changes.testItem.currentValue) {
      this.isImg = true;
      this.isItemComplete = true;
      this.loadImg = true;
      this.testItem.isTestAvailable = true;
    }
  }

  setTestHint(): void {
    this.storageService.set('test-hint', 1);
  }

  setTestSelect(): void {
    this.storageService.set('test-select', 1);
  }

  scroll(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'smooth'});
    this.scrollElement = true;
  }

  openHint(hint): void {
    const dialogConfig = new MatDialogConfig();
    const data = {
      hint
    };
    dialogConfig.data = data;
    const dialogRef = this.matDialog.open(HintsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openImage(imageUrl): void {
    const dialogConfig = new MatDialogConfig();
    const data = {
      imageUrl
    };
    dialogConfig.data = data;
    const dialogRef = this.matDialog.open(TestImageViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  checkAnswer(resId, questionId): void {
    if (questionId !== resId) {
      this.testItem.inCorrectAnswerId = questionId;
      this.testItem.isCorrectAnswer = false;
      this.testItem.testPassed = true;
      this.falseAnswer++;
      this.storageService.set('false-answer-count', this.falseAnswer);
    } else {
      this.testItem.isCorrectAnswer = true;
      this.testItem.testPassed = true;
    }
  }

  checkCorrectAnswer(testId, questionId): void {
    this.spinner.show();
    const answer: any = {};
    answer.sessionId = this.sessionId;
    answer.testId = testId;
    answer.answerId = questionId;
    this.disabledButton.emit(true);
    if (this.finalTest) {
      this.finalTestService.postAnswer(answer).subscribe((resId: string) => {
        this.testItem.correctAnswerId = resId;
        this.checkAnswer(resId, questionId);
      }, () => {
      }, () => {
        this.disabledButton.emit(false);
      });
    } else {
      this.testsOnThemesService.getCorrectAnswer(testId).subscribe((resId: string) => {
        this.testItem.correctAnswerId = resId;
        this.checkAnswer(resId, questionId);
      }, () => {
      }, () => {
        this.disabledButton.emit(false);
      });
    }
    this.spinner.hide();
  }

  enterKeyAnswer(event, index, testId, questionId): void {
    if ((+event.key === index + 1) && !this.testItem?.testPassed){
      this.checkCorrectAnswer(testId, this.testItem.questionAnswers[index].id);
    }
  }

  addDeleteFavouriteQuestion(question): void {
    this.spinner.show();
    if (question.isFavorite) {
      this.testsOnThemesService.deleteQuestionFromFavourite(question).subscribe((res: TestItem[]) => {
        this.testItem.isFavorite = false;
        this.spinner.hide();
      });
    } else {
      this.testsOnThemesService.addQuestionToFavourite(question).subscribe((res: TestItem[]) => {
        this.testItem.isFavorite = true;
        this.spinner.hide();
      });
    }
  }

  spinnerEvent(event): void {
    setTimeout(() => {
      this.loadImg = !this.loadImg;
    }, 50);

  }
}
