<div *ngIf="finalTest && step == 0"
     class="cdk-overlay-backdrop
            cdk-overlay-dark-backdrop
            cdk-overlay-backdrop-showing
            opacity"></div>
<div *ngIf="finalTest && step == 0">
  <div class="dialog-content">
    <img (click)="close()" class="cancel" src="../../../assets/icons/cancel.svg">
    <div class="content">
      <div class="timer">
        <img src="../../../assets/icons/clock.svg">
        <span>20:00</span>
      </div>
      <div class="title">
        <p>Підсумковий тест</p>
      </div>
      <div class="subtitle ">
        <img src="../../../assets/icons/ok-circle.svg">
        <p>Для доступу до державного іспиту в сервісному центрі МВС вам необхідно пройти підсумковий тест у додатку</p>
      </div>
      <div class="subtitle ">
        <img src="../../../assets/icons/play-circle.svg">
        <p>20 хвилин та не більше двох помилок!</p>
      </div>
      <div class="subtitle ">
        <img src="../../../assets/icons/restart-circle.svg">
        <p class="restart">У випадку не успішного результату у вас є можливість повторно пройти тестування</p>
      </div>
      <button (click)="startFinishTest()" class="continue">Розпочати</button>
    </div>
  </div>
</div>

<div *ngIf="testItems && !isTestFinished"
     class="general-container"
     id="test-container">
  <div class="container-content">
    <div>
      <div *ngIf="finalTest" class="timer">
        <img src="../../../assets/icons/clock.svg">
        <span><span *ngIf="timerTimeMinutes<10">0</span>{{timerTimeMinutes}} : <span
          *ngIf="timerTimeSeconds<10">0</span>{{timerTimeSeconds}} </span>
      </div>
      <div class="numbers-container">
        <div class="number-of-question-container">
          <div *ngFor="let test of testItems; let i = index" class="number-of-question-content">
            <div #scroll #start *ngIf="selectedTestIndex === i" class="block"></div>
            <div (click)="changeTestIndex(i)"
                 [ngClass]="{'correct-answer': test.isCorrectAnswer && test.testPassed,
                          'incorrect-answer': !test.isCorrectAnswer && test.testPassed,
                          'border': selectedTestIndex === i}"
                 class="number">
              <p>{{i + 1}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="test-container">
        <div class="number-of-question-title">
          <p> {{'TESTS.QUESTION-NUMBER' | translate}} {{selectedTestIndex + 1}}</p>
        </div>
        <div>
          <app-test-item [finalTestId]="finalTestId"
                         [finalTest]="finalTest"
                         [isImg]="false"
                         [isStartTest]="true"
                         [sessionId]="sessionId"
                         [testInformation]="testInfo"
                         [testItem]="testItems[selectedTestIndex]"
                         (disabledButton)="disableButton($event)"
          >
          </app-test-item>
        </div>
      </div>
    </div>
    <div class="test-buttons">
      <button [disabled]="disableBut" (click)="previousQuestion()" *ngIf="selectedTestIndex !=0" class="previous-question">
        <img src="../../../assets/icons/previous-question.svg">
      </button>
      <button [disabled]="disableBut" (click)="nextQuestion()" class="next-question">
        <span *ngIf="selectedTestIndex != testItems.length-1">{{'TESTS.BUTTON' | translate}}</span>
        <span *ngIf="selectedTestIndex === testItems.length-1">{{'TESTS.COMPLETE' | translate}}</span>
      </button>
    </div>
  </div>
</div>

<app-finished-test *ngIf="(testItems && isTestFinished)||finalTestError"
                   [TestFromLecture]="TestFromLecture"
                   [finalTestId]="finalTestId"
                   [finalTest]="finalTest"
                   [link]="link"
                   [nextLecture]="nextLecture"
                   [sessionId]="sessionId"
                   [testInformation]="testInfo"
                   [testKind]="testKind"
                   [testStatus]="testStatus">
</app-finished-test>
