import { AfterViewInit, Component, isDevMode, OnInit } from '@angular/core';
import { StorageService } from '../../../core/services/storage.service';
import { RegistrationPersonalInformationService } from '../services/registration-personal-information.service';
import { NgxSpinnerService } from '~/node_modules/ngx-spinner';
import { environment } from '~/src/environments/environment.prod';
import { Router } from '@angular/router';
import { Tariff } from '../../../shared/interfaces/licenseCategory';
import { LicenseCategoriesService } from '../../training-materials/services/license-categories.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss',
    '/src/app/modules/driving-school-registration/registration-content.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit {
  categoryId: string;
  orderId: string;
  isPurchased: boolean;
  isPartial = false;
  descriptions = [];

    // todo: move descriptions to backend side.
    partialDescriptions = [
      {tariffType:0, descriptions:["Доступ до 1-15 навчальних лекцій.", "Доступ до 15-30 навчальних лекцій та 2 практичних уроків з водієм. Можливість скласти державний іспит не раніше ніж через 8 тижнів після внесення другого платежу", "Доступ до 30 лекцій та ще 8 практичних занять з водієм, а також фінальний іспит."]},
      {tariffType:1, descriptions:["Доступ до 1-15 навчальних лекцій.", "Доступ до 15-30 навчальних лекцій."]},
      {tariffType:2, descriptions:["Доступ до 5 практичних уроків з водієм.","Доступ ще до 5 практичних занять з водієм."]}
  ]

  public tariff: Tariff;
  public user;
  public paymentData = '';
  public paymentSignature = '';
  private clientData;

  constructor(private storageService: StorageService,
              private regPersonalInformationService: RegistrationPersonalInformationService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private paymentService: LicenseCategoriesService) {
  }
  ngOnInit(): void {
    const storageValue = this.storageService.get('registCategory').value;
    this.isPartial = storageValue.IsPartialPayment;

    if (this.isPartial){
      this.getTariffForPartialPayment(storageValue.tariffId);
    }

    this.clientData = {
      clientType: 2, // web client
      // Set Tariff plan id from localStorage
      tariffId: storageValue.tariffId,
      isPartialPayment: storageValue.IsPartialPayment,
      // Allow to receive payment data in base64 encode format [false: will return payment data in JSON format]
      isDataEncode: true
    };

    if (this.clientData.tariffId === '' || this.clientData.tariffId == null)
    {
      // @ts-ignore
    }


    this.spinner.show();

    // Check payment status
    this.checkPayment(this.clientData);
  }

  getTariffForPartialPayment(tariffId): void{
    this.paymentService.getLicense().subscribe(data => {
      console.log(data);
      this.tariff = data.find(category => category.name === 'B').
      tariffs.find(tariff => tariff.id === tariffId);
      this.descriptions = this.partialDescriptions.find(t =>t.tariffType === this.tariff.type).descriptions;
    }, error => {
      return null;
    }, () => {

    });
    return null;
  }

  getPaymentData(data, isForm = false): void {
    this.regPersonalInformationService.getPaymentData(data).subscribe((res) => {
      this.paymentSignature = res?.signature;
      this.paymentData = res?.paymentData;
      this.spinner.hide();
      if (isForm)
      {
        (document.getElementById('paymentData') as HTMLInputElement).value = this.paymentData;
        (document.getElementById('paymentSignature') as HTMLInputElement).value = this.paymentSignature;
        this.submit();
      }
    });
  }
  checkPayment(clientData, isForm = false): void {
    this.regPersonalInformationService.checkPayment().subscribe((res) => {
      if (res === true){
        this.next();
      }
      this.getPaymentData(clientData, isForm);
    }, ((error) => {
      this.getPaymentData(clientData, isForm);
    }));
  }

  preSubmit(): void {
    const radioButton = document.getElementById('radio-2') as HTMLInputElement;
    if (radioButton) {
      if (radioButton.checked) {
        this.clientData.isPartialPayment = false;
        this.checkPayment(this.clientData, true);
      } else {
        this.submit();
      }
    }
    else {
      this.submit();
    }
  }

  submit(): void {
    const form = document.getElementById('payForm') as HTMLFormElement;
    form.submit();
  }

  ngAfterViewInit(): void {
  }

  next(): void {
    this.storageService.set('registrationStep', 3);
  }

  ChangeColor(rad1: HTMLInputElement, block1: HTMLDivElement, block2: HTMLDivElement): void {
    if (rad1.checked)
    {
      block1.style.backgroundColor = '#23C1B8';
      block1.style.color = 'white';
      block2.style.backgroundColor = 'white';
      block2.style.color = 'black';
    }
    else {
      block1.style.backgroundColor = 'white';
      block1.style.color = 'black';
    }
  }
}
