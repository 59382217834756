<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div>
        <p>{{'FINES_TABLE.TITLE' | translate}}</p>
      </div>
    </div>
    <hr>
    <div class="content">
      <div *ngFor="let penalty of penalties|async ; let it=index" class="fines-table">
        <div class="fines-table-content">
          <div class="fines-table-number">
            <p>{{'FINES_TABLE.ARTICLE' | translate}} {{penalty.article}}</p>
          </div>
          <div [innerHTML]="penalty.description | highlightsText: searchText"
               class="fines-table-name">
          </div>
          <div class="amount">
            <p>{{'FINES_TABLE.AMOUNT' | translate}} {{penalty.amount}} {{'FINES_TABLE.CURRENCY' | translate}}</p>
          </div>
          <div (click)="open(it)"
               *ngIf="penalty.comment"
               class="comment"><p>{{'TRAINING_MATERIAL.EXPERT_COMMENT' | translate}}</p></div>
          <div *ngIf="this.openComment.includes(it)"
               [innerHTML]="penalty?.comment | highlightsText: searchText"
               class="comment-place">
          </div>
        </div>
      </div>
      <div class="empty-block"></div>
    </div>
  </div>
  <app-penalties-menu></app-penalties-menu>
</div>
