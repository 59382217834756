import { Component, Input, OnInit } from '@angular/core';
import { Instructor } from '../../interfaces/instructor';
import { Config } from '../../property/constant';
import { TransmissionType } from '../../../../_shared/constants/transmissionType';

@Component({
  selector: 'app-short-instructor-information',
  templateUrl: './short-instructor-information.component.html',
  styleUrls: ['./short-instructor-information.component.scss',
    '/src/app/modules/practice/practice.scss']
})
export class ShortInstructorInformationComponent implements OnInit {
  @Input() background: boolean;
  @Input() instructor: Instructor;
  @Input() drivingStatus: boolean;
  prefixImgPath = Config.storageUrl();
  transmissionType: Array<string>;
  loadImg = true;
  instructorInformation: Array<object>;

  constructor() {
    this.transmissionType = TransmissionType.value();
  }

  ngOnInit(): void {
    this.instructorInformation = [{
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.CAR',
      value: this.instructor.vehicle ? this.instructor.vehicle.name : this.instructor.vehicleName
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.VEHICLE',
      value: this.transmissionType[this.instructor.vehicle ?
        this.instructor.vehicle.transmissionType : this.instructor.vehicleTransmissionType]
    }, {
      sign: 'PRACTICE.INSTRUCTOR_INFORMATION.YEAR',
      value: this.instructor.vehicle ? this.instructor.vehicle.manufactureYear : this.instructor.vehicleManufacturerYear
    }];
  }

  spinnerEvent(event): void {
    setTimeout(() => {
      this.loadImg = !this.loadImg;
    }, 10);

  }
}
