import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

import { API_PREFIX, Config } from '../../shared/property/constant';
import { propToLocalStorage } from '../../shared/decorators/storage.decorator';
import { IUser } from '../../shared/interfaces/user';
import { Credential } from '../../shared/classes/credential';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  /**
   * Access token.
   */
  @propToLocalStorage('user')
  public user: any;

  @propToLocalStorage('token')
  public token: any;

  /**
   * Token API endpoint.
   */
  private url = Config.apiUrl();

  /**
   * Current User Subject.
   */
  private currentUserSubject: BehaviorSubject<IUser>;

  /**
   * Current user stream.
   */
  public currentUser: Observable<IUser>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(localStorage.getItem('currentIUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentIUserValue(): IUser {
    return this.currentUserSubject.value;
  }

  /**
   * Login, and store user.
   *
   * @param credential
   */
  login(credential: Credential): Observable<any> {
    return this.http.post<any>(`${this.url}account/login`, credential)
      .pipe(map(token => {
        this.token = token;
        this.user = jwt_decode(token.token);
        this.currentUserSubject.next(this.user);
        return this.user;
      }));
  }


  /**
   * Login, and store user by fb.
   *
   * @param authToken
   */
  loginByFB(authToken: string): Observable<any> {
    return this.http.post<any>(`${this.url}account/login/external/facebook`, {
      accessToken: authToken,
      accountLanguage: 0
    }).pipe(map(token => {
        this.token = token;
        this.user = jwt_decode(token.token);
        this.currentUserSubject.next(this.user);
        return this.user;
      }));
  }


  /**
   * Login, and store user by Google.
   *
   * @param authCode
   */
  loginByGoogle(accessToken: string): Observable<any> {
    return this.http.post<any>(`${this.url}account/login/external/google`, {
      accessToken,
      accountLanguage: 0
    }).pipe(map(token => {
        this.token = token;
        this.user = jwt_decode(token.token);
        this.currentUserSubject.next(this.user);
        return this.user;
      }));
  }

  /**
   * Registration.
   *
   * @param credential
   */
  registration(credential: Credential): Observable<any> {
    return this.http.post<any>(`${this.url}account`, credential);
  }

  /**
   * Fast Registration.
   *
   * @param credential
   */
    fastRegistration(credential: Credential): Observable<any> {
      return this.http.post<any>(`${this.url}account/fast-sign-up`, credential);
    }

  /**
   * Remove Password.
   *
   * @param email
   */
  removePassword(email: string): Observable<any> {
    return this.http.delete<any>(`${this.url}accountsettings/password?email=` + email);
  }

  /**
   * Remove Password.
   *
   * @param email
   */
  confirmEmail(email: string, token: string): Observable<any>{
    return this.http.post<any>(`${this.url}accountsettings/email/confirmation`, {
      email,
      confirmationToken: token
    });
  }

  /**
   * Remove Password.
   *
   * @param email
   */
  resetPassword(resetPassword): Observable<any> {
    return this.http.post<any>(`${this.url}accountsettings/password`, resetPassword);
  }

  /**
   * Clear user info.
   */
  logout(): void {
    this.user = null;
    this.token = null;
    this.currentUserSubject.next(null);
  }
}
