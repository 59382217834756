import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersonalInformation } from '../../../shared/interfaces/personalInformation';

@Injectable({
  providedIn: 'root'
})
export class RegistrationPersonalInformationService {
  private url = Config.apiUrl();
  private apiV2Url = Config.apiV2Url();

  constructor(private http: HttpClient) {
  }

  addPersonalInformation(data): Observable<any> {
    return this.http.post(`${this.url}studentpersonaldetails`, data);
  }

  addDocument(data): Observable<any> {
    return this.http.post(`${this.url}studentpersonaldetails/documents`, data);
  }
  editDocuments(data): Observable<any> {
    return this.http.put(`${this.url}studentpersonaldetails/rejected/documents`, data);
  }
  updateDocuments(data): Observable<any> {
    return this.http.put(`${this.url}studentpersonaldetails/documents`, data);
  }
  phoneConfirmation(data): Observable<any> {
    return this.http.post(`${this.url}accountsettings/phone/verification/code`, data);
  }
  getRegistrationInformation(): Observable<PersonalInformation> {
    return this.http.get<PersonalInformation>(`${this.url}studentpersonaldetails`);
  }
  deleteDock(path): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json,application/x-www-form-urlencoded,multipart/form-data,text/plain',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*',
        'Accept-Language': 'en-AU,en-US;q=0.9,en;q=0.8,ru;q=0.7,uk;q=0.6',
        'Accept-Encoding': 'gzip, deflate, br',
        'Sec-Fetch-Site': 'cross-site'
      }),
      body: path,
    };
    return this.http.delete(`${this.url}studentpersonaldetails/documents`, options);
  }
  editData(data): Observable<any>{
    return this.http.put(`${this.url}studentpersonaldetails/rejected`, data);
  }
  getLiqPayPrice(): Observable<any>{
    return this.http.get(`${this.url}licensecategories`);
  }
  checkPayment(): Observable<any> {
    return this.http.get(`${this.apiV2Url}payments/checkpayments`);
  }
  getPaymentDataSimplified(data): Observable<any>{
    return this.http.post(`${this.apiV2Url}payments/getPaymentDataSimplified`, data);
  }
  getPaymentData(data): Observable<any>{
    return this.http.post(`${this.apiV2Url}payments/getpaymentdata`, data);
  }
  makePayment(data): Observable<any>{
    return this.http.post(`${this.apiV2Url}payments/getpaymentdata`, data);
  }
  getIntrodactionVideo(): Observable<any>{
    return this.http.get(`${this.url}lookup/static-media`);
  }
  createSignature(data): Observable<any>{
    return this.http.get(`${this.url}lookup/liqPay/signature?data=${data}`);
  }
}
