import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { loadCldr } from '@syncfusion/ej2-base';
import { RegistrationPersonalInformationService } from '../services/registration-personal-information.service';
import { DateAdapter } from '@angular/material/core';
import { SuccessRegistrationComponent } from '../../../shared/dialogs/success-registration/success-registration.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Subscription } from '~/node_modules/rxjs';
import { environment } from '../../../../environments/environment';

declare var require: any;

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/de/ca-gregorian.json'),
  require('cldr-data/main/de/numbers.json'),
  require('cldr-data/main/de/timeZoneNames.json')
);

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss',
    '/src/app/modules/driving-school-registration/registration-content.scss']
})
export class PersonalInformationComponent implements OnInit, OnDestroy {
  @Input() editStatusDocuments: boolean;
  step = 1;
  phoneNumber: string;
  personalInfoForm: FormGroup;
  verificationCode: FormGroup;
  timerTimeMinutes: number;
  timerTimeSeconds: number;
  error: boolean;
  howOld: number;
  codeCount = 0;
  codeActive: boolean;
  maxDate: Date;
  verCodeError: boolean;
  phoneStep: boolean;
  regStep: number;
  errorLanguage: Array<boolean>;
  timer: any;
  phoneError: boolean;
  errorPhone: boolean;
  phoneAlreadyIs: boolean;
  todayDate = new Date();
  phoneCheck: number;
  minDate = new Date(this.todayDate.getFullYear() - 100, this.todayDate.getMonth(), this.todayDate.getDate());
  pattern = /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ0-9.!@?#"$%&:;() *+,/;-=^_{|}<> ,.\'-]/;
  subscriptions: Subscription = new Subscription();

  constructor(private fb: FormBuilder,
              private router: Router,
              private storageService: StorageService,
              private spinner: NgxSpinnerService,
              private dateAdapter: DateAdapter<any>,
              private regPersonalInformationService: RegistrationPersonalInformationService,
              private activeRouter: ActivatedRoute,
              private matDialog: MatDialog) {
    this.dateAdapter.setLocale('uk');
    this.maxDate = new Date();
    this.errorLanguage = [true, true, true];

  }

  async ngOnInit(): Promise<void> {
    if (this.storageService.get('codeCount')) {
      this.codeCount = this.storageService.get('codeCount').value;

    } else {
      this.storageService.set('codeCount', 0);
    }
    this.regStep = this.storageService.get('personal-informationStep') ?
      this.storageService.get('personal-informationStep').value : 0;
    this.storageService.set('registrationStep', 1);
    this.spinner.show();
    this.personalInfoForm = await this.fb.group({
      firstName: ['', [Validators.required,
        Validators.pattern('^[\\s]*[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ ]((?:[-\\s])?(?!.*[-]{2})?[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\'\`]+)*[\\s]*$')]],
      lastName: ['', [Validators.required,
        Validators.pattern('^[\\s]*[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ ]((?:[-\\s])?(?!.*[-]{2})?[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\'\`]+)*[\\s]*$')]],
      middleName: ['', [Validators.required,
        Validators.pattern('^[\\s]*[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ ]((?:[-\\s])?(?!.*[-]{2})?[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\'\`]+)*[\\s]*$')]],
      phoneNumber: ['', [Validators.required, ,
        Validators.pattern('^[\\s]*(\\+38)?(\\d{3})\\-?(\\d{3})\\-?(\\d{2})\\-?(\\d{2})')]],
      verificationCode: this.fb.group({
        phoneCodesOne: ['', [Validators.required]],
        phoneCodesTwo: ['', [Validators.required]],
        phoneCodesThree: ['', [Validators.required]],
        phoneCodesFour: ['', [Validators.required]],
      }),
      dateOfBirth: ['', [Validators.required]],
    });

    const persInfo = await this.storageService.get('personal-information') ?
      await this.storageService.get('personal-information').value : null;
    if (persInfo !== null && persInfo !== undefined) {
      this.personalInfoForm.patchValue(persInfo);
      if (this.personalInfoForm.controls.dateOfBirth.valid) {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const dayOfBirth = new Date(this.personalInfoForm.getRawValue().dateOfBirth);
        const dayOfBirthNow = new Date(today.getFullYear(), dayOfBirth.getMonth(), dayOfBirth.getDate());
        this.howOld = Math.abs(today.getFullYear() - dayOfBirth.getFullYear());
        if (today < dayOfBirthNow) {
          this.howOld--;
        }
      }
    }
    this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  nextStep(): void {
    this.error = false;
    this.step++;
    if (this.regStep < this.step) {
      this.regStep = this.step;
      this.storageService.set('personal-informationStep', this.regStep);
    }
    this.storageService.set('personal-information', this.personalInfoForm.value);
  }

  verificationPhone(): void {
    const phoneConfirm = {
      phoneNumber: this.personalInfoForm.value.phoneNumber
    };
    this.subscriptions.add(this.regPersonalInformationService.phoneConfirmation(phoneConfirm).subscribe(() => {
    }));
  }

  checkForm(item?): void {
    if (item === 'checkNumber') {
      this.spinner.show();
      this.phoneCheck++;
      const phoneConfirm = {
        phoneNumber: this.personalInfoForm.value.phoneNumber
      };
      this.subscriptions.add(this.regPersonalInformationService.phoneConfirmation(phoneConfirm).subscribe(() => {
      }, (err: HttpErrorResponse) => {
        this.errorPhone = !!(err.error.errorCodes.includes(400562));
        this.error = true;
        this.phoneError = !!(err.error.errorCodes.includes(400566));
        this.phoneAlreadyIs = !!(err.error.errorCodes.includes(400539));
        if (this.phoneError) {
          this.nextStep();
        }
        return throwError(err.message);
      }, () => {
        this.spinner.hide();
        this.nextStep();
        this.setTimer();
        this.errorPhone = false;
        this.phoneAlreadyIs = false;
      }));
    } else if (item === 'personal-information') {
      if (this.checkName() || this.personalInfoForm.controls.firstName.invalid || this.personalInfoForm.controls.middleName.invalid ||
        this.personalInfoForm.controls.lastName.invalid) {
        this.error = true;
      } else {
        this.error = false;
        this.nextStep();
      }
    } else {
      if (this.checkName() ||
        ((this.howOld < 18 || this.personalInfoForm.controls.dateOfBirth.invalid) && this.step === 2) ||
        (this.personalInfoForm.controls.phoneNumber.invalid && this.step === 3)) {
        this.error = true;
      } else {
        this.nextStep();
      }

    }
    if (this.step === 4) {
      this.setTimer();
      this.error = false;
      this.phoneStep = true;
    }

    if (this.step === 5) {
      this.goToPayment();
    }
  }

  goToPayment(): void {
    if (this.personalInfoForm.valid) {
      const varificationCodeArray = [];
      varificationCodeArray.push(this.personalInfoForm.value.verificationCode.phoneCodesOne);
      varificationCodeArray.push(this.personalInfoForm.value.verificationCode.phoneCodesTwo);
      varificationCodeArray.push(this.personalInfoForm.value.verificationCode.phoneCodesThree);
      varificationCodeArray.push(this.personalInfoForm.value.verificationCode.phoneCodesFour);
      const reqParams = {...this.personalInfoForm.value};
      reqParams.verificationCode = varificationCodeArray.join('');
      this.spinner.show();
      this.subscriptions.add(this.regPersonalInformationService.addPersonalInformation(reqParams)
        .subscribe(
          () => {
          }, () => {
            this.step = 4;
            this.verCodeError = true;
          },
          () => {
            this.spinner.hide();
            this.storageService.set('registrationStep', 2);
          }));
    }
    if (this.verCodeError === true) {
      this.error = true;
      this.step = 4;
    }
  }

  change(): void {
    this.step = 4;
    this.phoneStep = true;
    this.storageService.set('personal-information', this.personalInfoForm.value);
  }

  getCodeBoxElement(index): any {
    return document.getElementById('codeBox' + index);
  }

  onKeyUpEvent(index, event): any {
    const eventCode = event.which || event.keyCode;
    if (this.getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        this.getCodeBoxElement(index + 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    } else if (this.getCodeBoxElement(index).value.length > 1) {
      this.getCodeBoxElement(index).value %= 10;
    }
    if ((eventCode === 4 || eventCode === 37) && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onKeyDown(index, event): void {
    const eventCode = event.which || event.keyCode;
    if (eventCode === 8 && this.getCodeBoxElement(index).value.length === 0) {
      this.getCodeBoxElement(index - 1).value = null;
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  onFocusEvent(index): any {
    for (let item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }

  checkDate(): boolean {
    if (this.step === 2) {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const dayOfBirth = new Date(this.personalInfoForm.getRawValue().dateOfBirth);
      const dayOfBirthNow = new Date(today.getFullYear(), dayOfBirth.getMonth(), dayOfBirth.getDate());
      this.howOld = Math.abs(today.getFullYear() - dayOfBirth.getFullYear());
      if (today < dayOfBirthNow) {
        this.howOld--;
      }
      return ((this.howOld < 18 || this.personalInfoForm.controls.dateOfBirth.invalid) &&
        this.personalInfoForm.controls.dateOfBirth.touched);
    }
  }

  lengthCode(index, event): void {
    if (this.getCodeBoxElement(index).value.length > 1) {
      this.getCodeBoxElement(index).value = event.data;
    }
  }

  checkName(): boolean {
    if (this.personalInfoForm.controls.firstName.valid &&
        this.personalInfoForm.controls.lastName.valid &&
        this.personalInfoForm.controls.middleName.valid &&
        this.step === 1) {
        this.error = false;
    }
    return (this.personalInfoForm.controls.firstName.invalid &&
            this.personalInfoForm.controls.firstName.touched) ||
           (this.personalInfoForm.controls.lastName.invalid &&
            this.personalInfoForm.controls.lastName.touched) ||
           (this.personalInfoForm.controls.middleName.invalid &&
            this.personalInfoForm.controls.middleName.touched);
  }

  checkNameTouch(): boolean {
    return (!this.personalInfoForm.controls.firstName.touched ||
            !this.personalInfoForm.controls.lastName.touched ||
            !this.personalInfoForm.controls.middleName.touched);
  }

  setTimer(value?): void {
    this.codeCount = this.storageService.get('codeCount').value + 1;
    this.storageService.set('codeCount', this.codeCount);
    this.timerTimeMinutes = 1;
    this.timerTimeSeconds = 0;
    this.codeActive = false;
    if (this.codeCount < 4) {
      if (value === 'retry') {
        this.verificationPhone();
      }
      this.timer = setInterval(() => {
        if (this.timerTimeMinutes >= 0) {
          this.timerTimeSeconds--;
          if (this.timerTimeSeconds === -1) {
            this.timerTimeSeconds = 59;
            this.timerTimeMinutes--;
          }
        }
        if (this.timerTimeMinutes === -1) {
          clearInterval(this.timer);
          this.timerTimeMinutes = 0;
          this.timerTimeSeconds = 0;
          this.codeActive = true;
        }
      }, 1000);
    } else {
      this.codeActive = true;
    }
  }

  clearInterval(): void {
    this.step = 3;
    this.phoneCheck = 0;
    clearInterval(this.timer);
  }

  checkLanguage(key, value: string): void {
    const err = value.split('').map(letter => {
      return this.pattern.test(letter);
    });
    this.errorLanguage[key] = !err.includes(false);
  }

  editData(): void {
    const data = {
      firstName: this.personalInfoForm.value.firstName,
      lastName: this.personalInfoForm.value.lastName,
      middleName: this.personalInfoForm.value.middleName,
      dateOfBirth: this.personalInfoForm.value.dateOfBirth
    };
    this.subscriptions.add(this.regPersonalInformationService.editData(data).subscribe(() => {
      const menuError = {
        personalInformation: true,
        isPurchased: this.storageService.get('correctInformation').value.isPurchased,
        documents: this.storageService.get('correctInformation').value.documents
      };
      if (!menuError.documents) {
        this.storageService.set('registrationStep', 3);
        this.storageService.set('correctInformation', menuError);
      } else {
        const dialogRef = this.matDialog.open(SuccessRegistrationComponent);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.matDialog.closeAll();
            this.storageService.set('finish-registration', true);
            this.router.navigate([`home/dashboard`]).then(() => {
              this.storageService.set('personal-information', {});
            });
          } else {
            this.matDialog.closeAll();
          }
          return result;
        });
      }
    }));
  }

  deleteGap(key, value): void {
    const src = value.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    switch (key) {
      case 0:
        this.personalInfoForm.patchValue({ firstName: src });
        break;
      case 1:
        this.personalInfoForm.patchValue({ lastName: src });
        break;
      case 2:
        this.personalInfoForm.patchValue({ middleName: src });
        break;
    }
  }
}
