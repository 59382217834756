import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILicenseCategory } from '../../../shared/interfaces/licenseCategory';
import { TestItem } from '../../../shared/modules/test-item';
import { LectureView } from '../../../shared/interfaces/lecture-view';

@Injectable({
  providedIn: 'root'
})
export class LecturesService {
  private apiUrl = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

    getLectures(id: string): Observable<LectureView[]> {
    return this.http.get<LectureView[]>(`${this.apiUrl}lectures`);
  }
  getLicenseCategory(): Observable<ILicenseCategory>{
    return this.http.get<ILicenseCategory>(`${this.apiUrl}licensecategories`);
  }
  getLecturesById(id: string): Observable<LectureView> {
    return this.http.get<LectureView>(`${this.apiUrl}lectures/${id}`);
  }
  getLecturesTestById(id: string): Observable<TestItem[]> {
    return this.http.get<TestItem[]>(`${this.apiUrl}trafficrulestests/lectureid/${id}`);
  }
  saveLectureProgress(data): Observable<any>{
    return this.http.post(`${this.apiUrl}lectures/progress`, data);
  }
}
