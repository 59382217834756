 <div class="content-wait">
    <div>
      <img src="../../../assets/icons/success-registration.svg">
    </div>
    <div class="main-content">
      <p class="title">Ваша заявка на навчання очікує підтвердження</p>
      <p class="subtitle">
        На вашу пошту буде надіслано лист з підтвердженням навчання в автошколі
      </p>
      <button (click)="trainingMaterial()">Навчальні матеріали</button>
    </div>
  </div>
