<div class="back-to-category">
  <img src="../../../../../../assets/icons/boorger-menu.svg">
  <p>{{'TESTS.TITLE' | translate}}</p>
</div>
<div class="general-container">
  <div class="test-content-box">
    <router-outlet></router-outlet>
  </div>
  <div class="menu">
    <div class="menu-container">
      <div>
        <div class="menu-container-top">
          <div class="title">
            <p>{{'TESTS.TITLE' | translate}}</p>
          </div>
          <div class="traffic-rule-icon"><img src="../../../assets/icons/traffic-rule-tests.svg"></div>
        </div>
        <div class="tests-content">
          <button *ngFor="let menu of menuContent"
                  [ngStyle]="{'background' : menuSelected.includes(menu.menuSelected) ? menu.color : ''}"
                  class="tests-item">
            <a class="tests-item-content" [routerLink]="menu.link">
              <div [ngStyle]="{'background':menu.color}" class="icon-place">
                <img class="tests-icon" [src]=menu.icon>
              </div>
              <div class="tests-title">
                <p>{{menu.name | translate}}</p>
              </div>
            </a>
          </button>
        </div>
      </div>
      <div class="information-block">
        <p>{{'TESTS.FOOTER' | translate}}</p>
      </div>
    </div>
  </div>
</div>
