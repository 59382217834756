<div class="privacy-policy">
  <div class="privacy-policy-content">
    <div class="theme-content">
      <div class="theme">
        <img src="../../../assets/icons/romdomdom.svg">
        <p>Політика конфіденційності</p>
      </div>

      <img src="../../../assets/images/privacy-policy.png">
    </div>

    <div class="content container-information">
      <div class="content-container">
        <div class="total-information">
          <p>
            Ця Політика конфіденційності персональних даних (далі — Політика конфіденційності) діє відносно всієї
            інформації, розміщеної на сайті в мережі Інтернет за адресою: www.romdomdom.in.ua (далі — Сайт), яку
            користувачі та відвідувачі Сайту (далі — «Ви») можуть отримати про Вас під час використання Сайту, його
            сервісів, програм та матеріалів (далі — «Послуга»).
          </p>
          <p>
            www.romdomdom.in.ua - це сайт, адміністратором (оператором) якого є фізична особа - підприємець Степаник
            Наталія Ярославівна. (далі — «Ми»). Ми зареєстровані за законодавством України (дата та номер запису про
            проведення державної реєстрації: 30 серпня 2019 року, 24150000000102749), місцезнаходження офісу: Україна,
            79066 м. Львів, вул. Сихівська, 20.
          </p>
          <p> Використання Послуг означає Вашу письмову згоду на обробку Ваших персональних даних відповідно до Політики
            конфіденційності, а також Вашу беззастережну згоду з умовами Політики конфіденційності. У разі незгоди з
            цими умовами Ви маєте утриматися від використання Послуги.
          </p>
        </div>
        <ol>
          <li *ngFor="let item of text" class="theme-blocks">
            <p class="theme list-text  general-item">{{item.theme}}</p>
            <ol>
              <li *ngFor="let themeItem of item.themeItem" class="theme-marker">
                <p class="list-text">
                  {{themeItem.text}} <a
                  [class.underline]="themeItem.urlUnderline">{{themeItem.url}}</a>{{themeItem.text2}}
                  <a>{{themeItem.url2}}</a>
                </p>
                <ul>
                  <li *ngFor="let itemContent of themeItem.item">
                    <p class="list-of-item">
                      <b *ngIf="itemContent.definition">{{itemContent.definition}}</b>{{itemContent.itemText}} <a
                      *ngIf="itemContent.url" [class.underline]="itemContent.urlUnderline">{{itemContent.url}}</a>
                      <span *ngIf="itemContent.itemTextSecondPart">{{itemContent.itemTextSecondPart}}</span>
                    </p>
                  </li>
                </ul>
                <p *ngIf="themeItem.addingInformation" class="adding-information">{{themeItem.addingInformation}}</p>
              </li>
            </ol>
          </li>
        </ol>
        <div class="redaction">
          <p><b>Редакція:</b> квітень 2021 року</p>
        </div>
      </div>
      <div class="confirm">
        <div class="confirm-content">
          <p class="before-registration">Перед реєстрацією:</p>
          <form [formGroup]="confirmForm">
            <mat-checkbox formControlName="first">Я повністю приймаю умови публічної оферти (пропозиції) про надання
              послуг
            </mat-checkbox>
            <mat-checkbox formControlName="second">Я приймаю умови конфіденційності</mat-checkbox>
            <button (click)="confirm()"
                    [class.noActive]="confirmForm.invalid"
                    [disabled]="confirmForm.invalid"
                    class="confirm-button"

            >Реєстрація в автошколу
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
