import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrafficRulesService } from '../../../training-materials/services/traffic-rules.service';
import { TestsOnThemesService } from '../../services/tests-on-themes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ILicenseCategory } from '../../../../shared/interfaces/licenseCategory';
import { StorageService } from '../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Tests } from '../../../../shared/interfaces/tests-on-themes';

@Component({
  selector: 'app-ticket-tests',
  templateUrl: './ticket-tests.component.html',
  styleUrls: ['./ticket-tests.component.scss']
})
export class TicketTestsComponent implements OnInit {

  categories: Observable<Tests[]>;
  licenseCategory: ILicenseCategory;

  constructor(private trafficRulesService: TrafficRulesService,
              private testsOnThemes: TestsOnThemesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.licenseCategory = await this.storageService.get('license-category').value;
    this.categories = await this.trafficRulesService.getTrafficRuleBTicketsyId(this.licenseCategory.id);
    this.storageService.set('test-category', 'ticket-tests');
    this.spinner.hide();
  }

}
