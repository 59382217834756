import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestsRoutingModule } from './tests-routing.module';
import { TestsOnThemesComponent } from './components/test-on-themes-content/tests-on-themes/tests-on-themes.component';
import { TranslateModule } from '@ngx-translate/core';
import { TicketTestsComponent } from './components/ticket-tests/ticket-tests.component';
import { TestContentComponent } from './components/test-content/test-content.component';
import { SelectedTestsComponent } from './components/selected/selected-tests/selected-tests.component';
import { TestComponent } from './components/test-on-themes-content/test/test.component';
import { SharedModule } from '../../shared/shared.module';
import { TicketComponent } from './components/ticket-tests/ticket/ticket.component';
import { TicketContentComponent } from './components/ticket-content/ticket-content.component';
import { SelectedTicketComponent } from './components/selected/selected-ticket/selected-ticket.component';
import { TwentyRandomTicketsComponent } from './components/twenty-random-tickets/twenty-random-tickets.component';



@NgModule({
  declarations: [TestsOnThemesComponent,
    TestComponent, TicketTestsComponent,
    TestContentComponent,
    SelectedTestsComponent,
    TicketComponent,
    TicketContentComponent,
    SelectedTicketComponent,
    TwentyRandomTicketsComponent],
  exports: [
    TicketContentComponent
  ],
  imports: [
    CommonModule,
    TestsRoutingModule,
    TranslateModule,
    SharedModule
  ]
})
export class TestsModule {
}
