import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test-content.component.html',
  styleUrls: ['./test-content.component.scss']
})
export class TestContentComponent implements OnInit {

  menuContent = [
    {
      link: '/home/tests/test/tests-on-themes',
      menuSelected: 'tests-on-themes',
      color: '#FFD576',
      name: 'TESTS.TESTS-ON-THEMES',
      icon: '../../../assets/icons/tests-on-themes.svg'
    }, {
      link: '/home/tests/test/ticket-tests',
      menuSelected: 'ticket',
      color: '#C3E6FF',
      name: 'TESTS.TICKET-TESTS',
      icon: '../../../assets/icons/ticket-tests.svg'
    }, {
      link: '/home/tests/test/selected-category',
      menuSelected: 'select',
      color: '#AEECDD',
      name: 'TESTS.SELECTED',
      icon: '../../../assets/icons/selected.svg'
    }, {
      link: '/home/tests/random-questions',
      menuSelected: 'random',
      color: '#E0D9F2',
      name: 'TESTS.RANDOM',
      icon: '../../../assets/icons/random-question.svg'
    }
  ];
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  get menuSelected(): string {
    return this.router.url;
  }

  ngOnInit(): void {
  }

  navigate(link): void {
    this.router.navigate([link]);
  }
}
