import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Components
import { AppComponent } from './app.component';

// Modules

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserComponent } from './modules/user/user.component';
import { TestsModule } from './modules/tests/tests.module';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrainingMaterialsModule } from './modules/training-materials/training-materials.module';
import { CourseLecturesComponent } from './modules/course/course-lectures/course-lectures.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { DrivingSchoolRegistrationComponent } from './modules/driving-school-registration/driving-school-registration.component';
import { DrivingSchoolRegistrationModule } from './modules/driving-school-registration/driving-school-registration.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RedirectPageComponent } from '@module/web-view-redirect-page/components/redirect-page/redirect-page.component';
import { MatSelectModule } from '~/node_modules/@angular/material/select';
import { BuyCourseComponent } from './buy-course/buy-course.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    CourseLecturesComponent,
    DrivingSchoolRegistrationComponent,
    RedirectPageComponent,
    BuyCourseComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    HttpClientModule,
    SocialLoginModule,
    TestsModule,
    TrainingMaterialsModule,
    MatProgressBarModule,
    MatCardModule,
    DrivingSchoolRegistrationModule,
    NgxMaskModule.forRoot(maskConfig),
    MatSelectModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '882376766374-mjub6vvs5t292o3nari32a68h6grrfb6.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('143995727684389'),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
