import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-information',
  templateUrl: './social-information.component.html',
  styleUrls: ['./social-information.component.scss']
})
export class SocialInformationComponent implements OnInit {
  @Input() drivingInformation: boolean;
  @Input() line: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
