<div class="test-container">
  <a [routerLink]="'home/tests/test/selected-category'" class="back-to-category">
    <img src="../../../../../../assets/icons/arrow-left-grey.svg">
    <p>{{'TESTS.SELECTED' | translate}}</p>
  </a>
  <app-ticket-content [link]="'/home/tests/test/selected-category'"
                      [testItems]="categories"
                      [testKind]="'TESTS.BACK_TO_FAVOURITE' | translate">
  </app-ticket-content>
</div>
