<div class="dialog-content container">
  <div class="content container-information">
    <p class="title">{{'DIALOGS.CHANGE_PASSWORD_TYPE.TITLE' | translate}}</p>
    <div class="buttons">
      <button (click)="closeDialog(false)" class="leave">
        <p>{{'DIALOGS.CHANGE_PASSWORD_TYPE.BACK' | translate}}</p>
      </button>
      <button (click)="closeDialog(true)" class="continue">
        <p>{{'DIALOGS.CHANGE_PASSWORD_TYPE.CHANGE' | translate}}</p>
      </button>
    </div>
  </div>
</div>
