<div class="reject-lesson-content container">
  <div class="content-container container-information">
    <div class="title"><p>Ви дійсно бажаєте скасувати поїздку?</p></div>
    <div>
      <p class="subtitle">
        Заняття буде не зараховане, якщо ви скасуєте бронювання. У разі наявності поважної причини повідомте
        адміністратора
      </p>
    </div>
    <div class="buttons">
      <button (click)="closeDialog()" class="back"><p>Повернутися</p></button>
      <button (click)="closeDialog(true)" class="reject"><p>Скасувати поїздку</p></button>
    </div>
  </div>
</div>
