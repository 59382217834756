import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestsOnThemesComponent } from './components/test-on-themes-content/tests-on-themes/tests-on-themes.component';
import { TicketTestsComponent } from './components/ticket-tests/ticket-tests.component';
import { TestContentComponent } from './components/test-content/test-content.component';
import { TestComponent } from './components/test-on-themes-content/test/test.component';
import { SelectedTestsComponent } from './components/selected/selected-tests/selected-tests.component';
import { TicketComponent } from './components/ticket-tests/ticket/ticket.component';
import { SelectedTicketComponent } from './components/selected/selected-ticket/selected-ticket.component';
import { TwentyRandomTicketsComponent } from './components/twenty-random-tickets/twenty-random-tickets.component';
import { TiketGuard } from '../../core/guards/tiket.guard';


const routes: Routes = [
  {
    path: 'test',
    component: TestContentComponent,
    children: [
      {
        path: 'tests-on-themes',
        component: TestsOnThemesComponent,
      },
      {
        path: 'ticket-tests',
        component: TicketTestsComponent,
      },
      {
        path: 'selected-category',
        component: SelectedTestsComponent,
      }
    ]
  },
  {
    path: 'test-on-theme/:id',
    component: TestComponent,
    canDeactivate: [TiketGuard],
  },
  {
    path: 'ticket-test/:id',
    component: TicketComponent,
    canDeactivate: [TiketGuard]
  },

  {
    path: 'random-questions',
    component: TwentyRandomTicketsComponent,
    canDeactivate: [TiketGuard]
  },
  {
    path: 'selected-test',
    component: SelectedTicketComponent,
    canDeactivate: [TiketGuard]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestsRoutingModule { }
