<div class="dialog-content container">
  <img (click)="closeDialog(false)" class="cancel" src="../../../assets/icons/cancel.svg">

  <div class="content">
    <div class="video-block">
      <div class="video-block-content">
        <p class="video-title">Дізнайся все про навчання в одному ролику</p>
        <img src="../../../assets/icons/success-registration.svg">
      </div>
    </div>
    <div class="main-content ">
      <div>
        <div class="title">
          Отримай водійське посвідчення за кілька кліків
        </div>
        <p class="subtitle">Питання, що представлені на нашому сайті і у мобільних застосунках повністю:</p>
        <p class="subtitle">Питання, що представлені на нашому сайті і у мобільних застосунках повністю:</p>
        <p class="subtitle">Питання, що представлені на нашому сайті і у мобільних застосунках повністю:</p>
      </div>
      <button (click)="closeDialog(true)">Розпочати навчання</button>
    </div>
  </div>
</div>
