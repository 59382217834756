<div *ngIf="practiceWindow || continueLearning" class="cdk-overlay-backdrop
 cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing opacity"></div>
<div *ngIf="practiceWindow || isNoPractice">
  <div class="dialog-content">
    <img (click)="close()" class="exit" src="../../../assets/icons/cancel.svg">
    <div class="content">
      <img src="../../../assets/images/access-to-practice.svg">
      <div class="text">
        <div class="title">
          <p>{{'COURSE.PRACTICE_DIALOG.HAPPY_TITLE' | translate}}</p>
          <p>{{'COURSE.PRACTICE_DIALOG.TITLE' | translate}}</p>
        </div>
        <div class="subtitle">
          <p>{{'COURSE.PRACTICE_DIALOG.SUBTITLE' | translate}} {{limitCount}} {{'COURSE.PRACTICE_DIALOG.SUBTITLE_END' | translate}}</p>
        </div>
        <button (click)="reserve()">{{'COURSE.PRACTICE_DIALOG.BUTTON' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="continueLearning">
  <div class="dialog-content-continue-learning">
    <app-continue-learning></app-continue-learning>
    <button (click)="closeWindowLearning()"><p>Зрозуміло</p></button>
  </div>
</div>
<div *ngIf="registerInfo === null || registerInfo" class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div> {{'COURSE.TITLE' | translate}}</div>
    </div>
    <hr *ngIf="(registerInfo && registerInfo.courseRequestStatus === 3)">
    <div *ngIf="(registerInfo && registerInfo.courseRequestStatus !== 1  &&
                                 registerInfo.courseRequestStatus !== 2) ||
                                 registerInfo === null; else noConfirm" class="content">
      <app-start-learning *ngIf="(registerInfo === null || registerInfo.courseRequestStatus === 0) && !isNoPractice"
                          [statusData]="'trainingMaterial'"
      ></app-start-learning>
      <app-start-learning *ngIf="isNoPractice" [statusData]="'pr'"></app-start-learning>
      <div *ngFor="let course of courses; let i = index"
           [ngClass]="{'active':(openComment === 0 ||
                                 openComment === i+1 ||
                                 i+1 === courses.length) &&
                                 !noRegister}"
           class="exam">
        <div class="exam-content">
          <div (click)="open(i); openStatus = true">
            <div class="part bottom">
              <div class="lecture-number"><p>{{'COURSE.LECTURE' | translate}} {{course.number}}</p></div>
              <div *ngIf="(i > 0 && courses[i-1].progress.percentage >= 90 && course.testsCount > 0 || i == 0)"
                   [ngStyle]="{'color': course.progress.percentage < 90 ? '#FE5858' : ''}"
                   class="persent">
                <p>{{course.progress.percentage}}%</p>
              </div>
            </div>
            <div class="part">
              <div class="part-theme">
                <div *ngFor="let theme of course.structure; let index = index"
                     class="themes">
                  <p>{{'COURSE.THEME' | translate}} {{theme.chapterNumber}}: {{theme.chapterName}} <span
                    *ngIf="theme.part">({{'COURSE.PART' | translate}}. {{theme.part}})</span></p>
                </div>
              </div>
              <div *ngIf="(i > 0 && (courses[i-1].progress.percentage >=90) && course.testsCount> 0)||i==0"
                   class="result">
                <div>
                  <p>{{'COURSE.QUESTION' | translate}}: {{course.testsCount}}</p>
                </div>
                <div class="right-answer">
                  <img src="../../../assets/icons/right-answer.svg">
                  <span>{{course.progress.rightAnswersCount}}</span>
                </div>
                <div class="false-answer">
                  <img src="../../../assets/icons/false-answer.svg">
                  <span>{{course.progress.wrongAnswersCount}}</span>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="(i > 0 && (courses[i-1].progress.percentage < 90) || course.testsCount === 0)"
               class="disabled"></div>
          <div *ngIf="((openComment === i) || (courses.length === 3 && course.testsCount > 0)) &&
                        !(i > 0 && (courses[i-1].progress.percentage < 90) || course.testsCount === 0)"
               [ngClass]="{'active-button':openComment === i || (courses.length === 3 && course.testsCount > 0)}"
               class="button-place">
            <a (click)="savePosition(i)" routerLink="/home/course/lecture-view/{{course?.id}}">
              <button
                [ngClass]="{'active-button':openComment === i && openStatus}"
              >
                <p>{{'COURSE.LECTURE' | translate}}</p></button>
            </a>
            <a (click)="savePosition(i)" href="/home/course/test/{{course?.id}}">
              <button
                [ngClass]="{'active-button':openComment === i && openStatus}"
              >
                <p>{{'COURSE.TEST_BUTTON' | translate}}</p></button>
            </a>

          </div>
        </div>

      </div>
      <div></div>
    </div>
    <ng-template #noConfirm>
      <div *ngIf="registerInfo.courseRequestStatus === 1">
        <app-wait-confirm></app-wait-confirm>
      </div>
      <div *ngIf="registerInfo.courseRequestStatus === 2" class="reject">
        <app-reject-application [rejectText]="registerInfo.courseRequestRejection.comment"></app-reject-application>
      </div>
    </ng-template>
  </div>
  <div class="menu">
    <div class="menu-container content">
      <div [class.course-result-block]="noRegister" class="course-result">

        <app-student-progress (allPractice)="allPractice($event)"
                              [drivingValues]="drivingValues"
                              [noRegister]="noRegister"
                              [page]="lessonsScoreValue >= 90 ? 1 : 0"
                              [practiseArray]="practiseArray"
                              [progressBarWidth]="progressBarWidth"
                              [startProgress]="(isPracticeAllowed)?1:0 "
                              [value]="lessonsScoreValue"
                              [tripCount]="limitCount">
        </app-student-progress>

        <div *ngIf="!noRegister && !((drivingValues >= limitCount || successPractice ) && lessonsScoreValue >= 90 && !isFinalPassed) && isFull"
             class="information">
        </div>
      </div>
      <div *ngIf="isFinalPassed && registerInfo.courseRequestStatus === 3" class="service-centres">
        <div class="content">
          <div class="title padding">
            <p *ngIf="registerInfo.isCourseCompleted; else noCourseCompleted " class="success-text">
              {{'COURSE.SUCCESS' | translate}}
            </p>
            <ng-template #noCourseCompleted>
              <p>{{'COURSE.SERVICE_CENTRES.TITLE' | translate}}
                <span>{{registerInfo.personalDetails.dateOfCourseFinish| date:'dd.MM.yyyy'}}</span></p>

            </ng-template>
          </div>
          <div class="question-block">
            <div class="title">
              <p>{{'COURSE.SERVICE_CENTRES.SUBTITLE' | translate}}</p>
            </div>
            <div class="social">
              <div class="background facebook">
                <a [href]="socialLinks.messages" target="_blank"></a>
              </div>
              <div class="background telegram">
                <a [href]="socialLinks.telegram" target="_blank"></a>
              </div>
              <div class="background viber">
                <a [href]="socialLinks.viber" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div *ngIf="accessToFinalTest  && lessonsScoreValue >= 90 && !isFinalPassed
           else no_final_exam"
           class=" block final-exam">
        <img src="../../../assets/icons/final-exam.svg">
        <div class="text final-text">
          <p class="title">{{'COURSE.FINAL_TEST.TITLE' | translate}}</p>
          <p class="subtitle subtitle2">{{'COURSE.FINAL_TEST.SUBTITLE' | translate}}</p>
          <button [disabled]="isFinalPassed"
                  [ngStyle]="{'cursor':isFinalPassed? 'default' : ''}"
                  routerLink="/home/course/final-test">{{'COURSE.FINAL_TEST.BUTTON' | translate}}</button>
        </div>
      </div>
      <ng-template #no_final_exam>
        <div *ngIf="!isFinalPassed && isFull; else successFinal" class="block final-exam no-active ">
          <img src="../../../assets/icons/final-exam-no-active.svg">
          <div class=" text final-text">
            <p class="title">{{'COURSE.FINAL_TEST.TITLE' | translate}}</p>
            <p class="subtitle subtitle2 padding">{{'COURSE.FINAL_TEST.SUBTITLE' | translate}}</p>
          </div>
        </div>
        <ng-template #successFinal>
          <div *ngIf="isFull" class="block final-exam">
            <img src="../../../assets/icons/final-exam.svg">
            <div class=" text final-text">
              <p class="title success-final">{{'COURSE.FINAL_TEST.SUCCESS' | translate}}</p>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <div *ngIf="isPracticeAllowed || registerInfo?.courseRequestStatus === 6
           else no_practice"
           class="block practic">
        <img src="../../../assets/icons/practic.svg">
        <div class="text practic-text">
          <p class="title">{{'COURSE.DRIVE_ACTIVE.TITLE' | translate}}</p>
          <p class="subtitle">{{'COURSE.DRIVE_ACTIVE.SUBTITLE' | translate}}</p>
          <a routerLink="/home/practice/practical-training">
            <button>{{'COURSE.DRIVE_ACTIVE.BUTTON' | translate}}</button>
          </a>
        </div>
      </div>
      <ng-template #no_practice>
        <div *ngIf="!isFinalPassed && tariff?.tripsLimit > 0
            else request_practice"
            class="block practic no-active ">
          <img src="../../../assets/icons/practic-no-active.svg">
          <div class="text practic-text text-no-practice">
            <p class="title">{{'COURSE.DRIVE_NO_ACTIVE.TITLE' | translate}}</p>
            <p class="subtitle no-practice-subtitle">{{'COURSE.DRIVE_NO_ACTIVE.SUBTITLE' | translate}}</p>
          </div>
        </div>
      </ng-template>
      <ng-template #request_practice>
        <div *ngIf="registerInfo?.courseRequestStatus === 3 && tariff?.tripsLimit > 0"
        class="block practic ">
          <img src="../../../assets/icons/practic-no-active.svg">
          <div class="text practic-text text-no-practice">
            <p class="title">{{'COURSE.REQUEST_FOR_DRIVE.TITLE' | translate}}</p>
            <p class="subtitle no-practice-subtitle">{{'COURSE.REQUEST_FOR_DRIVE.SUBTITLE' | translate}}</p>
            <a routerLink="/home/practice/documents-update">
              <button>{{'COURSE.DRIVE_ACTIVE.BUTTON' | translate}}</button>
            </a>
          </div>
        </div>
      </ng-template>
      <div *ngIf="isPartialPayment && !canPay" class="block partial-block">
        <img src="../../../assets/images/partial.svg">
        <div class=" text final-text partial-text">
          <p class="title partial-title" style="color: #5D6A76 !important;">Оплата частинами</p>
          <p class="subtitle subtitle-partial" style="color: #5D6A76 !important;">Оплачуйте свою частину, та продовжуйте навчання дальше!</p>
        </div>
      </div>
      <div *ngIf="isPartialPayment && canPay" class="block partial-block partial-activate">
        <img src="../../../assets/images/partial-act.png">
        <div class=" text final-text partial-text">
          <p class="title partial-title">Оплата частинами</p>
          <p class="subtitle subtitle-partial">{{partialSum}}</p>
          <button (click)="pay()" class="partial-button">Оплатити</button>
        </div>
        <form method="POST" accept-charset="utf-8" id="payForm"  action="https://www.liqpay.ua/api/3/checkout">
          <input id="paymentData" type="hidden" name="data" [value]="paymentData"  />
          <input id="paymentSignature" type="hidden" name="signature" [value]="paymentSignature"  />
        </form>
      </div>
    </div>
  </div>
</div>
