import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutorizedUserGuard } from './core/guards/autorized-user.guard';
import { BuyCourseComponent } from './buy-course/buy-course.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'authorization',
    loadChildren: () => import('./modules/authorize/authorize.module').then(m => m.AuthorizeModule),
  },
  {
    path: 'viber-link',
    loadChildren: () => import('./modules/viber/viber.module').then(m => m.ViberModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate: [AutorizedUserGuard]
  },
  {
    path: 'driving-school-registration',
    loadChildren: () => import('./modules/driving-school-registration/driving-school-registration.module').
    then(m => m.DrivingSchoolRegistrationModule),
    canActivate: [AutorizedUserGuard]
  },
  {
    path: 'registration-category-select',
    loadChildren: () => import('./modules/registration-category-select/registration-category-select.module').
    then(m => m.RegistrationCategorySelectModule),
    canActivate: [AutorizedUserGuard]
  },
  {
    path: 'start-learning',
    loadChildren: () => import('./modules/start-learning/start-learning.module').
    then(m => m.StartLearningModule),
    canActivate: [AutorizedUserGuard]
  },
  {
    path: 'instructor',
    loadChildren: () => import('./modules/instuctor-finish-registration/finish-instructor.module').
    then(m => m.FinishInstructorModule)
  },
  {
    path: 'buy-course',
    component: BuyCourseComponent
  },
  {
    path: '**', redirectTo: '/home/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
