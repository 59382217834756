import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-categories',
  templateUrl: './sign-categories.component.html',
  styleUrls: ['./sign-categories.component.scss']
})
export class SignCategoriesComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {}
}

