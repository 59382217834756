import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestsOnThemesService } from '../../../services/tests-on-themes.service';
import { TestItem } from '../../../../../shared/modules/test-item';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-selected-ticket',
  templateUrl: './selected-ticket.component.html',
  styleUrls: ['./selected-ticket.component.scss']
})
export class SelectedTicketComponent implements OnInit, OnDestroy {


  categories: TestItem[];
  subscriptions: Subscription = new Subscription();


  constructor(private testsOnThemes: TestsOnThemesService,
              private spinner: NgxSpinnerService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.subscriptions.add(this.testsOnThemes.getFavouritesTest().subscribe((resp: TestItem[]) => {
      this.categories = resp;
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  goToPreviousUrl(): void {
    this.router.navigate(['home/tests/test/selected-category']);
  }
}
