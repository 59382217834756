<div *ngIf="(testSelect == 0 || (testHint === 0 && testSelect == 1 && testItem?.hint))&& !finalTest"
     class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing opacity"></div>
<div *ngIf="testItem && (testItem.imagePath || testItem.imagePath === null)" class="test-container">
  <div class="question">
    <p>{{testItem?.question}}</p>
  </div>
  <div class="answer-options-container">
    <div class="adding-function-container">
      <div *ngIf="isStartTest" class="adding-function">
        <div *ngIf="!finalTest" class="add-to-favourites">
          <img (click)="addDeleteFavouriteQuestion(testItem)"
               *ngIf="!testItem?.isFavorite && !finalTest"
               src="../../../assets/icons/add-to-favourites.svg">
          <img (click)="addDeleteFavouriteQuestion(testItem)"
               *ngIf="testItem?.isFavorite && !finalTest"
               src="../../../assets/icons/favourites-star.svg">
          <div *ngIf="testSelect === 0" [ngClass]="{'active':testSelect == 0}">
            <img class="white-arrow" src="../../../assets/icons/white-arrow.svg">
            <div class="block-training-material">
              <img src="../../../assets/icons/favourites-star.svg"> <span
              class="title">{{'DIALOGS.FAVOURITES.TITLE' | translate}}</span>
              <div class="block-content">
                <div class="subtitle">
                  {{'DIALOGS.FAVOURITES.SUBTITLE' | translate}}
                </div>
                <div class="button">
                  <button (click)="testSelect = 1; setTestSelect()" class="next">
                    <span> {{'DIALOGS.FAVOURITES.BUTTON' | translate}}</span>
                    <span><img src="../../../assets/icons/red-arrow.svg"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="testItem?.hint && !finalTest" class="hint">
          <img (click)="openHint(testItem?.hint)" src="../../../assets/icons/hint.svg">
          <div *ngIf="testHint === 0 && testSelect == 1" [ngClass]="{'active':testHint === 0 && testSelect == 1}">
            <img class="white-arrow" src="../../../assets/icons/white-arrow.svg">
            <div class="block-training-material">
              <img src="../../../assets/icons/active-hint.svg"> <span
              class="title">{{'DIALOGS.TEST_HINT.TITLE' | translate}}</span>
              <div class="block-content">
                <div class="subtitle">
                  {{'DIALOGS.TEST_HINT.SUBTITLE' | translate}}
                </div>
                <div class="button">
                  <button (click)="testHint = 1; setTestHint()" class="next">
                    <span> {{'DIALOGS.TEST_HINT.BUTTON' | translate}}</span>
                    <span><img src="../../../assets/icons/red-arrow.svg"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="testItem?.imagePath" class="image-block">
      <div *ngIf="loadImg && !isFirefox" class="loadImg"></div>
      <div *ngIf="loadImg && !isFirefox" class="spinner">
        <img src="../../../assets/icons/test-load-image-spinner.svg">
      </div>
      <img (click)="openImage(storagePath + testItem?.imagePath)"
           (load)="spinnerEvent($event)"
           (loadstart)="spinnerEvent($event)"
           *ngIf="(testItem?.imagePath && isItemComplete) || isFirefox"
           [src]="storagePath + testItem?.imagePath"
           class="image">
    </div>

    <div (click)="checkCorrectAnswer(testItem.id, question.id)"
         *ngFor="let question of testItem?.questionAnswers; let i = index"
         [ngClass]="{'correct-answer': question?.id === testItem?.correctAnswerId,
                     'incorrect-answer': question?.id === testItem?.inCorrectAnswerId ,
                     'unclickable-container': testItem?.testPassed }"
         (window:keypress)="enterKeyAnswer($event, i, testItem.id, question.id)"
         class="answer-options">
      <div class="number">{{i + 1}}</div>
      <div class="answer">{{question?.description}}</div>
      <div><img *ngIf="question?.id !== testItem?.correctAnswerId && question?.id !== testItem?.inCorrectAnswerId"
                src="../../../assets/icons/answer-checked.svg">
      </div>
      <div><img *ngIf="question?.id === testItem?.correctAnswerId" src="../../../assets/icons/circle-green.svg"></div>
      <div><img *ngIf="question?.id === testItem?.inCorrectAnswerId" src="../../../assets/icons/circle-unchecked.svg">
      </div>
    </div>
  </div>
</div>

