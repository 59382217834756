import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-final-test',
  templateUrl: './final-test.component.html',
  styleUrls: ['./final-test.component.scss',
    '/src/app/shared/dialogs/dialogs.scss']
})
export class FinalTestComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FinalTestComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

}
