import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../../../layout/services/search.service';
import { StorageService } from '../../../../../core/services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialComponents } from '../../../../../shared/interfaces/training-material-components';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-penalties-search',
  templateUrl: './penalties-search.component.html',
  styleUrls: ['./penalties-search.component.scss',
  '/src/app/style/penalties.scss']
})
export class PenaltiesSearchComponent implements OnInit {

  penalties: Observable<TrainingMaterialComponents>;
  openComment = [];
  searchText: string;

  constructor(private activatedRoute: ActivatedRoute,
              private searchService: SearchService,
              private storageService: StorageService,
              private spinner: NgxSpinnerService) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.penalties = await this.searchService.searchByPenalties(this.activatedRoute.snapshot.params.searchText);
    this.searchText = await this.activatedRoute.snapshot.params.searchText;
    this.spinner.hide();
  }

  open(el): void {
    if (this.openComment.includes(el)){
      this.openComment =  this.openComment.filter(element => element !== el);
    }
    else{
      this.openComment.push(el);
    }
  }
}
