import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-registration',
  templateUrl: './success-registration.component.html',
  styleUrls: ['./success-registration.component.scss']
})
export class SuccessRegistrationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SuccessRegistrationComponent>,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

  cancel(): void {
    this.matDialog.closeAll();
  }
}
