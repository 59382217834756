<div *ngIf="activeCategory" class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div>
        <p>
          {{activeCategory?.name}}
        </p>
      </div>
    </div>
    <hr>
    <div *ngIf="trafficRules" class="content">
      <div class="content-container">
        <div *ngFor="let trafficRule of trafficRules| async; let it=index; " class="traffic-rule">
          <div class="traffic-rule-content">
            <div class="traffic-rule-number">
              <p>{{trafficRule.number}}</p>
            </div>
            <div [innerHTML]="trafficRule?.description | highlightsText: searchText"
                 class="traffic-rule-name"></div>
            <div (click)="open(it)"
                 *ngIf="trafficRule.comment"
                 class="comment">{{'TRAINING_MATERIAL.EXPERT_COMMENT' | translate}}
            </div>
            <div *ngIf="this.openComment.includes(it)"
                 [froalaView]="trafficRule?.comment" class="comment-place"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="menu">
    <div class="menu-container">
      <div class="menu-container-top">
        <div class="title">{{'TRAINING_MATERIAL.TRAFFIC_RULE_TITLE' | translate}}
          <div class="subtitle">{{'TRAINING_MATERIAL.TRAFFIC_RULE_SUBTITLE' | translate}}</div>
        </div>

        <div class="traffic-rule-icon"><img src="../../../assets/icons/traffic.svg"></div>
      </div>
      <div class="menu-content-container">
        <a (click)="changeCategory(category)"
           *ngFor="let category of categories | async"
           [routerLink]="'/home/tm/traffic-rules/categories/' + category.id"
           class="menu-item">
          <div class="menu-item-content">
            <div class="number"><p>{{category.number}}.</p></div>
            <div class="theme-name">
              <p>{{category.name}}</p>
            </div>
            <div *ngIf="activeCategory?.id === category.id;else other_content" class="arrow">
              <img src="../../../assets/icons/arrow-left.svg">
            </div>
            <ng-template #other_content>
              <div class="arrow">
                <img src="../../../assets/icons/arrow-right.svg">
              </div>
            </ng-template>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
