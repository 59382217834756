import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LeaveTestComponent } from '../../shared/dialogs/leave-test/leave-test.component';
import { map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
import { CancelRegistrationComponent } from '../../shared/dialogs/cancel-registration/cancel-registration.component';

@Injectable({
  providedIn: 'root'
})
export class FinishRegistrationGuard implements CanDeactivate<unknown> {

  constructor(private matDialog: MatDialog,
              private stor: StorageService) {
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const dialogConfig = new MatDialogConfig();
    const data = {
      hint: 'ssss'
    };
    dialogConfig.data = data;
    if (!this.stor.get('finish-registration').value) {
    const dialogRef = this.matDialog.open(CancelRegistrationComponent);
    return dialogRef.afterClosed().pipe(map(result => {
      if (result) {
          this.matDialog.closeAll();
          this.stor.remove('personal-information', );
          this.stor.remove('editDocuments');
          this.stor.remove('correctInformation');
          this.stor.remove('personal-informationStep');
        }
      return result;
      }));
    }
    else {
      return true;
    }
  }

}
