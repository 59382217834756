<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div><span [routerLink]="'/home/tm/road-marks/category/'+activeCategory?.id"
                 class="categoryName">{{categoryName}}</span>
        <img src="../../../assets/icons/boorger-menu.svg">{{roadMarking?.name}} </div>
    </div>
    <hr>
    <div class="more-information-content">
      <div class="content-container">
        <div *ngIf="roadMarking" class="roadMarking-number">
          <p>{{roadMarking.number}} {{roadMarking.name}}</p>
        </div>
        <div *ngIf="roadMarking" [froalaView]="roadMarking.description"></div>
        <div (click)="openComment=!openComment"
             *ngIf="roadMarking?.comment!=null"
             class="comment">
          <p>{{'TRAINING_MATERIAL.EXPERT_COMMENT' | translate}}</p>
        </div>
        <div *ngIf="openComment"
             [froalaView]="roadMarking?.comment"
             class="comment-place"></div>
      </div>
    </div>
  </div>
  <div class="menu">
    <app-road-marking-menu [activeCategory]="activeCategory"
                           [categories]="categories">
    </app-road-marking-menu>
  </div>
  <div class="menu-mobile none">
    <div class="menu-container">
      <div *ngFor="let category of categories | async" [routerLink]="'/categories/'+category.id" class="menu-item">
        <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
          <div class="number"><p>{{category.number}}.</p></div>
          <div class="theme-name">
            <p>{{ category.name}}</p>
          </div>
          <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
