<div *ngIf="activeCategory && roadMarkings" class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div>
        <p>{{activeCategory?.name}}</p>
      </div>
    </div>
    <hr>
    <div class="content">
      <a (click)="openMarking('/home/tm/road-marks/road-mark/' + roadMarking.id,activeCategory?.name)"
         *ngFor="let roadMarking of roadMarkings| async; let it=index"
         class="little-block">
        <div class="little-block-content">
          <div class="marking-image">
            <img alt="" src="{{prefixImgPath}}/road-markings/{{roadMarking.number}}.png">
          </div>
          <div class="marking-content">
            <div class="marking-number">
              <p>{{'TRAINING_MATERIAL.ROAD_MARKING' | translate}} {{roadMarking.number}}</p>
            </div>
            <div *ngIf="roadMarking.name" class="little-block-name">
              <p>{{roadMarking.name}}</p>
            </div>
            <div class="marking-arrow"><img alt="" class="marking-arrow-image" src="../../assets/icons/sign-arrow.svg">
            </div>
          </div>
        </div>
        <div class="big">
          <div class="disabled-block"></div>
          <div
            [ngClass]="{left:(it%3 === 0 || it === 0 && it !== 1),
                        left2:(((it - 1)%3 === 0 || (it - 1) === 0 && (it - 1) !== 1)),
                        left3:(((it + 1)%3 === 0 || (it + 1) === 0 && (it + 1) !== 1))}"
            class="item">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{roadMarking.number + '.'}}</span>
              <span class="title-hover-block-name">{{roadMarking.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-marking-hover-block">
              </div>
              <div [froalaView]="roadMarking?.description" class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
        <div class="little">
          <div [ngClass]="{left:(it%2 === 0 || it === 0 && it !== 1),
                           left2:(((it + 1)%2 === 0 || (it + 2) === 0 && (it + 2) !== 1))}"
               class="item ">
            <div class="title-hover-block">
              <span class="title-hover-block-number">{{roadMarking.number + '.'}}</span>
              <span class="title-hover-block-name">{{roadMarking?.name}}</span>
            </div>
            <div class="content-hover-block">
              <div class="content-marking-hover-block">
              </div>
              <div [froalaView]="roadMarking?.description" class="content-hover-block-description">
              </div>
            </div>
          </div>
        </div>
      </a>
      <div class="emptyBlock"></div>
    </div>
  </div>
  <div class="menu">
    <div class="menu-container">
      <div class="menu-container-top">
        <div class="title"><p>{{'TRAINING_MATERIAL.ROAD_MARKING_TITLE' | translate}}</p></div>
        <div class="marking-icon"><img src="../../../assets/icons/general-road-marking.svg"></div>
      </div>
      <div class="menu-content-container">
        <a (click)="changeCategory(category)"
           *ngFor="let category of categories | async"
           [routerLink]="'../'+category.id"
           class="menu-item">
          <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
            <div class="number"><p>{{category.number}}.</p></div>
            <div class="theme-name"><p>{{ category.name}}</p></div>
            <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="menu-mobile none">
    <div class="menu-container">
      <div *ngFor="let category of categories | async" [routerLink]="'../' + category.id" class="menu-item">
        <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
          <div class="number"><p>{{category.number}}.</p></div>
          <div class="theme-name"><p>{{ category.name}}</p></div>
          <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
