<div *ngIf="favourites > 0; else noSelect">
  <div class="test-theme">
    <div><p>{{'TESTS.SELECTED' | translate}}</p></div>
    <div class="start-test" routerLink="/home/tests/selected-test"><p>{{'TESTS.START-TEST' | translate}}</p></div>
  </div>
  <div class="content">
    <a *ngFor="let test of categories| async; let i = index" class="tests">
      <div class="test-content">
        <div class="test-information">
          <div class="test-number">
            <p>
              {{'TESTS.QUESTION-NUMBER' | translate}} {{i + 1}}
            </p>
          </div>
          <div class="test-table-name">{{test.question}}</div>
        </div>
        <div>
          <img (click)="deleteFavouriteQuestion(test)" alt="" class="icon" src="../../../assets/icons/selected.svg">
        </div>
      </div>
    </a>
  </div>
</div>
<ng-template #noSelect>
  <div class="no-favourites">
    <img class="no-favourites-icon" src="../../../assets/icons/selected.svg">
    <div class="title"><p>{{'TESTS.NO_FAVOURITES_TITLE' | translate}}</p></div>
    <div class="subtitle"><p>{{'TESTS.NO_FAVOURITES_SUBTITLE' | translate}}</p></div>
  </div>
</ng-template>
