<div *ngIf="activeCategory && trafficRules" class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <p>{{activeCategory?.name}}</p>
    </div>
    <hr>
    <div *ngIf="trafficRules" class="content">
      <div class="content-container">
        <div *ngFor="let trafficRule of trafficRules; let it=index; " class="traffic-rule">
          <div class="traffic-rule-content">
            <p [ngClass]="{'activeId':trafficRule.id == activatedRoute.snapshot.params.id}"
               class="traffic-rule-number"
               id="{{trafficRule.id}}">{{trafficRule.number}}</p>
            <div [froalaView]="trafficRule?.description" class="traffic-rule-name"></div>
            <p (click)="open(it)" *ngIf="trafficRule.comment"
               class="comment">{{'TRAINING_MATERIAL.EXPERT_COMMENT' | translate}}</p>
            <div *ngIf="this.openComment.includes(it)" [froalaView]="trafficRule?.comment" class="comment-place"></div>
          </div>
        </div>
      </div>
      <div class="emptyBlock"></div>
    </div>
  </div>
  <div class="menu">
    <div class="menu-container">
      <div class="menu-container-top">
        <div class="title">{{'TRAINING_MATERIAL.TRAFFIC_RULE_TITLE' | translate}}
          <div class="subtitle">{{'TRAINING_MATERIAL.TRAFFIC_RULE_SUBTITLE' | translate}}</div>
        </div>

        <div class="traffic-rule-icon"><img src="../../../assets/icons/traffic.svg"></div>
      </div>
      <div class="menu-content-container">
        <a (click)="changeCategory(category)"
             *ngFor="let category of categories | async"
           [routerLink]="'../'+category.id"
             class="menu-item">
          <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
            <div class="number">{{category.number}}.</div>
            <div class="theme-name">{{ category.name}}</div>
            <div *ngIf="activeCategory?.id === category.id;else other_content" class="arrow">
              <img src="../../../assets/icons/arrow-left.svg">
            </div>
            <ng-template #other_content>
              <div class="arrow">
                <img src="../../../assets/icons/arrow-right.svg">
              </div>
            </ng-template>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="menu-mobile none">
    <div class="menu-container">
      <div *ngFor="let category of categories | async" [routerLink]="'../'+category.id" class="menu-item">
        <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
          <div class="number">{{category.number}}.</div>
          <div class="theme-name">{{ category.name}}</div>
          <div class="arrow"><img src="../../../assets/icons/arrow-right.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
