import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoPlayerComponent } from '../../dialogs/video-player/video-player.component';

@Component({
  selector: 'app-start-learning',
  templateUrl: './start-learning.component.html',
  styleUrls: ['./start-learning.component.scss']
})
export class StartLearningComponent implements OnInit {
  @Input() statusData: string;

  constructor(private router: Router,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  start(): void {
    if (this.statusData === 'pr')
    {
      this.router.navigate([`/home/practice/practical-training`]);
    }
    else {
      this.router.navigate([`/start-learning/choose-city`]);
    }
  }

  openVideo(): void {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.matDialog.open(VideoPlayerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}
