import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-data',
  templateUrl: './registration-data.component.html',
  styleUrls: ['./registration-data.component.scss',
    '/src/app/shared/dialogs/dialogs.scss']
})
export class RegistrationDataComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RegistrationDataComponent>) {
  }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }


}
