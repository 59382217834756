<a routerLink="/home/tests/test/tests-on-themes" class="back-to-category">
  <img src="../../../../../../assets/icons/arrow-left-grey.svg">
  <p>{{'TESTS.BACK-TO-THEMES' | translate}}</p>
</a>
<div class="test-container">
  <app-ticket-content [link]="'/home/tests/test/tests-on-themes'"
                      [testItems]="testsById"
                      [testKind]="'TESTS.THEMES' | translate">
  </app-ticket-content>
</div>
