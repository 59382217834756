import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TestItem } from '../../../../shared/modules/test-item';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { FinalTestService } from '@module/course/services/final-test.service';

@Component({
  selector: 'app-ticket-content',
  templateUrl: './ticket-content.component.html',
  styleUrls: ['./ticket-content.component.scss']
})
export class TicketContentComponent implements OnInit, OnChanges {

  @Input() testItems: TestItem[] = [];
  @Input() testKind: string;
  @Input() finalTestId: string;
  @Input() link: string;
  @Input() finalTest: boolean;
  @Input() TestFromLecture: string;
  @Input() nextLecture: any;
  @Input() testStatus: string;
  startTest = false;
  selectedTestIndex = 0;
  testInfo: object;
  isTestFinished = false;
  scrollElement = false;
  timerTimeMinutes = 20;
  timerTimeSeconds = 0;
  step = 0;
  session = {
    testType: 0,
    trafficRulesTestsTicketId: ''
  };
  falseAnswer = 0;
  sessionId: string;
  disableBut: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private storageService: StorageService,
              private translateService: TranslateService,
              private router: Router,
              private finalTestService: FinalTestService) {
    this.storageService.set('false-answer-count', 0);
  }

  get finalTestError(): boolean {
    this.falseAnswer = this.storageService.get('false-answer-count') ?
      this.storageService.get('false-answer-count').value : this.falseAnswer;
    if (this.falseAnswer === 3 && this.finalTest) {
      this.storageService.set('treeError', true);
      const correctAnswers = [];
      this.testItems.forEach((el: TestItem) => {
        if (el.isCorrectAnswer) {
          correctAnswers.push(el);
        }
      });
      this.testInfo = {
        correctAnswers,
        allTestsLength: this.testItems.length
      };
      this.isTestFinished = true;
      return true;
    } else {
      return false;
    }
  }

  ngOnChanges(changes): void {
    if (changes && changes.finalTestId && changes.finalTestId.currentValue) {
      this.startSession();
    }
  }

  ngOnInit(): void {

    this.storageService.set('treeError', false);
    this.storageService.set('wasteTime', false);
    this.storageService.set('finish-test', this.isTestFinished);
  }

  startFinishTest(): void {
    this.step = 1;
    if (this.finalTest) {
      setInterval(() => {
        this.storageService.set('wasteTime', false);
        if (this.timerTimeMinutes >= 0) {
          this.timerTimeSeconds--;
          if (this.timerTimeSeconds === -1) {
            this.timerTimeSeconds = 59;
            this.timerTimeMinutes--;
          }
        }
        if (this.timerTimeMinutes === -1) {
          this.storageService.set('wasteTime', true);
          this.timerTimeMinutes = 0;
          this.timerTimeSeconds = 0;
          const correctAnswers = [];
          this.testItems.forEach((el: TestItem) => {
            if (el.isCorrectAnswer) {
              correctAnswers.push(el);
            }
          });
          this.testInfo = {
            correctAnswers,
            allTestsLength: this.testItems.length
          };
          this.isTestFinished = true;
          this.storageService.set('finish-test', this.isTestFinished);
        }
      }, 1000);
    }
  }

  startSession(): void {
    if (this.finalTest && this.finalTestId && !this.session.trafficRulesTestsTicketId) {
      this.session.trafficRulesTestsTicketId = this.finalTestId;
      this.finalTestService.postFinal(this.session).subscribe((el) => {
        this.sessionId = el;
      });
    }
  }

  changeTestIndex(index): void {
    if (!this.disableBut) {
      this.selectedTestIndex = index;
      this.scrollToQuestion();

    }
  }

  checkIfTestPassed(): number {
    return this.testItems.findIndex((el: TestItem) => typeof el.isCorrectAnswer === 'undefined');
  }

  scrollToQuestion(): void {
    setTimeout(() => {
      const el = document.querySelector('.block');
      if (el) {
        el.scrollIntoView();
      }
    }, 0);
  }

  nextQuestion(): void {
    this.scrollElement = true;
    if (this.testItems.length - 1 !== this.selectedTestIndex) {
      this.selectedTestIndex++;
    } else {
      const correctAnswers = [];
      this.testItems.forEach((el: TestItem) => {
        if (el.isCorrectAnswer) {
          correctAnswers.push(el);
        }
      });
      this.testInfo = {
        correctAnswers,
        allTestsLength: this.testItems.length
      };
      this.isTestFinished = true;
      this.storageService.set('finish-test', this.isTestFinished);
    }
    this.scrollToQuestion();
  }

  previousQuestion(): void {
    if (!!this.selectedTestIndex) {
      this.selectedTestIndex--;
    }
    this.scrollToQuestion();
  }

  close(): void {
    this.storageService.set('finish-test', true);
    this.router.navigate(['/home/course/courses']);
  }

  disableButton(event): void {
    this.disableBut = event;
  }
}
