import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './core/services/storage.service';
import { propToLocalStorage } from './shared/decorators/storage.decorator';
import { UserService } from './modules/user/services/user.service';
import { DeviceDetectorService } from '~/node_modules/ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'RDD';
  @propToLocalStorage('user')
  public user;
  checkPhone: boolean;
  deviceInfo: any;
  openApplication: number;
  location: Location;
  isViber: boolean;
  availableForMobile: boolean;

  constructor(private translate: TranslateService,
              private storageService: StorageService,
              private userService: UserService,
              private deviceService: DeviceDetectorService) {
    this.initTranslate();
    this.checkPhone = this.deviceService.isMobile();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isViber = window.location.pathname.includes('/viber-link');
    this.availableForMobile = this.pages_opened_in_mobile();
    if (this.checkPhone) {
      if (this.tryOpenMobileVersion()) {
        this.openApplication = this.storageService.get('openApplication') ?
          this.storageService.get('openApplication').value : 0;
        if (this.openApplication === 1) {
          this.openAppstore();
        }
      } else {
        this.openApplication = 0;
      }
    }
  }

  tryOpenMobileVersion(): boolean {
    return window.location.pathname.includes('authorization') && document.referrer !== '' &&
    (document.referrer.includes('romdomdom.in.ua'));
  }

  pages_opened_in_mobile(): boolean {
    return window.location.pathname.includes('confirm-email') ||
      window.location.pathname.includes('reset-password') ||
      window.location.pathname.includes('confirmed-email') ||
      window.location.pathname.includes('finish-registration') ||
      window.location.pathname.includes('/registration-categories') ||
      window.location.pathname.includes('forgot-password') ||
      window.location.pathname.includes('buy-course');
  }

  openAppstore(): void {
    this.storageService.set('openApplication', 0);
    if (this.checkPhone && this.deviceInfo.os === 'Android') {
      window.open('https://play.google.com/store/apps/details?id=com.romdomdom.student');
    } else if (this.checkPhone && this.deviceInfo.os === 'iOS') {
      window.open('https://apps.apple.com/ua/app/romdomdom/id1541853396/');
    }
  }

  tryToOpenApp(): void {
    this.storageService.set('openApplication', 1);
    this.openAppstore();
  }

  initTranslate(): void {
    const lang = this.storageService.get('lang') ?
      this.storageService.get('lang') : {value: 'Uk'};
    this.translate.use(lang.value);
    this.translate.onLangChange.subscribe(event => this.storageService.set('lang', event.lang));
  }

}
