import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPenalties } from '../../../shared/interfaces/penalties';
import { TrainingMaterialComponents } from '../../../shared/interfaces/training-material-components';

@Injectable({
  providedIn: 'root'
})
export class PenaltyService {

  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }

  getPenalties(): Observable<IPenalties[]> {
    return this.http.get<IPenalties[]>(this.url + 'penalties');
  }

  getPenaltyById(id: string): Observable<TrainingMaterialComponents> {
    return this.http.get <TrainingMaterialComponents>(this.url + 'penalties/' + id);
  }
}
