import { Injectable } from '@angular/core';
import { Config } from '../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILicenseCategory } from '../../../shared/interfaces/licenseCategory';
import { NextPayment } from '../../../shared/interfaces/nextPayment';

@Injectable({
  providedIn: 'root'
})
export class LicenseCategoriesService {

  private url = Config.apiUrl();

  constructor(private http: HttpClient) {
  }
  getLicense(): Observable<ILicenseCategory[]> {
    return this.http.get<ILicenseCategory[]> (this.url + 'licensecategories');
  }

  getNextPayment(): Observable<NextPayment> {
    return this.http.get<NextPayment> (this.url + 'payments/nextpaymentstatus');
  }
}
