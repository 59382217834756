<div *ngIf="!checkPhone||isViber||availableForMobile; else noPhone">
  <router-outlet></router-outlet>
</div>
<ng-template #noPhone>
  <div class="download-app">
    <div class="content">
      <img  src="../../../assets/images/download-app-logo.svg">
      <p>Для зручності користування завантажте мобільний додаток або використовуйте персональний ПК</p>
      <button (click)="tryToOpenApp()">
       Перейти в додаток
       </button>
    </div>
  </div>
</ng-template>
<ngx-spinner
  bdColor = "rgba(0, 0, 0, 0.32)"
  template="<img src='../../../assets/icons/pin.svg' class='spinner' >"
></ngx-spinner>

