<div class="general-container">
  <div class="training-material-content-box">
    <div class="theme">
      <div><span [routerLink]="'/home/tm/signs/category/'+activeCategory?.id"
                 class="categoryName">{{categoryName}}</span>
        <img src="../../../assets/icons/boorger-menu.svg">{{sign?.name}} </div>
    </div>
    <hr>
    <div class="more-information-content">
      <div class="content-container">
        <div *ngIf="sign" class="bold-text"><p>{{sign.number}} {{sign.name}}</p></div>
        <div *ngIf="sign" [froalaView]="sign.description"></div>
        <div (click)="openComment=!openComment"
             *ngIf="sign?.comment!=null"
             class="comment">{{'TRAINING_MATERIAL.EXPERT_COMMENT' | translate}}</div>
        <div *ngIf="!openComment"
             [froalaView]="sign?.comment"
             class="comment-place"></div>
      </div>
    </div>
  </div>
  <div *ngIf="categories" class="menu">
    <app-sign-menu [activeCategory]="activeCategory"
                   [categories]="categories"
    ></app-sign-menu>
  </div>
  <div class="menu-mobile none">
    <div class="menu-container">
      <div *ngFor="let category of categories | async" [routerLink]="'/category/'+category.id" class="menu-item">
        <div [class.active]="activeCategory?.id === category.id" class="menu-item-content">
          <div class="number">
            <p>{{category.number}}.</p>
          </div>
          <div class="theme-name">
            <p>{{category.name}}</p>
          </div>
          <div class="arrow"><img src="../../../assets/icons/arrow.svg"></div>
        </div>
      </div>
    </div>
  </div>
</div>
