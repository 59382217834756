import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-test',
  templateUrl: './leave-test.component.html',
  styleUrls: ['./leave-test.component.scss',
    '/src/app/style/buttons.scss']
})
export class LeaveTestComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LeaveTestComponent>) {
  }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

}
