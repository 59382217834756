import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../../core/services/storage.service';
import { PrivacyPolicy } from '../../../../_shared/constants/privacy-policy';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss',
    '/src/app/style/buttons.scss',
    '/src/app/style/material-components.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  forGooglePlay: boolean;
  confirmForm: FormGroup;
  text = PrivacyPolicy.value();
  @Output() confirmPrivacy: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder,
              private storageService: StorageService) {
    this.confirmForm = this.fb.group({
      first: [false, [Validators.required, Validators.pattern('true')]],
      second: [false, [Validators.required, Validators.pattern('true')]],
    });
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.confirmPrivacy.emit(true);
    this.storageService.set('privacy', true);
  }
}
