import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-registration',
  templateUrl: './cancel-registration.component.html',
  styleUrls: ['./cancel-registration.component.scss',
               '/src/app/shared/dialogs/dialogs.scss',
               '/src/app/style/buttons.scss']
})
export class CancelRegistrationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CancelRegistrationComponent> ) { }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }

}
