import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-type',
  templateUrl: './change-password-type.component.html',
  styleUrls: ['./change-password-type.component.scss',
    '/src/app/shared/dialogs/dialogs.scss',
    '/src/app/style/buttons.scss']
})
export class ChangePasswordTypeComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<ChangePasswordTypeComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    this.dialogRef.close(result);
  }


}
