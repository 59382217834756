<div class="container">
  <div class="container-top">
    <div class="profile"><img src="../../../assets/icons/Profile.svg"></div>
    <div class="user-pin1"><img src="../../../assets/icons/user-pin1.svg"></div>
    <div class="user-pin2"><img src="../../../assets/icons/user-pin2.svg"></div>
  </div>
  <div class="user-information">
    <div class="top-container">
      <div class="image-container">
        <div *ngIf="imagePath === null; else other_content" class="image">
          <img src="../../../assets/icons/user-image.svg">
        </div>
        <ng-template #other_content class="image">
          <img [src]="prefixImgPath + imagePath" class="image">
        </ng-template>
      </div>
      <label class="change-photo" for="photo">{{'USER.CHANGE_PHOTO' | translate}}</label>
      <input (change)="fileChangeEvent($event)" accept="image/*" class="none" id="photo" type="file">
    </div>
    <div class="bottom-container">
      <div class="user-data">
        <div class="user-data-container">
          <div class="name">{{'USER.NAME' | translate}}</div>
          <input [(ngModel)]="fullName" [disabled]="changingPassword" class="full-name">
        </div>
        <div class="user-data-container">
          <div class="name">{{'USER.EMAIL' | translate}}</div>
          <input [disabled]="true" [value]="userInfo?.email" class="full-name">
        </div>
      </div>
      <div class="user-location">
        <div class="user-data-container">
          <div class="name">Ваше місце розташування</div>
            <mat-select #location (click)="changeColor(location.id)" [placeholder]="locationPlaceholder" class="city-select" id="location" [(value)]="selectedLocation">
              <mat-option *ngFor="let city of optionsOne" [value]="city.id">
                {{city.value}}
              </mat-option>
            </mat-select>
          </div>
        <div class="user-data-container">
          <div class="name">Місто для практичних уроків</div>
            <mat-select *ngIf="!isPractice" #practice (click)="changeColor(practice.id)" [placeholder]="practicePlaceholder" class="city-select" id="practice" [(value)]="selectedPractice">
              <mat-option *ngFor="let city of optionsTwo" [value]="city.id">
                {{city.value}}
              </mat-option>
            </mat-select>
            <span *ngIf="isPractice && cityShown" class="city-select">{{userInfo.location.cityPractice.name}}</span>
         </div>
      </div>
    </div>
    <div *ngIf="changingPassword">
      <div class="user-data">
        <div class="user-data-container">
          <div class="password-data">{{'USER.OLD_PASSWORD' | translate}}</div>
          <input [(ngModel)]="currentPassword" class="password" placeholder="********" type="password">
        </div>
        <div [formGroup]="newPasswordGroup" class="user-data-container">
          <div class="password-data">{{'USER.NEW_PASSWORD' | translate}}</div>
          <input class="password" formControlName="newPassword" placeholder="********" type="password">
        </div>
        <div
          *ngIf="error || newPasswordGroup.controls['newPassword'].invalid && newPasswordGroup.controls['newPassword'].touched"
          class="error-password"> {{'USER.ERROR_PASSWORD' | translate}}</div>
      </div>
      <div class="button">
        <button (click)="cancelNewPassword()" class="cancel">{{'USER.CANCEL' | translate}}</button>
        <button (click)="changePassword()" class="save">{{'USER.CHANGE_PASSWORD' | translate}}</button>
      </div>
    </div>
    <div *ngIf="!changingPassword">
      <div class="button">
        <button (click)="changingPassword = true"
                class="change-password">{{'USER.CHANGE_PASSWORD' | translate}}</button>
        <button (click)="changeMode(false)" class="cancel">{{'USER.CANCEL' | translate}}</button>
        <button (click)="changeInfo()" class="save">{{'USER.SAVE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="successPassword" class="successful-password">
  <img src="../../../assets/icons/true-email.svg">
  <span>{{'USER.SUCCESS_PASSWORD_CHANGE' | translate}}</span>
</div>
