import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../core/services/storage.service';
import { RegistrationPersonalInformationService } from './services/registration-personal-information.service';
import { PersonalInformation } from '../../shared/interfaces/personalInformation';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-driving-school-registration',
  templateUrl: './driving-school-registration.component.html',
  styleUrls: ['./driving-school-registration.component.scss']
})
export class DrivingSchoolRegistrationComponent implements OnInit, OnDestroy {
  step: number;
  dockStep: number;
  editStatusDocuments: boolean;
  privacy: boolean;
  subscriptions: Subscription = new Subscription();

  constructor(private router: Router,
              private matDialog: MatDialog,
              private storageService: StorageService,
              private  regPersonalInformationService: RegistrationPersonalInformationService,
              private activeRouter: ActivatedRoute) {
    try {
      this.privacy = this.storageService.get('privacy') ? this.storageService.get('privacy').value : false;
    }
    catch (e) {
      this.privacy = false;
    }
  }

  ngOnInit(): void {
    this.editStatusDocuments = this.activeRouter.snapshot.routeConfig.path === 'edit';
    this.storageService.set('finish-registration', false);
    this.storageService.set('editStatusDocuments', this.editStatusDocuments);
    const menuError = {
      personalInformation: false,
      isPurchased: false,
      documents: false
    };
    this.subscriptions.add(this.regPersonalInformationService.getRegistrationInformation().subscribe((el: PersonalInformation) => {
       if (el !== null) {
          const docs = this.storageService.get('documnets');
          if (docs && docs.value)
          {
            this.storageService.set('registrationStep', 3);
            menuError.personalInformation = true;
            menuError.isPurchased = true;
            return;
          }
          if (this.storageService.get('registCategory')?.isHadLicense)
          {
            this.storageService.set('registrationStep', 2);
            return;
          }
          else if (!el.isPurchased) {
            try {
              if (this.storageService.get('personalInfo').value &&
                this.storageService.get('personalInfo').value.purchaseType !== null) {
                this.storageService.set('registrationStep', 3);
                menuError.personalInformation = true;
                menuError.isPurchased = true;
              } else {
                this.storageService.set('registrationStep', 2);
                menuError.personalInformation = true;
                menuError.isPurchased = false;
              }
            }
            catch (e) {
              this.storageService.set('registrationStep', 2);
              menuError.personalInformation = true;
              menuError.isPurchased = false;
            }
          } else {
            this.storageService.set('registrationStep', 3);
            menuError.personalInformation = true;
            menuError.isPurchased = true;
          }
        } else {
          this.storageService.set('registrationStep', 1);
          menuError.personalInformation = false;
          menuError.isPurchased = false;
        }
       if (el) {
          if (el.courseRequestRejection) {
            menuError.isPurchased = true;
            if (el.courseRequestRejection.isPersonalInfoInvalid) {
              menuError.personalInformation = false;
              this.storageService.set('registrationStep', 1);
            } else {
              menuError.personalInformation = true;
              this.storageService.set('registrationStep', 3);
            }
            menuError.documents = !(el.courseRequestRejection.isCategoryBDrivingLicenseInvalid || el.courseRequestRejection.isIdInvalid ||
              el.courseRequestRejection.isIndividualTaxNumberInvalid || el.courseRequestRejection.isMedicalReferenceInvalid);
          }
        }
       this.storageService.set('correctInformation', menuError);
      }, () => {
        this.storageService.set('registrationStep', 1);
      }, () => {
      },
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  confirmPrivacy(event): void {
    this.privacy = event;
  }

  registrationStep(): number {
    try {
      const temp = this.storageService.get('registrationStep').value;
      const docs = this.storageService.get('documents');
      if (docs && docs.value)
      {
        return 3;
      }
      if (temp === 0) {
        return 1;
      }
      else {
        return temp;
      }
    }
    catch (e) {
      console.log(e);
      return 1;
    }
  }

  exit(): void {
    this.router.navigate([`home/dashboard`]).then(() => {
      this.storageService.set('personal-information', {});
    });
  }
}
