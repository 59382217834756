import { Injectable } from '@angular/core';
import { Config } from '../../../../shared/property/constant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cities } from '../../../../shared/interfaces/cities';
import { UserInfirmation } from '../../../../shared/interfaces/user-infirmation';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private url = Config.apiUrl();

  constructor(private http: HttpClient) { }

  getCities(): Observable<Cities[]> {
    return this.http.get<Cities[]>(this.url + 'locations/cities');
  }

  postLocation(data): Observable<any> {
    return this.http.post(this.url + 'locations', data);
  }
}
