import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrivingSchoolRegistrationComponent } from './driving-school-registration.component';
import { FinishRegistrationGuard } from '../../core/guards/finish-registration.guard';
import { StudentRegistrationGuard } from '../../core/guards/student-registration.guard';

const routes: Routes = [
  {
    path: '',
    component: DrivingSchoolRegistrationComponent,
    canActivate: [StudentRegistrationGuard],
    canDeactivate: [FinishRegistrationGuard]
  },  {
    path: 'edit',
    component: DrivingSchoolRegistrationComponent,
    canDeactivate: [FinishRegistrationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DrivingSchoolRegistrationRoutingModule { }
