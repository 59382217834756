import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestsOnThemesService } from '../../../services/tests-on-themes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../../core/services/storage.service';
import { ILicenseCategory } from '../../../../../shared/interfaces/licenseCategory';
import { NgxSpinnerService } from 'ngx-spinner';
import { TestItem } from '../../../../../shared/modules/test-item';
import { Subscription } from '~/node_modules/rxjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss',
    '/src/app/test-themes.scss']
})
export class TestComponent implements OnInit, OnDestroy {

  testsById: TestItem[] = [];
  licenseCategory: ILicenseCategory;
  subscriptions: Subscription = new Subscription();

  constructor(private testsOnThemesService: TestsOnThemesService,
              private activatedRoute: ActivatedRoute,
              private storageService: StorageService,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.licenseCategory = this.storageService.get('license-category').value;
    this.getTestById();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTestById(): void {
    this.spinner.show();
    this.subscriptions.add(this.testsOnThemesService.getTestsOnThemesById(this.licenseCategory.id,
      this.activatedRoute.snapshot.params.id).subscribe((test: TestItem[]) => {
      this.testsById = test;
    }, ()=>{

    }, ()=>{
      this.spinner.hide();
    }));
  }

  goToPreviousUrl(): void {
    this.router.navigate(['/home/tests/test/tests-on-themes']);
  }
}
