<div class="dialog-container">
  <img (click)="closeDialog()" class="exit" src="../../../assets/icons/cancel.svg">
  <div class="content">
    <div class="header">
      <img src="../../../../assets/icons/lightning.svg">
      <div class="title">
        <p>{{'DIALOGS.HINT.TITLE' | translate}}</p>
      </div>
    </div>
    <mat-dialog-actions>
      <button [froalaView]="data?.hint" class="mat-raised-button"></button>
    </mat-dialog-actions>
    <div class="footer">
      <button (click)="closeDialog()">{{'DIALOGS.HINT.BUTTON' | translate}}</button>
    </div>
  </div>
</div>
