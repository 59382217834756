import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../../../core/services/storage.service';
import { SearchService } from '../../../../layout/services/search.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingMaterialComponents } from '../../../../../shared/interfaces/training-material-components';
import { TrainingMaterialCategories } from '../../../../../shared/interfaces/training-material-categories';
import { Config } from '../../../../../shared/property/constant';

@Component({
  selector: 'app-search-signs',
  templateUrl: './search-signs.component.html',
  styleUrls: ['./search-signs.component.scss',
  '/src/app/style/sign-marking-item.scss']
})
export class SearchSignsComponent implements OnInit {

  signs: Observable<TrainingMaterialComponents[]>;
  categories: Observable<TrainingMaterialCategories[]>;
  activeCategory: TrainingMaterialCategories;
  searchText: string;
  prefixImgPath = Config.storageUrl();


  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private searchService: SearchService,
              private spinner: NgxSpinnerService) {
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.signs = await this.searchService.searchBySignsId(this.activatedRoute.snapshot.params.id, this.activatedRoute.snapshot.params.searchText);
    this.spinner.hide();
    this.searchText = this.activatedRoute.snapshot.params.searchText;
    this.activeCategory = this.storageService.get('change-category').value;
  }

  openSign(link, categoryName): void {
    this.storageService.set('signCategory', categoryName);
    this.router.navigate([link]);
  }

}
