import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstructorsService } from '../../../modules/practice/services/instructors.service';


@Component({
  selector: 'app-reject-lesson',
  templateUrl: './reject-lesson.component.html',
  styleUrls: ['./reject-lesson.component.scss',
    '/src/app/shared/dialogs/dialogs.scss']
})
export class RejectLessonComponent implements OnInit {
  id: string;
  status: number;

  constructor(public dialogRef: MatDialogRef<RejectLessonComponent>,
              public instructorService: InstructorsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.id = data.id;
    this.status = data.status;
  }

  ngOnInit(): void {
  }

  closeDialog(result?): void {
    if (result) {
      this.instructorService.rejectLesson(this.id).subscribe(() => {
        this.dialogRef.close(result);
      });
    } else {
      this.dialogRef.close(result);
    }
  }
}
