import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Config } from '../../shared/property/constant';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CropImageComponent } from '../../shared/dialogs/crop-image/crop-image.component';
import { Router } from '@angular/router';
import { UserInfirmation } from '../../shared/interfaces/user-infirmation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from '~/node_modules/rxjs';
import { CityService } from '@module/start-learning/components/services/city.service';
import { MatSelect } from '~/node_modules/@angular/material/select';
import { InstructorsService } from '@module/practice/services/instructors.service';
import { TripBookingProgress } from '~/src/app/shared/interfaces/tripBookingProgress';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  userInfo: UserInfirmation;
  edit = false;
  currentPassword: string;
  fullName: string;
  cityLocation: string;
  cityPractice: string;
  imagePath: string;
  changingPassword: boolean;
  prefixImgPath = Config.storageUrl();
  successPassword: boolean;
  errorPassword: boolean;
  newPasswordGroup: FormGroup;
  error = false;
  subscriptions: Subscription = new Subscription();

  cityShown = false;
  isPractice = false;

  optionsOne = [];
  optionsTwo = [];
  selectedCityName = '';
  isValiable = false;
  selectedLocation = '';
  selectedPractice = '';
  practicePlaceholder = '. . .';
  locationPlaceholder = '. . .';

  constructor(private userService: UserService,
              private cityService: CityService,
              public spinner: NgxSpinnerService,
              private matDialog: MatDialog,
              private router: Router,
              private fb: FormBuilder,
              private instructorService: InstructorsService) {
    this.newPasswordGroup = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern('^[a-zA-Zа-щА-ЩЬьЮюЯяЇїІіЄєҐґ0-9-_\\!\\@\\#\\$\\%\\^&\\*]{6,12}$')]],
    });
  }

  ngOnInit(): void {
    this.getUser();
    this.getLocations();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getLocations(): void {
    this.spinner.show();
    this.cityService.getCities().subscribe(result => {
      result.forEach(city => {
        this.optionsOne.push({id: city.id as string, value: city.name as string});
        this.optionsTwo.push({id: city.id as string, value: city.name as string});
      });
    }, error => {
      console.log(error);
    }, () => {
      this.spinner.hide();
      this.isValiable = true;
    });
  }

  getUser(): void {
    this.spinner.show();
    try {
      this.instructorService.getTripBookingsProgress().subscribe((trips: TripBookingProgress) => {
        if (trips.tripStatuses.length >= 1) {
          this.isPractice = true;
          console.log(trips.tripStatuses.length);
        }
      });
    }
    catch (e) {
      console.log(e);
    }

    this.subscriptions.add(this.userService.getUser().subscribe((user: UserInfirmation) => {
      this.userInfo = user;
      this.imagePath = user.imagePath;
      this.fullName = user.fullName;
      if (user.location) {
        if (user.location.cityLocation.id && user.location.cityPractice.id) {
          this.cityShown = true;
          this.selectedPractice = user.location.cityPractice.id;
          this.selectedLocation = user.location.cityLocation.id;
        }
      }

    }, error => {
      // error during done subscribe
    }, () => {
      // After complete subscribe
      this.spinner.hide();
    }));
  }

  changePassword(): void {
    this.error = false;
    this.errorPassword = false;
    this.spinner.show();
    this.subscriptions.add(this.userService.changePassword({
      currentPassword: this.currentPassword,
      newPassword: this.newPasswordGroup.getRawValue().newPassword
    }).subscribe(() => {

      this.spinner.hide();
      this.newPasswordGroup.getRawValue().newPassword = null;
      this.currentPassword = '';
      this.changingPassword = false;
      this.successPassword = true;
      setTimeout(() => {
        this.successPassword = false;
      }, 4000);
    }, () => {
      this.error = true;
    }));
    if (this.error && !this.newPasswordGroup.controls.newPassword.invalid) {
      this.changingPassword = false;

      this.newPasswordGroup.getRawValue().newPassword = '';
    }
  }

  cancelNewPassword(): void {
    this.changingPassword = false;
    this.errorPassword = false;
    this.newPasswordGroup.getRawValue().newPassword = '';
  }

  changeMode(mode): void {
    this.edit = mode;
    if (mode) {
      this.imagePath = this.userInfo.imagePath;
      this.fullName = this.userInfo.fullName;
    }
    this.router.navigate(['/home/dashboard']);
  }

  changeInfo(): void {
    if (this.selectedLocation && !this.selectedPractice)
    {
      this.practicePlaceholder = 'Виберіть місто...';
      const el = document.getElementById('practice');
      el.style.border = 'red 1px solid';
      el.style.borderRadius = '5px';
      return;
    }
    else if (!this.selectedLocation && this.selectedPractice)
    {
      this.locationPlaceholder = 'Виберіть місто...';
      const el = document.getElementById('location');
      el.style.border = 'red 1px solid';
      el.style.borderRadius = '5px';
      return;
    }
    this.spinner.show();
    this.userService.editUserInfo({
      imagePath: this.imagePath,
      fullName: this.fullName,
      cityLocationId: this.selectedLocation,
      cityPracticeId: this.selectedPractice
    }).subscribe(() => {
      this.changeMode(false);
      this.getUser();

    }, () => { this.spinner.hide(); },
      () => { this.spinner.hide(); });

    this.router.navigate(['/home/dashboard']);
  }


  fileChangeEvent(event: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      imageChangedEvent: event,
    };
    const dialogRef = this.matDialog.open(CropImageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result?.image) {
        this.uploadImg(result.image);
      }
    });
  }

  uploadImg(image): void {
    this.spinner.show();
    this.subscriptions.add(this.userService.uploadPhoto(image).subscribe(res => {
      this.imagePath = res;
    }, error => {
      // error during done subscribe
    }, () => {
      // After complete subscribe
      this.spinner.hide();
    }));
  }

  changeColor(practice: string): void {
    document.getElementById(practice).style.border = 'none';
  }
}
