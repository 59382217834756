import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoadMarkingsService } from '../../../services/road-markings.service';
import { Observable } from '~/node_modules/rxjs';
import { TrainingMaterialCategories } from '~/src/app/shared/interfaces/training-material-categories';

@Component({
  selector: 'app-road-marking-menu',
  templateUrl: './road-marking-menu.component.html',
  styleUrls: ['./road-marking-menu.component.scss']
})
export class RoadMarkingMenuComponent implements OnInit {
  @Input() categories: Observable<TrainingMaterialCategories[]> ;
  @Input() activeCategory: string;

  constructor(private roadMarkingsService: RoadMarkingsService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.categories = this.roadMarkingsService.getRoadMarkingCategories();
  }

  changeCategory(category): void {
    this.activeCategory = this.activatedRoute.snapshot.params.email;
    this.router.navigate([`/home/tm/road-marks/category/${category.id}`]);
  }
}
