import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationBuilder } from '@angular/animations';
import { LecturesService } from '../../../modules/course/services/lectures.service';
import { StorageService } from '../../../core/services/storage.service';
import { LectureProgress } from '../../modules/LecturePorogress';
import { FinalTestService } from '../../../modules/course/services/final-test.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LectureView } from '~/src/app/shared/interfaces/lecture-view';

@Component({
  selector: 'app-finished-test',
  templateUrl: './finished-test.component.html',
  styleUrls: ['./finished-test.component.scss'],
  animations: [],
})
export class FinishedTestComponent implements OnInit, OnDestroy {

  @Input() testInformation: any;
  @Input() testKind: string;
  @Input() link: string;
  @Input() finalTest: boolean;
  @Input() TestFromLecture: string;
  @Input() nextLecture: any;
  @Input() testStatus: string;
  @Input() finalTestId: string;
  @Input() sessionId: string;
  trialTest: boolean;
  test: number;
  number = 0;
  licenseCategory;
  courses;
  curentLection;
  lectureProgress: LectureProgress = {
    lectureId: '',
    rightAnswersCount: 0
  };
  nextLectionIndex: number;
  currentOpen;
  session = {
    testType: 0,
    trafficRulesTestsTicketId: ''
  };
  wasteTime: boolean;
  treeError: boolean;
  @ViewChild('movieList') movieList: ElementRef;

  constructor(private router: Router,
              public builder: AnimationBuilder,
              public lectureService: LecturesService,
              private storageService: StorageService,
              private activatedRoute: ActivatedRoute,
              private finalTestService: FinalTestService,
              private spinner: NgxSpinnerService) {
    this.trialTest = this.storageService.get('trialTest').value;
    setInterval(() => {
      if (this.number < this.test && this.test !== 0) {
        this.number++;
      }
    }, 50);
  }

  ngOnDestroy(): void {
    if (this.finalTest && this.test >= 90) {
      this.finalTestService.postSession(this.sessionId).subscribe((() => {
      }));
    }
  }

  ngOnInit(): void {
    this.wasteTime = this.storageService.get('wasteTime').value;
    this.treeError = this.storageService.get('treeError').value;
    this.currentOpen = this.storageService.get('openComent')?.value;
    this.test = Math.round(this.testInformation?.correctAnswers.length * 100 / this.testInformation?.allTestsLength);
    this.verifyTestKind();
    this.licenseCategory = this.storageService.get('license-category').value;
    if (this.testStatus === 'lecture') {
      this.lectureService.getLectures(this.licenseCategory.id).subscribe((res: LectureView[]) => {
        this.courses = res;
        this.nextLecture = this.courses[this.courses.findIndex((element) => {
          return element.id === this.activatedRoute.snapshot.params.id;
        }) + 1];
        this.curentLection = this.courses[this.courses.findIndex((element) => {
          return element.id === this.activatedRoute.snapshot.params.id;
        })];
        this.nextLectionIndex = this.courses.indexOf(this.curentLection) + 1;
        if (this.test >= 90 && this.nextLecture !== undefined && this.nextLecture !== null) {
          this.storageService.set('openComent', this.courses.indexOf(this.curentLection) + 1);
        }

        if (this.testStatus === 'lecture') {
          this.lectureProgress.lectureId = this.curentLection.id;
          this.lectureProgress.rightAnswersCount = this.testInformation?.correctAnswers.length;
          this.lectureService.saveLectureProgress(this.lectureProgress).subscribe(() => {
          });
        }
      });
    }

  }

  verifyTestKind(): void {
    if (this.testKind === undefined) {
      this.testKind = 'TESTS.BACK_TO_TEST';
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  changeUrl(): void {
    this.spinner.show();
    this.router.navigate([this.link]);
  }

  openNextLecture(link): void {
    if (this.trialTest) {
      this.storageService.set('continue-learning', true);
      this.router.navigate(['/home/course/courses']);
    } else {
      this.storageService.set('openComent', this.nextLectionIndex);
      this.router.navigate(['/home/course/lecture-view/' + link]);
    }
  }

  nextLectureStatus(): boolean {
    return this.test >= 90 &&
      this.nextLecture !== undefined &&
      this.nextLecture !== null;
  }
}

